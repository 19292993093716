import React from "react";
import { connect } from "react-redux";
import { createPortal } from "react-dom";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { actionGlobalPopupMessageInit } from "../../../store/reducers/globalPopupMessage/globalPopupMessage-actions";

import { ModalPopup } from "../ModalPopup/ModalPopup";
import classnames from "classnames";
import {isRTL} from "../../../services/userModel.js";

const GlobalPopupMessage = ({ t, isShow, isLink, title, message, onClose, onConfirm }) => (
    <div className={classNames("globalPopupMessage", { isRtl: isRTL() })}>
        {isShow && (
            <ModalPopup isOpen={isShow} height={"fit-content"} width={"20rem"}
                containerModalClass={"globalPopupMessageWrapper"}                
                content={
                    <div className={classNames("globalPopupMessageData")}>
                        <div className={classNames("messageData", {isLink})}>
                            {!!title && (<div className={classNames("title")}>{title}</div>)}
                            <div className={classNames("message")}>
                                {isLink && (<a href={message}>{message}</a>)}
                                {!isLink && (<span>{message}</span>)}
                            </div>                            
                        </div>
                        <div className={classnames("globalPopupMessageButtons", {coloredButton: onConfirm})}>
                            {onConfirm && (
                                <button className={classNames("btn", "confirm")} onClick={onConfirm}>
                                    {t("basic.YES_BUTTON")}
                                </button>
                            )}
                            <button className={classNames("btn", {cancel: !!onConfirm, confirm: !onConfirm})} onClick={onClose}>
                                {onConfirm ? t("basic.NO_BUTTON") : t("basic.OK_BUTTON")}
                            </button>
                        </div>
                    </div>
                }
                onClose={onClose}
            />
        )}
    </div>
);

const GlobalPopupMessagePortal = ({ t, isShow, isLink, title, message, onPopupMessageClose, globalPopupMessageInit, onPopupConfirm }) => {
    const handleOnClose = () => {
        if(onPopupMessageClose) {
            onPopupMessageClose();
        } else {
            globalPopupMessageInit();
        }
    }
    return createPortal(
      <GlobalPopupMessage t={t} onConfirm={onPopupConfirm} isShow={isShow} isLink={isLink} title={title} message={message} onClose={handleOnClose} />,
      document.getElementById("globalMessage-container")
    );
}

const mapStateToProps = state => ({
    isShow: state.globalPopupMessage.isShow,
    isLink: state.globalPopupMessage.isLink,
    message: state.globalPopupMessage.message,
    title: state.globalPopupMessage.title,
    onPopupMessageClose: state.globalPopupMessage.onClose,
    onPopupConfirm: state.globalPopupMessage.onConfirm,
});

const mapDispatchToProps = dispatch => {
    return {
        globalPopupMessageInit: () => dispatch(actionGlobalPopupMessageInit())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GlobalPopupMessagePortal));