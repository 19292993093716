import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  AUDIENCE_FILTER_SELECT_GROUP_TYPES, audienceFilterTimeStampTypes,
  CAMPAIGN_AUDIENCE_FILTER_TYPES,
  CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES,
  getAudienceFilterSelectTypes,
  getAudienceFilterTypes,
  getCampaignGenders,
  languages,
} from '../../../utils/enums.js';
import {getDepotsKeyValue, getTerritoriesEntity} from '../../../services/restaurant.js';
import NumberInput from '../NumberInput/NumberInput.jsx';
import { withTranslation } from 'react-i18next';
import {
  getGroupType,
  getLookupModel,
  getNumericModel,
  getTerritoriesSelect,
  getTimeSpanModel,
  handleMinValueError,
  SimpleItemSelect,
} from './audienceFilterHelpers.js';
import TimeSpanItem from './TimeSpanItem.jsx';
import { getAudienceFilterModel } from './SectionItemAudienceFilter.jsx';

const getFilterModel = filterState => {
  const newGroupType = getGroupType(filterState.field);
  let model = null;

  switch (newGroupType) {
    case AUDIENCE_FILTER_SELECT_GROUP_TYPES.timeSpan: {
      model = {
        ...getTimeSpanModel({
          data: filterState,
          isEqual: filterState?.operator === CAMPAIGN_AUDIENCE_FILTER_TYPES.equal,
        }),
      };
      break;
    }
    case AUDIENCE_FILTER_SELECT_GROUP_TYPES.lookup: {
      model = { ...getLookupModel(filterState) };
      break;
    }
    case AUDIENCE_FILTER_SELECT_GROUP_TYPES.numeric: {
      model = { ...getNumericModel(filterState) };
      break;
    }
    default:
      model = getAudienceFilterModel();
  }

  return model;
};

const AudienceFilterItem = ({ t, filterState, handleChange, index }) => {
  const [groupType, setGroupType] = useState(getGroupType(filterState.field));
  const isLookupOnly = groupType === AUDIENCE_FILTER_SELECT_GROUP_TYPES.lookup
  const territories = getTerritoriesEntity() || [];

  useEffect(() => {
    const newGroup =  getGroupType(filterState.field)
    if(newGroup === groupType) return
      setGroupType(newGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.field]);

  const handleFilterStateChange = (value, key) => {
    let newValue = value?.key ?? value;

    if (newValue === filterState[key]) return;

    if (key === 'field') {
      let newFilter = {...filterState, [key]: newValue}

      const newGroup =  getGroupType(newValue)
      if(newGroup !== groupType || newGroup=== AUDIENCE_FILTER_SELECT_GROUP_TYPES.lookup) {
        setGroupType(newGroup);
        newFilter = { operator: CAMPAIGN_AUDIENCE_FILTER_TYPES.equal, [key]: newValue }
      }

      handleChange(index, getFilterModel(newFilter));
      return
    }

    if (key === 'operator') {
      handleChange(index, getFilterModel({ ...filterState, [key]: newValue }));
      return;
    }

    if (key === 'double_value' && newValue < 0) {
      return handleMinValueError(0);
    }

    handleChange(index, { ...filterState, [key]: newValue });
  };

  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      <SimpleItemSelect
        value={filterState.field}
        values={getAudienceFilterSelectTypes(!territories?.length)}
        handleChange={value => handleFilterStateChange(value, 'field')}
        width={'310px'}
      />
      {filterState.field !== null &&
        <SimpleItemSelect
          width={'140px'}
          value={filterState?.operator}
          values={getAudienceFilterTypes(isLookupOnly, audienceFilterTimeStampTypes().includes(filterState.field))}
          handleChange={value => handleFilterStateChange(value, 'operator')}
        />
      }

      {/*LOOKUP*/}
      {(filterState.field === CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.depot || filterState.field === CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.consumerProfile_LastDepot) && (
          <SimpleItemSelect
              width={'300px'}
              value={filterState.string_value}
              values={getDepotsKeyValue()}
              handleChange={value => handleFilterStateChange(value, 'string_value')}
          />
      )}

      {filterState.field === CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.consumerProfile_LastTerritory && (
          <SimpleItemSelect
              value={filterState.string_value}
              values={getTerritoriesSelect()}
              handleChange={value => handleFilterStateChange(value, 'string_value')}
          />
      )}

      {filterState.field === CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.language && (
        <SimpleItemSelect
          value={filterState.string_value}
          values={languages()}
          handleChange={value => handleFilterStateChange(value, 'string_value')}
        />
      )}
      {filterState.field === CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.gender && (
        <SimpleItemSelect
          value={filterState.string_value}
          values={getCampaignGenders()}
          handleChange={value => handleFilterStateChange(value, 'string_value')}
        />
      )}
      {filterState.field === CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.currentTerritory && (
        <SimpleItemSelect
          width={'210px'}
          value={filterState.string_value}
          values={getTerritoriesSelect()}
          handleChange={value => handleFilterStateChange(value, 'string_value')}
        />
      )}

      {/*LOOKUP END*/}

      {/*TIMESPAN*/}

      {groupType === AUDIENCE_FILTER_SELECT_GROUP_TYPES.timeSpan && (
        <TimeSpanItem index={index} filterState={filterState} handleChange={handleChange} />
      )}

      {/*TIMESPAN END*/}

      {/*NUMERIC*/}

      {groupType === AUDIENCE_FILTER_SELECT_GROUP_TYPES.numeric && (
        <div className={classNames('sectionItemNumber')} style={{ width: '120px' }}>
          <div className={classNames('inputItem')}>
            <NumberInput
              value={filterState.double_value}
              onChange={value => handleFilterStateChange(value || 0, 'double_value')}
            />
          </div>
        </div>
      )}

      {/*NUMERIC END*/}
    </div>
  );
};

export default withTranslation()(AudienceFilterItem);
