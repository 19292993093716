import moment from 'moment';

import { getInitModel } from '../services/initModels';
import { MODEL_TYPE } from '../utils/enums';

export function offerModel(data) {
  const item = data || {};
	return {
	  coupon: item.coupon,
	  description: item.description,
	  end: item.end || moment().format(),
	  id: item.id || "",
	  limit: item.limit || 0,
	  minimum: item.minimum || 0,
	  name: item.name || "",
	  rate: item.rate || 0,
	  rate_type: item.rate_type || 0,
	  start: item.start || moment().format(),
	  title: item.title || "",
	  type: item.type || 0,
	  single_user: item.single_user || false,
	  enable_schedule: item.enable_schedule || false,
	  schedule: getInitModel(MODEL_TYPE.scheduleModel, item.schedule),
	  order_type: item.order_type || 0,
	  discount_target: item.discount_target,
	  calculation_base: item.calculation_base,
	  show_in_marketplace: item.show_in_marketplace,
	  business_id: item.business_id,
	  product_id: item.product_id,
	  usage_limit: item.usage_limit,
	  loyalty_level: item.loyalty_level ?? null,
	  recurring_type: item.recurring_type,
  };
}
