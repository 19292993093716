import i18n from '../i18n';

export const TYPE_STORE_DATA = {
    orders: 0,
    noAssistanceOrders: 1,
    couriers: 2,
    billingInfo: 3,
};

export const STATUSES = {
    notInitialized: 0,
    inProcess: 1,
    success: 2,
    failed: 3,
};

export const COURIER_LOCATION_COLORS = {
    yellow: 'yellow',
    red: 'red',
    green: 'green',
};

export const COURIER_LOCATION_STATE = {
    lost: 0,
    standing: 1,
    moving: 2,
};

export const CYCLE_STATE = {
    started: 0,
    approved: 1,
    deliveryStart: 2,
    comingBack: 3,
    closed: 4,
    away: 5,
};

export const GROUPING_STRATEGY = {
    courierBased: 0,
    immediateCooking: 1,
    onDemand: 2,
};

export const ICONS_MARKER_TYPE = {
    empty: 1,
    point: 2,
    checked: 3,
    canseled: 4,
};

export const ICONS_COURIER_MARKER = {
    courier: 1,
    courierletter: 2,
    courierlight: 3,
};

export const TYPE_MARKER = {
    restaurant: 'restaurant',
    depot: 'depot',
    courier: 'courier',
    order: 'order',
    place: 'place',
    serviceCity: 'serviceCity',
    depotFromBusiness: 'depotFromBusiness',
};

export const DELIVERY_STRATEGY = {
    asap: 0,
    concreteTime: 1,
};

export const DEFAULT_ORDERS_GROUPS = {
    groups: 'groups',
    courierGroup: 'courierGroup',
    assistance: 'assistance',
    takeawayOrders: 'takeawayOrders',
    preOrders: 'preOrders',
    Unsorted: 'Unsorted',
};

export const ORDER_STATE = {
    new: 0,
    kitchen: 1,
    ready: 2,
    onroute: 3,
    delivered: 4,
    canceled: 5,
};

export const ORDER_TYPE = {
    delivery: 0,
    takeAway: 1,
    pickUp: 2,
};

export const DELIVERY_TYPE = {
    delivery: 1,
    takeAway: 2,
}

export const PICKUP_STATE = {
    pending: 0,
    arrived: 1,
};

export const PENDING_ORDER_TYPE = {
    delivery: 1,
    takeAway: 2,
};

export const ADMIN_ORDER_DELIVERY_TYPE = {
    default: 0,
    delivered: 1,
};

export const OFFER_SOURCE_TYPE = {
    business: 0,
    account: 1,
};

export const ACCOUNT_ORDER_STATUS = {
    pending: 0,
    accepted: 1,
    ready: 2,
    delivering: 3,
    finished: 4,
    canceled: 5,
};

export const ORDER_PROCESS_STEP = {
    waitingForApproval: 0,
    waitingForCourierAssignment: 1,
    waitingForPickup: 2,
    delivering: 3,
    processingFinished: 4,
};

export const PENDING_ORDERS_FILTER_TYPE = {
    pending: 'pending',
    cooking: 'cooking',
    ready: 'ready',
    preorders: 'preorders',
    history: 'history',
    active: 'active',
    delivering: 'delivering',
};

export const getAccountOrderStatusList = () => [
    { key: ACCOUNT_ORDER_STATUS.pending, value: getAccountOrderStatusTitle(ACCOUNT_ORDER_STATUS.pending) },
    { key: ACCOUNT_ORDER_STATUS.accepted, value: getAccountOrderStatusTitle(ACCOUNT_ORDER_STATUS.accepted) },
    { key: ACCOUNT_ORDER_STATUS.ready, value: getAccountOrderStatusTitle(ACCOUNT_ORDER_STATUS.ready) },
    { key: ACCOUNT_ORDER_STATUS.delivering, value: getAccountOrderStatusTitle(ACCOUNT_ORDER_STATUS.delivering) },
    { key: ACCOUNT_ORDER_STATUS.finished, value: getAccountOrderStatusTitle(ACCOUNT_ORDER_STATUS.finished) },
    { key: ACCOUNT_ORDER_STATUS.canceled, value: getAccountOrderStatusTitle(ACCOUNT_ORDER_STATUS.canceled) },
];

export function getAccountOrderStatusTitle(status) {
    switch (status) {
        case ACCOUNT_ORDER_STATUS.pending:
            return i18n.t('enums.accountOrderStatus.PENDING');
        case ACCOUNT_ORDER_STATUS.accepted:
            return i18n.t('enums.accountOrderStatus.ACCEPTED');
        case ACCOUNT_ORDER_STATUS.ready:
            return i18n.t('enums.accountOrderStatus.READY');
        case ACCOUNT_ORDER_STATUS.delivering:
            return i18n.t('enums.accountOrderStatus.DELIVERING');
        case ACCOUNT_ORDER_STATUS.finished:
            return i18n.t('enums.accountOrderStatus.FINISHED');
        case ACCOUNT_ORDER_STATUS.canceled:
            return i18n.t('enums.accountOrderStatus.CANCELED');
        default:
            return '';
    }
}

export function getAccountOrderStatusInfo(order) {
  const title = getAccountOrderStatusTitle((order || {}).status);
  switch ((order || {}).status) {
    case ACCOUNT_ORDER_STATUS.pending:
        return {
          title: (order || {}).cooking
            ? i18n.t("pending-orders.COOKING")
            : title,
          icon: (order || {}).cooking ? "orderStatusCooking" : "orderStatusNotCooking"
        };
    case ACCOUNT_ORDER_STATUS.accepted:
      return {
        title: (order || {}).cooking
            ? i18n.t("pending-orders.COOKING")
            : title,
        icon: (order || {}).cooking ? "orderStatusCooking" : "orderStatusNotCooking"
      };
    case ACCOUNT_ORDER_STATUS.ready:
      return { title, icon: "orderStatusReady" };
    case ACCOUNT_ORDER_STATUS.finished:
      return { title, icon: "orderStatusFinished" };
    case ACCOUNT_ORDER_STATUS.canceled:
      return { title, icon: "orderStatusCanceled" };
    default:
        return {};
  }
}

export function getAccountOrderDeliveryStrategyList() {
    return [
        { key: DELIVERY_STRATEGY.asap, value: i18n.t('enums.accountOrderDeliveryStrategy.ASAP') },
        { key: DELIVERY_STRATEGY.concreteTime, value: i18n.t('enums.accountOrderDeliveryStrategy.CONCRETETIME') },
    ];
}

export function getOfficeSourceTypes() {
    return [
        { key: OFFER_SOURCE_TYPE.business, value: i18n.t('enums.officeSourceType.BUSINESS') },
        { key: OFFER_SOURCE_TYPE.account, value: i18n.t('enums.officeSourceType.ACCOUNT') },
    ];
}

export const SHIFT_TYPE = {
    Normal: 0,
    Backup: 1,
    ControlCenter: 2,
};

export const SHIFT_TYPE_VALUES = {
    [SHIFT_TYPE.Normal]: i18n.t('shifts.type.NORMAL'),
    [SHIFT_TYPE.Backup]: i18n.t('shifts.type.BACKUP'),
    [SHIFT_TYPE.ControlCenter]: i18n.t('shifts.type.CONTROL_CENTER'),
}

export const ORDER_STATE_COLOR = {
    blue: 'blue',
    white: 'white',
};

export const SINGLE_EVENTS = {
    pusherEvent: 'pusherEvent',
};

export const TYPE_NOTIFICATION = {
    notificationType: 0,
    warningType: 1,
    errorType: 2,
    importantWarningType: 4,
};

export const SEVERITY_NOTIFICATION = {
    lostCommunication: 0,
    orderNotDeliveredInTime: 1,
    courierLost: 2,
    boxLost: 3,
    courierStopped: 4,
    orderCanLate: 5,
    assistanceExists: 6,
    courierCommingBack: 7,
    courierReadyToDispatch: 8,
    courierWrongRoute: 9,
    consumerOrderCreated: 10,
    orderIsNotAssignedTimeout: 11,
};

export const NOTIFICATION_COLOR_TYPE = {
    red: '#ff5b2e',
    blue: '#007aff',
};

export const SOUND_NOTIFICATION = {
    [SEVERITY_NOTIFICATION.assistanceExists]: 'sounds.soundNotification.ASSISTANCE_ADDED_SOUND',
    [SEVERITY_NOTIFICATION.courierCommingBack]: 'sounds.soundNotification.COURIER_COMING_BACK_SOUND',
    [SEVERITY_NOTIFICATION.orderNotDeliveredInTime]: 'sounds.soundNotification.ORDER_ERROR_SOUND',
    [SEVERITY_NOTIFICATION.orderCanLate]: 'sounds.soundNotification.ORDER_WARNING_SOUND',
    [SEVERITY_NOTIFICATION.courierReadyToDispatch]: 'sounds.soundNotification.READY_COURIER_SOUND',
    [SEVERITY_NOTIFICATION.orderIsNotAssignedTimeout]: 'sounds.soundNotification.ORDER_NOT_ASSIGNED_SOUND',
    [SEVERITY_NOTIFICATION.courierWrongRoute]: 'sounds.soundNotification.COURIER_WRONG_ROUTE_SOUND',
    [SEVERITY_NOTIFICATION.consumerOrderCreated]: 'sounds.soundNotification.CONSUMER_ORDER_CREATED_SOUND',
    newConsumerOrderCreatedFromLoop: 'sounds.soundNotification.NEW_ORDER_CREATED',
    newOrderCreated: 'sounds.soundNotification.NEW_ORDER_CREATED',
    newUnreadMessage: 'sounds.soundNotification.NEW_UNREAD_MESSAGE',
    newDialogItem: 'sounds.soundNotification.NEW_DIALOG_ITEM',
};

export const SOUND_ERRORS = {
    notAllowedError: 'NotAllowedError',
};

export const MODEL_TYPE = {
    order: 'order',
    orderEdit: 'orderEdit',
    categoryProductModelOverride: 'categoryProductModelOverride',
    address: 'address',
    location: 'location',
    payments: 'payments',
    content: 'content',
    controlCenterInfo: 'controlCenterInfo',
    externalCycleModel: 'externalCycleModel',
    driverInfoModel: 'driverInfoModel',
    routeEstimation: 'routeEstimation',
    addon: 'addon',
    user: 'user',
    cloudPrinters: 'cloudPrinters',
    depot: 'depotModel',
    region: 'regionModel',
    providers: 'providersModel',
    reshipmentOrder: 'reshipmentOrder',
    userServerModel: 'userServerModel',
    restaurantRegionServerModel: 'restaurantRegionServerModel',
    depotUserModel: 'depotUserModel',
    depotDeliveryPriceModel: 'depotDeliveryPriceModel',
    depotDeliveryPriceServerModel: 'depotDeliveryPriceServerModel',
    regionDeliveryPriceModel: 'regionDeliveryPriceModel',
    travelDataModel: 'travelDataModel',
    namedPlaces: 'namedPlaces',
    serviceCities: 'serviceCities',
    shifts: 'shifts',
    currentShift: 'currentShift',
    deliveryZoneModel: 'deliveryZoneModel',
    offerModel: 'offerModel',
    businessInfoModel: 'businessInfoModel',
    categoryModelOverride: 'categoryModelOverride',
    categoryModelForOverride: 'categoryModelForOverride',
    categoryModel: 'categoryModel',
    categoryProductModel: 'categoryProductModel',
    categoryProductModelForOverride: 'categoryProductModelForOverride',
    productOptionModel: 'productOptionModel',
    extraModel: 'extraModel',
    extraModelOverride: 'extraModelOverride',
    optionModelOverride: 'optionModelOverride',
    suboptionsOverride: 'suboptionsOverride',
    extraSuboptionModel: 'extraSuboptionModel',
    scheduleModel: 'scheduleModel',
    refundModel: 'refundModel',
    serviceFeeModel: 'serviceFeeModel',
    cancelOrderModel: 'cancelOrderModel',
    courierDeliveryFees: 'courierDeliveryFees',
    courierDeliveryFeesServer: 'courierDeliveryFeesServer',
    listPromotionModel: 'listPromotionModel',
    depotIntegrationModel: 'depotIntegrationModel',
    serviceFeeServerModel: 'serviceFeeServerModel',
    listPromotionServerModel: 'listPromotionServerModel',
    refundServerModel: 'refundServerModel',
    extraSuboptionServerModel: 'extraSuboptionServerModel',
    extraServerModel: 'extraServerModel',
    productOptionServerModel: 'productOptionServerModel',
    categoryProductServerModel: 'categoryProductServerModel',
    categoryServerModel: 'categoryServerModel',
    businessInfoServerModel: 'businessInfoServerModel',
    namedPlacesServerModel: 'namedPlacesServerModel',
    contentServerModel: 'contentServerModel',
    assistanceServerModel: 'assistanceServerModel',
    addonServerModel: 'addonServerModel',
    addressServerModel: 'addressServerModel',
    locationServerModel: 'locationServerModel',
    credentialsServerModel: 'credentialsServerModel',
    depotServerModel: 'depotServerModel',
    visualSettingsServerModel: 'visualSettingsServerModel',
    shiftServerModel: 'shiftServerModel',
    currentShiftDepotServerModel: 'currentShiftDepotServerModel',
    currentShiftServerModel: 'currentShiftServerModel',
    controlCenterActivateServerModel: 'controlCenterActivateServerModel',
    controlCenterDeactivateServerModel: 'controlCenterDeactivateServerModel',
    controlCenterEdit: 'controlCenterEdit',
    adminOrderProductServerModel: 'adminOrderProductServerModel',
    driverTip: 'driverTip',
    marketplacePages: 'marketplacePages',
    menuTemplate: 'menuTemplate',
    depotsMenuCopy: 'depotsMenuCopy',
    marketplace: 'marketplace',
    marketplaceSettings: 'marketplaceSettings',
    parentMenu: 'parentMenu',
    reviewExtra: 'reviewExtra',
    billingAddressModel: 'billingAddressModel',
    gallery: 'gallery',
    provider: 'provider',
    operationTiming: 'operationTiming',
    extraModelWithOverrides: 'extraModelWithOverrides',
    optionModelWithOverrides: 'optionModelWithOverrides',
    suboptionsModelWithOverrides: 'suboptionsModelWithOverrides',
    courierAppSettings: 'courierAppSettings',
    courierDisplaySettings: 'courierDisplaySettings',
    courierInterfaceSettings: 'courierInterfaceSettings',
    messageGallery: 'messageGallery',
    messageGalleryServerModel: 'messageGalleryServerModel',
    editCustomerOrder: 'editCustomerOrder',
    consumerServerModel: 'consumerServerModel',
    fleet: 'fleetModel',
    fleetTelemetry: 'fleetTelemetryModel',
    marketplaceCampaignModel: 'marketplaceCampaignModel',
    reconciliations: 'reconciliations',
    reconciliationsSettingsTemplateModel: 'reconciliationsSettingsTemplateModel',
    reconciliationsPeriodsTemplateModel: 'reconciliationsPeriodsTemplateModel',
    reconciliationsPeriodTemplateModel: 'reconciliationsPeriodTemplateModel',
    creditCard: 'creditCard',
    loyaltyRegisterVoucher: 'loyaltyRegisterVoucher',
    loyaltyRegisterVoucherServerModel: 'loyaltyRegisterVoucherServerModel',
};

// Loyalty campaign

export const CAMPAIGN_TYPES = {
    push: 0,
}

export const CAMPAIGN_RECURRING_TYPES = {
    none: 0,
    daily: 1,
    weekly: 2,
    biWeekly: 3,
    monthly: 4
}

export const CAMPAIGN_AUDIENCE_FILTER_TYPES = {
    equal: 1,
    notEqual: 2,
    less: 3,
    more: 4,
    lessOrEqual: 5,
    moreOrEqual: 6
}

export const CAMPAIGN_TIME_SPAN_AUDIENCE_FILTER_TYPES = {
    creationDateTime: 0,
    birthdate: 1,
    consumerProfile_FirstOrderDate: 2,
    consumerProfile_LastOrderDate: 3,
    consumerProfile_LastRatingDate: 11
}

export const CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES = {
    language: 4,
    gender: 5,
    currentTerritory: 6,
    depot: 18,
    consumerProfile_LastDepot: 19,
    consumerProfile_LastTerritory: 20,
}

export const CAMPAIGN_NUMERIC_AUDIENCE_FILTER_TYPES = {
    consumerProfile_TotalOrders: 7,
    consumerProfile_TotalTakeAwayOrders: 8,
    consumerProfile_TotalDeliveryOrders: 9,
    consumerProfile_AverageOrderAmount: 10,
    consumerProfile_AmountOfPositiveReviews: 12,
    consumerProfile_AmountOfNegativeReviews: 13,
    consumerProfile_AmountOfPositiveDeliveryReviews: 14,
    consumerProfile_AmountOfNegativeDeliveryReviews: 15,
    consumerProfile_LastRating: 16,
    consumerProfile_LastDeliveryRating: 17
}

export const CAMPAIGN_PERIOD_TYPES = {
    hours: 'hours',
    days: 'days',
    weeks: 'weeks',
    months: 'months'
}

export const getCampaignTypes = () => [
    // { key: CAMPAIGN_TYPES.email, value: i18n.t('enums.campaign.CAMPAIGN_TYPE_EMAIL') },
    { key: CAMPAIGN_TYPES.push, value: i18n.t('enums.campaign.CAMPAIGN_TYPE_PUSH') },
];

export const AUDIENCE_FILTER_SELECT_GROUP_TYPES = {
    lookup: 0,
    timeSpan: 1,
    numeric: 2
}

export const AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES = {
    yesterday: -1,
    today: 0,
    tomorrow: 1,
    after: 2,
    before: 3
}

export const CAMPAIGN_EXECUTION_STATE_TYPES= {
    pending: 0,
    active: 1,
    finished: 2
}

export const CAMPAIGN_GANDER = {
    male: '0',
    female: '1',
}

export const CAMPAIGN_AUDIENCE_FILTER_TYPE = {
    ByChannel: 'By Channel',
    ByCriteria: 'By Criteria'
}

export const CAMPAIGN_AUDIENCE_FILTER_CHANNEL_TYPES = {
    All: 1,
    Anonymous: 2
}

export const getAudienceFilterType = () => [
    { key: CAMPAIGN_AUDIENCE_FILTER_TYPE.ByChannel, value: i18n.t('enums.campaign.CAMPAIGN_AUDIENCE_FILTER_TYPE_BY_CHANNEL') },
    { key: CAMPAIGN_AUDIENCE_FILTER_TYPE.ByCriteria, value: i18n.t('enums.campaign.CAMPAIGN_AUDIENCE_FILTER_TYPE_BY_CRITERIA') },
]

export const getAudienceFilterChannel = () => [
    { key: CAMPAIGN_AUDIENCE_FILTER_CHANNEL_TYPES.All, value: i18n.t('enums.campaign.CAMPAIGN_AUDIENCE_FILTER_CHANNEL_TYPE_ALL') },
    { key: CAMPAIGN_AUDIENCE_FILTER_CHANNEL_TYPES.Anonymous, value: i18n.t('enums.campaign.CAMPAIGN_AUDIENCE_FILTER_CHANNEL_TYPE_ANONYMOUS') },
]

export const getAudienceFilterSelectTypes = (isRemoveTerritories = false) => {
    let defaultSelect = [
        //Lookup
        { value: i18n.t('enums.campaign.AUDIENCE_LOOKUP_GROUP_LABEL'), isGroupTitle: true },
        { key: CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.language, value: i18n.t('enums.campaign.AUDIENCE_LOOKUP_TYPES_LANGUAGE') },
        { key: CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.gender, value: i18n.t('enums.campaign.AUDIENCE_LOOKUP_TYPES_GENDER') },
        { key: CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.currentTerritory, value: i18n.t('enums.campaign.AUDIENCE_LOOKUP_TYPES_CURRENT_TERRITORY') },
        { key: CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.depot, value: i18n.t('enums.campaign.AUDIENCE_LOOKUP_TYPES_DEPOT') },
        { key: CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.consumerProfile_LastDepot, value: i18n.t('enums.campaign.AUDIENCE_LOOKUP_TYPES_LAST_DEPOT') },
        { key: CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.consumerProfile_LastTerritory, value: i18n.t('enums.campaign.AUDIENCE_LOOKUP_TYPES_LAST_TERRITORY') },
        //TimeSpan
        { value: i18n.t('enums.campaign.AUDIENCE_TIME_SPAN_GROUP_LABEL'), isGroupTitle: true },
        { key: CAMPAIGN_TIME_SPAN_AUDIENCE_FILTER_TYPES.creationDateTime, value: i18n.t('enums.campaign.AUDIENCE_TIME_SPAN_TYPES_CREATION_DATE_TIME') },
        { key: CAMPAIGN_TIME_SPAN_AUDIENCE_FILTER_TYPES.birthdate, value: i18n.t('enums.campaign.AUDIENCE_TIME_SPAN_TYPES_BIRTH_DATE') },
        { key: CAMPAIGN_TIME_SPAN_AUDIENCE_FILTER_TYPES.consumerProfile_FirstOrderDate, value: i18n.t('enums.campaign.AUDIENCE_TIME_SPAN_TYPES_FIRST_ORDER_DATE') },
        { key: CAMPAIGN_TIME_SPAN_AUDIENCE_FILTER_TYPES.consumerProfile_LastOrderDate, value: i18n.t('enums.campaign.AUDIENCE_TIME_SPAN_TYPES_LAST_ORDER_DATE') },
        { key: CAMPAIGN_TIME_SPAN_AUDIENCE_FILTER_TYPES.consumerProfile_LastRatingDate, value: i18n.t('enums.campaign.AUDIENCE_TIME_SPAN_TYPES_LAST_RATING_DATE') },
        //Numeric
        { value: i18n.t('enums.campaign.AUDIENCE_NUMERIC_GROUP_LABEL'), isGroupTitle: true },
        { key: CAMPAIGN_NUMERIC_AUDIENCE_FILTER_TYPES.consumerProfile_TotalOrders, value: i18n.t('enums.campaign.AUDIENCE_NUMERIC_TYPES_TOTAL_ORDERS') },
        { key: CAMPAIGN_NUMERIC_AUDIENCE_FILTER_TYPES.consumerProfile_TotalTakeAwayOrders, value: i18n.t('enums.campaign.AUDIENCE_NUMERIC_TYPES_TOTAL_TAKE_AWAY_ORDERS') },
        { key: CAMPAIGN_NUMERIC_AUDIENCE_FILTER_TYPES.consumerProfile_TotalDeliveryOrders, value: i18n.t('enums.campaign.AUDIENCE_NUMERIC_TYPES_TOTAL_DELIVERY_ORDERS') },
        { key: CAMPAIGN_NUMERIC_AUDIENCE_FILTER_TYPES.consumerProfile_AverageOrderAmount, value: i18n.t('enums.campaign.AUDIENCE_NUMERIC_TYPES_AVERAGE_ORDER_AMOUNT') },
        { key: CAMPAIGN_NUMERIC_AUDIENCE_FILTER_TYPES.consumerProfile_AmountOfPositiveReviews, value: i18n.t('enums.campaign.AUDIENCE_NUMERIC_TYPES_AMOUNT_OF_POSITION_REVIEW') },
        { key: CAMPAIGN_NUMERIC_AUDIENCE_FILTER_TYPES.consumerProfile_AmountOfNegativeReviews, value: i18n.t('enums.campaign.AUDIENCE_NUMERIC_TYPES_AMOUNT_OF_NEGATIVE_REVIEW') },
        { key: CAMPAIGN_NUMERIC_AUDIENCE_FILTER_TYPES.consumerProfile_AmountOfPositiveDeliveryReviews, value: i18n.t('enums.campaign.AUDIENCE_NUMERIC_TYPES_AMOUNT_OF_POSITIVE_DELIVERY_REVIEWS') },
        { key: CAMPAIGN_NUMERIC_AUDIENCE_FILTER_TYPES.consumerProfile_AmountOfNegativeDeliveryReviews, value: i18n.t('enums.campaign.AUDIENCE_NUMERIC_TYPES_AMOUNT_OF_NEGATIVE_DELIVERY_REVIEWS') },
        { key: CAMPAIGN_NUMERIC_AUDIENCE_FILTER_TYPES.consumerProfile_LastRating, value: i18n.t('enums.campaign.AUDIENCE_NUMERIC_TYPES_LAST_RATING') },
        { key: CAMPAIGN_NUMERIC_AUDIENCE_FILTER_TYPES.consumerProfile_LastDeliveryRating, value: i18n.t('enums.campaign.AUDIENCE_NUMERIC_TYPES_LAST_DELIVERY_RATING') },
    ];

    if(isRemoveTerritories) {
        defaultSelect = defaultSelect.filter(item =>
            ![CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.currentTerritory, CAMPAIGN_LOOKUP_AUDIENCE_FILTER_TYPES.consumerProfile_LastTerritory].includes(item.key)
        )
    }

    return defaultSelect
}

export const audienceFilterTimeStampTypes = () => Object.values(CAMPAIGN_TIME_SPAN_AUDIENCE_FILTER_TYPES);

export const getCampaignRecurringTypes = () => [
    { key: CAMPAIGN_RECURRING_TYPES.none, value: i18n.t('enums.campaign.RECURRING_TYPES_NONE') },
    { key: CAMPAIGN_RECURRING_TYPES.daily, value: i18n.t('enums.campaign.RECURRING_TYPES_DAILY') },
    { key: CAMPAIGN_RECURRING_TYPES.weekly, value: i18n.t('enums.campaign.RECURRING_TYPES_WEEKLY') },
    { key: CAMPAIGN_RECURRING_TYPES.biWeekly, value: i18n.t('enums.campaign.RECURRING_TYPES_BI_WEEKLY') },
    { key: CAMPAIGN_RECURRING_TYPES.monthly, value: i18n.t('enums.campaign.RECURRING_TYPES_MONTHLY') },
];

export const getAudienceFilterTypes = (isLookupOnly = false, timestamp) => {
    const lookupTypes = [
        { key: CAMPAIGN_AUDIENCE_FILTER_TYPES.equal, value: i18n.t(`enums.campaign.${timestamp ? 'TIMESTAMP_' : ''}AUDIENCE_FILTER_TYPES_EQUAL`) },
        { key: CAMPAIGN_AUDIENCE_FILTER_TYPES.notEqual, value: i18n.t(`enums.campaign.${timestamp ? 'TIMESTAMP_' : ''}AUDIENCE_FILTER_TYPES_NOT_EQUAL`) },
    ]

    if(isLookupOnly) {
        return lookupTypes
    }

    return [
        ...lookupTypes,
        { key: CAMPAIGN_AUDIENCE_FILTER_TYPES.less, value: i18n.t(`enums.campaign.${timestamp ? 'TIMESTAMP_' : ''}AUDIENCE_FILTER_TYPES_LESS`) },
        { key: CAMPAIGN_AUDIENCE_FILTER_TYPES.more, value: i18n.t(`enums.campaign.${timestamp ? 'TIMESTAMP_' : ''}AUDIENCE_FILTER_TYPES_MORE`) },
        { key: CAMPAIGN_AUDIENCE_FILTER_TYPES.lessOrEqual, value: i18n.t(`enums.campaign.${timestamp ? 'TIMESTAMP_' : ''}AUDIENCE_FILTER_TYPES_LESS_OR_EQUAL`) },
        { key: CAMPAIGN_AUDIENCE_FILTER_TYPES.moreOrEqual, value: i18n.t(`enums.campaign.${timestamp ? 'TIMESTAMP_' : ''}AUDIENCE_FILTER_TYPES_MORE_OR_EQUAL`) },
    ]
}

export const getCampaignPeriodTypes = () => [
    { key: CAMPAIGN_PERIOD_TYPES.hours, value: i18n.t('enums.campaign.CAMPAIGN_PERIOD_TYPES_HOUR') },
    { key: CAMPAIGN_PERIOD_TYPES.days, value: i18n.t('enums.campaign.CAMPAIGN_PERIOD_TYPES_DAY') },
    { key: CAMPAIGN_PERIOD_TYPES.weeks, value: i18n.t('enums.campaign.CAMPAIGN_PERIOD_TYPES_WEEK') },
    { key: CAMPAIGN_PERIOD_TYPES.months, value: i18n.t('enums.campaign.CAMPAIGN_PERIOD_TYPES_MONTH') },
]

export const getCampaignDaysOffsetTypes = () => [
    { key: AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.yesterday, value: i18n.t('enums.campaign.AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES_YESTERDAY') },
    { key: AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.today, value: i18n.t('enums.campaign.AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES_TODAY') },
    { key: AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.tomorrow, value: i18n.t('enums.campaign.AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES_TOMORROW') },
    { key: AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.after, value: i18n.t('enums.campaign.AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES_AFTER') },
    { key: AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES.before, value: i18n.t('enums.campaign.AUDIENCE_FILTER_SELECT_DAYS_OFFSET_TYPES_BEFORE') },
]

export const getCampaignGenders = () => ([
    {key: CAMPAIGN_GANDER.male, value: i18n.t('gender.MALE')},
    {key: CAMPAIGN_GANDER.female, value: i18n.t('gender.FEMALE')},
]);

// Loyalty campaign end

export const WAITER_TYPES = {
    button: 'button',
    buttonSvg: 'buttonSvg',
    buttonSpan: 'buttonSpan',
    buttonDiv: 'buttonDiv',
    svg: 'svg',
    span: 'span',
};

export const RESTAURANT_STATES = {
    autoMode: 0,
    manualMode: 1,
};

export const COURIER_ASSOCIATION_MODE = {
    push: 0,
    pull: 1,
    manualPull: 2,
    autoPuch: 3
};

export const COURIER_GROUP_MODEL_TYPE = {
    onRouteGroup: 0,
    approvedGroup: 1,
    comingBackGroup: 2,
    awayGroup: 3,
    waitingGroup: 4,
    backupGroup: 5,
    externalCycle: 6,
};

export const CONFIRM_DIALOG_TYPE = {
    goAwayShift: 0,
    cancelShift: 1,
    finishShift: 2,
    deleteItem: 3,
    reload: 4,
    default: 5,
};

export const COURIER_STATE = {
    startBackup: 'startBackup',
    startShift: 'startShift',
    endShift: 'endShift',
    cancelShift: 'cancelShift',
    finishShift: 'finishShift',
    goAwayShift: 'goAwayShift',
    endBackup: 'endBackup',
    default: null,
};

export const PUBLIC_DATA_KEYS = {
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    tokenType: 'tokenType',
    rememberMe: 'rememberMe',
    currentUserName: 'currentUserName',
};

export const COUNTRY_CURRENCY_CODES = {
    IL: 'ILS',
    ME: 'EUR',
    RS: 'EUR',
    RU: 'RUB',
    UA: 'UAH',
    US: 'USD',
    RO: 'RON',
    MX: 'MXN',
    KZ: 'KZT',
};

export const DATECONTROL_TYPE = {
    time: 'time',
    date: 'date',
    datetime: 'datetime',
};

export const SHOW_DROPDOWN_NAME = {
    history: 0,
    suggest: 1,
};

export function groupingStrategies() {
    return [
        {
            key: 0,
            value: i18n.t('settings.settings.numericalSettings.ALGO_STRATEGY_COURIER_BASED'),
        },
        {
            key: 1,
            value: i18n.t('settings.settings.numericalSettings.ALGO_STRATEGY_IMMEDIATE_COOKING'),
        },
        {
            key: 2,
            value: i18n.t('settings.settings.numericalSettings.ALGO_STRATEGY_IMMEDIATE_COOKING_ON_DEMAND'),
        },
    ];
}

export function optimizationStrategies() {
    return [
        {
            key: 0,
            value: i18n.t('settings.settings.numericalSettings.ALGO_OPTIMIZATION_STRATEGY_BY_TIME'),
        },
        {
            key: 1,
            value: i18n.t('settings.settings.numericalSettings.ALGO_OPTIMIZATION_STRATEGY_BY_DISTANCE'),
        },
    ];
}

export function courierAssociationModes() {
    return [
        {
            key: 0,
            value: i18n.t('settings.settings.numericalSettings.COURIER_ASSOCIATION_MODE_PUSH'),
        },
        {
            key: 1,
            value: i18n.t('settings.settings.numericalSettings.COURIER_ASSOCIATION_MODE_PULL'),
        },
        // {
        //     key: 2,
        //     value: i18n.t('settings.settings.numericalSettings.COURIER_ASSOCIATION_MODE_MANUAL_PULL'),
        // },
        {
            key: 3,
            value: i18n.t('settings.settings.numericalSettings.COURIER_ASSOCIATION_MODE_AUTO_PUSH'),
        },
        {
            key: 5,
            value: i18n.t('settings.settings.numericalSettings.COURIER_ASSOCIATION_MODE_AUTO_PUSH_WITH_CONFIRM'),
        },
        {
            key: 4,
            value: i18n.t('settings.settings.numericalSettings.COURIER_ASSOCIATION_MODE_LINES_AUTO_PUSH'),
        }
    ];
}

export function languages() {
  return [
    {
      key: "en",
      value: "English"
    },
    {
      key: "he",
      value: "Hebrew"
    },
    {
      key: "de",
      value: "Deutsch"
    },
    {
      key: "ru",
      value: "Russian"
    },
    {
      key: "es",
      value: "Spanish"
    },
    {
      key: "el",
      value: "Greek"
    },
    {
      key: "ar",
      value: "Arabian"
    },
    {
      key: "pl",
      value: "Polish",
    }
  ];
}

export function sellerBankCodes() {
    return [
        { key: 4, value: 'בנק יהב לעובדי המדינה' },
        { key: 9, value: 'בנק הדואר' },
        { key: 10, value: 'בנק לאומי' },
        { key: 11, value: 'בנק דיסקונט' },
        { key: 12, value: 'בנק הפועלים' },
        { key: 13, value: 'בנק אגוד לישראל' },
        { key: 14, value: 'בנק אוצר החייל' },
        { key: 17, value: 'בנק מרכנתיל דסקונט' },
        { key: 20, value: 'בנק מזרחי-טפחות' },
        { key: 22, value: 'סיטיבנק' },
        { key: 23, value: 'HSBC' },
        { key: 26, value: 'UBANK' },
        { key: 31, value: 'הבין לאומי הראשון' },
        { key: 34, value: 'בנק ערבי ישראלי' },
        { key: 39, value: 'בנק אוף אינדיה' },
        { key: 46, value: 'בנק מסד' },
        { key: 52, value: 'בנק פאגי' },
        { key: 54, value: 'בנק ירושלים' },
        { key: 68, value: 'דקסיה ישראל' },
        { key: 77, value: 'בנק לאומי למשכנתאות' },
        { key: 90, value: 'בנק דיסקונט למשכנתאות' },
        { key: 91, value: 'משכן בנהפ למשכנתאות' },
        { key: 92, value: 'הבין לאומי למשכנתאות' },
    ];
}

export function sellerPersonBusinessTypes() {
    return [
        { key: 1608, value: 'אביזרי אופנה' },
        { key: 1816, value: 'שירותי ייעוץ' },
        { key: 1313, value: 'אהילים ונברשות' },
        { key: 1504, value: 'אולם שמחות' },
        { key: 1408, value: 'אופטיקה' },
        { key: 119, value: 'אופנועים' },
        { key: 114, value: 'אופניים' },
        { key: 1118, value: 'שמרטפים' },
        { key: 1507, value: 'אכסניות וכפרי נופש' },
        { key: 1511, value: 'אסטרולוגיה גרפולוגיה' },
        { key: 1814, value: 'ארנק אלקטרוני' },
        { key: 1212, value: 'ארנקים תיקים ומזודות' },
        { key: 1510, value: 'אתרי בידור' },
        { key: 1201, value: 'בגדי גברים' },
        { key: 1203, value: 'בגדי ילדים' },
        { key: 1202, value: 'בגדי נשים' },
        { key: 1207, value: 'בדי רפוד' },
        { key: 1213, value: 'בדים וטכסטיל' },
        { key: 1106, value: 'בזאר' },
        { key: 1705, value: 'בזק' },
        { key: 1606, value: 'בטוח' },
        { key: 1709, value: 'ביטוח לאומי' },
        { key: 1822, value: 'בית לוויות' },
        { key: 1615, value: 'בית ספר לנהיגה' },
        { key: 1831, value: 'בניית אתרי אינטרנט' },
        { key: 1105, value: 'בשר דגים ועופות' },
        { key: 1512, value: 'בתי דפוס' },
        { key: 1506, value: 'בתי הבראה ומרגוע' },
        { key: 1001, value: 'בתי כל בו' },
        { key: 1004, value: 'בתי כל בו ארציים' },
        { key: 1505, value: 'בתי מלון ופנסיון' },
        { key: 1406, value: 'בתי מרקחת' },
        { key: 1818, value: 'בתי מרקחת חו"ל' },
        { key: 1114, value: 'בתי ספר' },
        { key: 1503, value: 'בתי קפה ומילק בר' },
        { key: 1627, value: 'גלידריות' },
        { key: 1314, value: 'גלריות' },
        { key: 1836, value: 'גמח הלוואות' },
        { key: 1220, value: 'גני ילדים' },
        { key: 1508, value: 'דיסקוטק, פאבים' },
        { key: 1321, value: 'דלק לחימום ביתי' },
        { key: 116, value: 'דלק שרות עצמי' },
        { key: 1003, value: 'דרגאסטורים' },
        { key: 1125, value: 'דשנים וכימיקלים' },
        { key: 1120, value: 'הובלות והסעות' },
        { key: 1513, value: 'הוצאות לאור' },
        { key: 1607, value: 'היכרויות ושדוכים' },
        { key: 1800, value: 'היפר שוק תנובה' },
        { key: 1200, value: 'עצוב אפנה ' },
        { key: 1803, value: 'הלוואות פקדונות חסכו' },
        { key: 1804, value: 'העברת כספים לכרטיס' },
        { key: 1830, value: 'תקליטן DJ' },
        { key: 1620, value: 'הרבלייף' },
        { key: 1311, value: 'השכרת רכב' },
        { key: 117, value: 'וידאו אוטומט' },
        { key: 1215, value: 'וילונות ודקורטיבים' },
        { key: 1621, value: 'ועדי בתים' },
        { key: 1419, value: 'חב. ציי רכב )כ. דלק(' },
        { key: 1625, value: 'חברות בניה' },
        { key: 1704, value: 'חברות עירוניות' },
        { key: 118, value: 'חברות תעופה' },
        { key: 1707, value: 'חברת חשמל' },
        { key: 1415, value: 'חומרי ניקוי' },
        { key: 1413, value: 'חיות' },
        { key: 1630, value: 'חלפני כספים' },
        { key: 1310, value: 'חלקי חלוף לרכב' },
        { key: 1315, value: 'חנויות ללא מכס' },
        { key: 1509, value: 'חניוני נופש' },
        { key: 1808, value: 'חניונים' },
        { key: 1113, value: 'שירותי בטחון ושמירה' },
        { key: 1412, value: 'טבק וצרכי עישון' },
        { key: 1626, value: 'טלפונים סלולריים' },
        { key: 1214, value: 'טפטים' },
        { key: 1210, value: 'כובעים' },
        { key: 1300, value: 'כלי בית ומטבח' },
        { key: 1217, value: 'כלי מיטה' },
        { key: 1414, value: 'כלי נגינה ותקליטים' },
        { key: 1401, value: 'כלי עבודה ובנין צבע' },
        { key: 1309, value: 'כלי רכב' },
        { key: 1205, value: 'לבני נשים וגברים' },
        { key: 1825, value: 'לוטו טוטו' },
        { key: 1622, value: 'ליסינג' },
        { key: 1806, value: 'מבוגרים' },
        { key: 1122, value: 'מגרשי מכוניות משומשות' },
        { key: 1112, value: 'מדליות מטבעות ובולים' },
        { key: 1123, value: 'מוניות' },
        { key: 1312, value: 'מוסכים' },
        { key: 111, value: 'מועדוני ספורט' },
        { key: 1418, value: 'מועצות ועיריות' },
        { key: 1500, value: 'מופעי בידור ותרבות' },
        { key: 1402, value: 'מוצרי גומי ופלסטיק' },
        { key: 1302, value: 'מוצרי גז' },
        { key: 1301, value: 'מוצרי חשמל גדולים' },
        { key: 1219, value: 'מוצרי חשמל קטנים' },
        { key: 1614, value: 'מוצרי פרסום' },
        { key: 1110, value: 'מזון טבעי' },
        { key: 1817, value: 'מזון מהיר' },
        { key: 1305, value: 'מזכרות מתנות חפצי חן' },
        { key: 1411, value: 'מחנאות וספורט' },
        { key: 1116, value: 'מחשבים' },
        { key: 1128, value: 'נגריות/רפדיות' },
        { key: 1802, value: 'מים ומטהרי מים' },
        { key: 1101, value: 'מינימרקטים' },
        { key: 1619, value: 'מכבסות/מתפרות' },
        { key: 1628, value: 'מכון רפואי' },
        { key: 1124, value: 'מכונות כתיבה/תפירה' },
        { key: 1308, value: 'מכונות לתעשיה חקלאות' },
        { key: 1319, value: 'מכוני לימוד והדרכה' },
        { key: 1603, value: 'מכוני קוסמטיקה' },
        { key: 1623, value: 'מכירות פומביות' },
        { key: 1711, value: 'מכירת דירות /נדל"ן' },
        { key: 1702, value: 'מכירת יחידות נופש' },
        { key: 1121, value: 'מכירת כלי רכב' },
        { key: 1320, value: 'מכירת מכשירי גז' },
        { key: 112, value: 'מכשירי בריאות' },
        { key: 1218, value: 'מלכ"ר (מוסד ללא רווח)' },
        { key: 1824, value: 'מנויי לוטו טוטו' },
        { key: 1126, value: 'מסגרות לתמונות' },
        { key: 1127, value: 'מסגריות' },
        { key: 1827, value: 'מסחר במט"ח' },
        { key: 1501, value: 'מסעדות' },
        { key: 1602, value: 'מספרות ופאות' },
        { key: 1601, value: 'מעבדות' },
        { key: 1103, value: 'מעדניה/קונדיטוריה' },
        { key: 1009, value: 'מפעל הפיס/טוטו' },
        { key: 1409, value: 'צלמים' },
        { key: 1811, value: 'משחקים און ליין' },
        { key: 1108, value: 'משקאות חריפים וקלים' },
        { key: 1708, value: 'משרדי ממשלה' },
        { key: 1317, value: 'משרדי פרסום' },
        { key: 1838, value: 'מתן אשרות שהיה' },
        { key: 1616, value: 'מתנ"ס' },
        { key: 113, value: 'מתנ"סים' },
        { key: 1208, value: 'נעליים' },
        { key: 1629, value: 'נשק ותחמושת' },
        { key: 1211, value: 'סדקית ומוצרי תפירה' },
        { key: 1303, value: 'רהיטים' },
        { key: 1703, value: 'סוכנויות ביטוח' },
        { key: 1631, value: 'סוכני דלק' },
        { key: 1100, value: 'סופרמרקטים' },
        { key: 1502, value: 'סטקיות ומזנונים' },
        { key: 1624, value: 'סיטונאים' },
        { key: 1223, value: 'סינמטק/בתי קולנוע' },
        { key: 1216, value: 'סלון לכלות' },
        { key: 1812, value: 'סליקה פנים קיבוצית' },
        { key: 1117, value: 'סניטציה' },
        { key: 500, value: 'סניפים' },
        { key: 1810, value: 'ספא' },
        { key: 1801, value: 'ספק אינטרנט' },
        { key: 1799, value: 'ספק תוכן אינטרנט' },
        { key: 1115, value: 'ספריות וידיאו' },
        { key: 1405, value: 'ספרים' },
        { key: 1400, value: 'עו"ד' },
        { key: 1828, value: 'עמותות ואגודות' },
        { key: 1835, value: 'עמותות חרדיות - תרומות' },
        { key: 1834, value: 'עמותות חרדיות-חינוך' },
        { key: 1837, value: 'עמותות כלליות' },
        { key: 1832, value: 'עמותות מוסלמיות' },
        { key: 1833, value: 'עמותות נוצריות' },
        { key: 1306, value: 'עתיקות ותשמישי קדושה' },
        { key: 1813, value: 'פיצריות' },
        { key: 1104, value: 'פירות וירקות' },
        { key: 1204, value: 'פרוות ובגדי עור' },
        { key: 1410, value: 'פרחים ומשתלות' },
        { key: 1304, value: 'ציוד לתינוק' },
        { key: 1404, value: 'ציוד משרדי' },
        { key: 1613, value: 'ציוד משרדי' },
        { key: 1807, value: 'ציוד רפואי' },
        { key: 1209, value: 'צמר וסריגה' },
        { key: 1416, value: 'צעצועים' },
        { key: 1005, value: 'קו-אופ' },
        { key: 1006, value: 'קואופ ירושלים' },
        { key: 1107, value: 'קונדיטוריות וממתקים' },
        { key: 1007, value: 'קופות חולים' },
        { key: 1710, value: 'קזינו' },
        { key: 1109, value: 'קיוסק' },
        { key: 1221, value: 'קייטנה' },
        { key: 1823, value: 'קייטרינג' },
        { key: 1821, value: 'קפה ותבלינים' },
        { key: 1612, value: 'רואה חשבון/מהנדסים' },
        { key: 1712, value: 'רכישת ניירות ערך' },
        { key: 1600, value: 'רפואת שיניים' },
        { key: 1706, value: 'רשות השידור' },
        { key: 1002, value: 'רשתות מזון ארציות' },
        { key: 1206, value: 'שטיחים' },
        { key: 1805, value: 'שיווק ישיר' },
        { key: 1119, value: 'שירותי רכב' },
        { key: 1316, value: 'שירותים ממשלתיים' },
        { key: 1819, value: 'שירותים משרדיים' },
        { key: 1129, value: 'שלטים' },
        { key: 1131, value: 'שמאים' },
        { key: 2000, value: 'שק"ם' },
        { key: 1605, value: 'שרותי רפואה/בתי חולי' },
        { key: 1609, value: 'תוכנות מחשבים' },
        { key: 1403, value: 'תחנות דלק' },
        { key: 1318, value: 'תיווך' },
        { key: 1829, value: 'תיירות למרפא' },
        { key: 1307, value: 'תכשיטים ושעונים' },
        { key: 1407, value: 'תמרוקיות' },
        { key: 1611, value: 'תנועות נוער' },
        { key: 1604, value: 'תעופה תיירות ונסיעות' },
        { key: 1809, value: 'תערוכות וכנסים' },
        { key: 1417, value: 'תקליטים' },
        { key: 1610, value: 'תקשורת וטלפונים' },
        { key: 115, value: 'תקשורת טלויזיה בכבלי' },
        { key: 1815, value: 'תרומה' },
        { key: 1130, value: 'תריסים' },
    ];
}

export function sellerIncList() {
    return [
        { key: 0, value: 'Private Individual/Sole Proprietorship' },
        { key: 2, value: 'Licensed Company' },
        { key: 3, value: 'Corporation' },
        { key: 4, value: 'Registered Partnership' },
        { key: 5, value: 'Exempt Company' },
        { key: 6, value: 'Non Profit' },
        { key: 7, value: 'LLC Limited Liability Company' },
    ];
}

export const CONTROL_TYPE = {
    address: 'address',
    arrayItems: 'arrayItems',
    checkbox: 'checkbox',
    controlCentersList: 'controlCentersList',
    usersPopup: 'usersPopup',
    groupCheckbox: 'groupCheckbox',
    columnGroupCheckbox: 'columnGroupCheckbox',
    cityAddress: 'cityAddress',
    date: 'date',
    inlineLabel: 'inlineLabel',
    squareAssociatedLocations: 'squareAssociatedLocations',
    editTable: 'editTable',
    frame: 'frame',
    group: 'group',
    image: 'image',
    imageEdit: 'imageEdit',
    languageEditor: 'languageEditor',
    lapse: 'lapse',
    schedule: 'schedule',
    label: 'label',
    link: 'link',
    linkAction: 'linkAction',
    linkIconComponent: 'linkIconComponent',
    linkControl: 'linkControl',
    linkDownload: 'linkDownload',
    linkSvg: 'linkSvg',
    listItem: 'listItem',
    mapAddress: 'mapAddress',
    mapRegion: 'mapRegion',
    mapByLocation: 'mapByLocation',
    number: 'number',
    password: 'password',
    icon: 'icon',
    iconsSelector: 'iconsSelector',
    allowedDepotsList: 'AllowedDepotsList',
    paymeConfiguration: 'paymeConfiguration',
    paymeRadioButtons: 'paymeRadioButtons',
    depotPaymentSelector: 'depotPaymentSelector',
    presetTiming: 'presetTiming',
    roleSelector: 'roleSelector',
    sectionGroup: 'sectionGroup',
    sectionName: 'sectionName',
    sectionTab: 'sectionTab',
    select: 'select',
    multiselect: 'multiselect',
    selectInterval: 'selectInterval',
    selectWithAdd: 'selectWithAdd',
    slider: 'slider',
    switch: 'switch',
    switchGroups: 'switchGroups',
    text: 'text',
    inputNumber: 'inputNumber',
    inputText: 'inputText',
    inputPercent: 'inputPercent',
    textArea: 'textArea',
    vehicleSelector: 'vehicleSelector',
    radioButton: 'radioButton',
    button: 'button',
    richText: 'richText',
    nestedItem: 'nestedItem',
    iconWithQuantity: 'iconWithQuantity',
    copyToClipboard: 'copyToClipboard',
    mobileMenu: 'mobileMenu',
    deepLinkStat: 'deepLinkStat',
    audienceFilter: 'audienceFilter',
    bonusVoucher: 'bonusVoucher',
    modalSelect: 'modalSelect',
    translationTable: 'translationTable',
    reconciliationFeesTable: 'reconciliationFeesTable',
    reconciliationPeriodsTable: 'reconciliationPeriodsTable',
    reconciliationMultiSelect: 'reconciliationMultiSelect',
    defaultMultiSelect: 'defaultMultiSelect',
};

export const MODAL_COMPONENT_TYPE = {
    customersList: 'customersList'
}

export const CONTROL_STYLE_TYPE = {
    inline: 'inline',
};

export const FILTER_TYPE = {
    and: 'and',
    or: 'or',
    from: 'from',
    to: 'to',
};

export const PRODUCER_FLEET_TYPE = {
    None: 0,
    NuiFleet: 1,
}

export const VEHICLE_FLEET_TYPE = {
    motorScooter: 0,
    bicycle: 1,
    car: 2

}


export const VEHICLE_TYPE = {
    delivery: 0,
    kickScooter: 1,
    car: 2,
};
export const VEHICLE_CYCLE_TYPE = {
    delivery: 1,
    kickScooter: 0,
    car: 2,
};

export const MODE_SWITCHER_TYPE = {
    takeAway: 0,
    delivery: 1,
    extraSize: 2,
};

export const getModeSwitcherValues = withExtraSize => {
    const items = [
        {
            key: MODE_SWITCHER_TYPE.delivery,
            value: 'scooter_delivery',
            tooltip: i18n.t('dashboard.orders.DELIVERY_ORDERS_MODE_LABEL'),
        },
        {
            key: MODE_SWITCHER_TYPE.takeAway,
            value: 'basket',
            tooltip: i18n.t('dashboard.orders.TAKEAWAY_ORDERS_MODE_LABEL'),
        },
    ];

    if (withExtraSize) {
        items.push({
            key: MODE_SWITCHER_TYPE.extraSize,
            value: 'extraSize',
            tooltip: i18n.t('dashboard.orders.EXTRA_SIZE_ORDERS_MODE_LABEL'),
        });
    }

    return items;
};

export function getVehicleTypeTooltip(vehicleType) {
    switch (vehicleType) {
        case 'delivery':
            return i18n.t('settings.users.VEHICLE_DELIVERY_TOOLTIP');
        case 'kickScooter':
            return i18n.t('settings.users.VEHICLE_KICK_SCOOTER_TOOLTIP');
        case 'car':
            return i18n.t('settings.users.VEHICLE_CAR_TOOLTIP');
        default:
            return '';
    }
}

export const SECTION_MODEL_FACTORY_TYPE = {
    settings: 'settings',
    shifts: 'shifts',
    orderingMenu: 'orderingMenu',
    marketplaceSettings: 'marketplaceSettings',
    marketplaceOrderingMenu: 'marketplaceOrderingMenu',
    reconciliations: 'reconciliations',
};

export const TYPE_SECTION_DEPOT_MODEL = {
    depotMenu: '/settings/depots/depotmenu',
    depotsMenuCopy: '/settings/depots/createMenu',
    depotsEditPrice: '/settings/depots/editprice',
    depotusers: '/settings/depots/users',
    depotusersEdit: '/settings/depots/users/edit',
    depotusersNew: '/settings/depots/users/new',
    depotusersConfirmPassword: '/settings/depots/users/confirmPassword',
    depotIntegrations: '/settings/depots/integrations',
    depotIntegrationsNew: '/settings/depots/integrations/new',
    depotDefaultRegionControlCenters: '/settings/depots/regionControls',
    depotDefaultRegionControlCentersNew: '/settings/depots/regionControls/new',
    depotDefaultRegionControlCentersEdit: '/settings/depots/regionControls/edit',
    provider: '/settings/depots/provider',
    providerNew: '/settings/depots/provider/new',
    providerEdit: '/settings/depots/provider/edit',
    depotPaymeCreditInfo: '/settings/depots/paymecreditinfo',
    depotPaymeCreditInfoEdit: '/settings/depots/paymecreditinfo/edit',
    depotsEdit: '/settings/depots/depot/edit',
    telegram: '/settings/depots/telegram',
    operationMode: '/settings/depots/operationMode',
    operationModeEdit: '/settings/depots/operationMode/edit',
    operationModeNew: '/settings/depots/operationMode/new',
    operationModeActive: '/settings/depots/operationModeActive',
};

export const TYPE_SECTION_MODEL = {
    users: '/settings/users',
    usersEdit: '/settings/users/edit',
    usersNew: '/settings/users/new',
    cloudPrinters: '/settings/cloudPrinters',
    cloudPrintersEdit: '/settings/cloudPrinters/edit',
    cloudPrintersNew: '/settings/cloudPrinters/new',
    userConfirmPassword: '/settings/users/confirmPassword',
    depots: '/settings/depots',
    depotsEdit: '/settings/depots/edit',
    depotsNew: '/settings/depots/new',
    depotsPriceApplyTo: '/settings/depots/depotsPriceApplyTo',
    depotsMenu: '/settings/depots/menu',
    depotpaymewallet: '/settings/depotpaymewallet',
    depotpaymewalletNew: '/settings/depotpaymewallet/new',
    depotsCategories: '/settings/depots/categories',
    depotsCategoriesEdit: '/settings/depots/categories/edit',
    depotSquareAssociatedLocation: '/settings/depots/depotSquareAssociatedLocation',
    generalSettings: '/settings/generalsettings',
    personalSettings: '/settings/personalSettings',
    personalSettingsConfirmPassword: '/settings/personalSettings/confirmPassword',
    telegramSettings: '/settings/telegramsettings',
    restaurantPaymeWallet: '/settings/restaurantPaymeWallet',
    restaurantPaymeWalletNew: '/settings/restaurantPaymeWallet/new',
    regions: '/settings/regions',
    regionsEdit: '/settings/regions/edit',
    regionsNew: '/settings/regions/new',
    serviceCities: '/settings/serviceCities',
    serviceCitiesNew: '/settings/serviceCities/new',
    serviceCitiesEdit: '/settings/serviceCities/edit',
    searchShortcuts: '/settings/searchShortcuts',
    searchShortcutsNew: '/settings/searchShortcuts/new',
    searchShortcutsEdit: '/settings/searchShortcuts/edit',
    marketplace: '/settings/marketplace',
    marketplaceNew: '/settings/marketplace/new',
    marketplaceEdit: '/settings/marketplace/edit',
    marketplaceSettings: '/settings/marketplaceSettings',
    namedPlaces: '/settings/namedPlaces',
    namedPlacesNew: '/settings/namedPlaces/new',
    namedPlacesEdit: '/settings/namedPlaces/edit',
    controlCenters: '/settings/controlCenters',
    controlCentersEdit: '/settings/controlCenters/edit',
    controlCentersActivate: '/settings/controlCenters/activate',
    controlCentersDeactivate: '/settings/controlCenters/deactivate',
    controlCentersChangeuser: '/settings/controlCenters/changeUser',
    operationTiming: '/settings/operationTiming',
    operationTimingEdit: '/settings/operationTiming/edit',
    operationTimingNew: '/settings/operationTiming/new',
    courierDeliveryFees: '/settings/courierDeliveryFees',
    courierAppSettings: '/settings/courierAppSettings',
    businessAppSettings: '/settings/businessAppSettings',
    fleet: '/settings/fleet',
    fleetEdit: '/settings/fleet/edit',
    fleetNew: '/settings/fleet/new',
    fleetTelemetry: '/settings/fleet/telemetry',
    providers: '/settings/providers',
    providersEdit: '/settings/providers/edit',
    providersNew: '/settings/providers/new',
    deliveryProviders: '/settings/deliveryProviders',
    deliveryProvidersEdit: '/settings/deliveryProviders/edit',
    deliveryProvidersNew: '/settings/deliveryProviders/new',
};

export const SHIFTS_MODEL = {
    history: '/shifts/history',
    providers: '/shifts/providers',
    historyEdit: '/shifts/history/edit',
    shiftHistory: '/shifts/history/shiftHistory',
    currentShiftUsers: '/shifts/currentShift/users',
    currentShiftDepots: '/shifts/currentShift/depots',
    currentShiftDepotEdit: '/shifts/currentShift/depot/edit',
    currentShiftHistory: '/shifts/currentShift/history',
    currentShiftProviderHistory: '/shifts/currentShiftProvider/history',
};

export const TYPE_SECTION_MENUTEMPLATE_MODEL = {
    menuTemplateMenu: '/marketplace-settings/app-builder/orderingmenu',
    menuTemplateMenuCopy: '/marketplace-settings/app-builder/menuTemplates/createMenu',
    menuTemplateEdit: '/marketplace-settings/app-builder/menuTemplates/menuTemplate/edit',
};

export const TYPE_SECTION_MESSAGE_MODEL = {
    messageInfo: '/marketplace-settings/app-builder/marketplace/message/edit',
    messageGallery: '/marketplace-settings/app-builder/marketplace/message/gallery',
    messageGalleryEdit: '/marketplace-settings/app-builder/marketplace/message/gallery/edit',
    messageGalleryNew: '/marketplace-settings/app-builder/marketplace/message/gallery/new',
};

export const MARKETPLACE_SETTINGS_MODEL = {
    marketplaceSettings: '/marketplace-settings/marketplaceSettings',
    paymentsConfiguration: '/marketplace-settings/paymentsConfiguration',
    serviceFee: '/marketplace-settings/serviceFee',
    offers: '/marketplace-settings/offers',
    offersEdit: '/marketplace-settings/offers/edit',
    offersNew: '/marketplace-settings/offers/new',
    //loyalty
    loyalty: '/marketplace-settings/loyalty',
    giftCard: '/marketplace-settings/loyalty/giftCard',
    giftCardCategoryProducts: '/marketplace-settings/loyalty/giftCardCategoryProducts',
    giftCardCategoryProductsNew: '/marketplace-settings/loyalty/giftCardCategoryProducts/new',
    giftCardCategoryProductsEdit: '/marketplace-settings/loyalty/giftCardCategoryProducts/edit',
    referral: '/marketplace-settings/loyalty/referral',
    campaigns: '/marketplace-settings/loyalty/campaigns',
    campaignsNew: '/marketplace-settings/loyalty/campaigns/new',
    campaignsEdit: '/marketplace-settings/loyalty/campaigns/edit',
    campaignsExecutions: '/marketplace-settings/loyalty/campaigns/executions',
    loyaltySettingsRegistrationBonus: '/marketplace-settings/loyalty/registrationBonus',
    loyaltySettingsCashback: '/marketplace-settings/loyalty/cashback',
    //loyalty end
    //delivery
    delivery: '/marketplace-settings/delivery',
    deliveryZones: '/marketplace-settings/delivery/deliveryZones',
    deliveryZonesEdit: '/marketplace-settings/delivery/deliveryZones/edit',
    deliveryZonesNew: '/marketplace-settings/delivery/deliveryZones/new',
    customerDeliveryPrice: '/marketplace-settings/delivery/customerDeliveryPrice',
    driverTip: '/marketplace-settings/delivery/driverTip',
    listPromotion: '/marketplace-settings/app-builder/listPromotion',
    //delivery end
    //app builder
    appBuilder: '/marketplace-settings/app-builder',
    listPromotionNew: '/marketplace-settings/app-builder/listPromotion/new',
    listPromotionEdit: '/marketplace-settings/app-builder/listPromotion/edit',
    marketplace: '/marketplace-settings/app-builder/marketplace',
    marketplaceNew: '/marketplace-settings/app-builder/marketplace/new',
    marketplaceEdit: '/marketplace-settings/app-builder/marketplace/edit',
    businessCategories: '/marketplace-settings/app-builder/businessCategories',
    businessCategoriesNew: '/marketplace-settings/app-builder/businessCategories/new',
    businessCategoriesEdit: '/marketplace-settings/app-builder/businessCategories/edit',
    menuTemplate: '/marketplace-settings/app-builder/menuTemplates',
    menuTemplateNew: '/marketplace-settings/app-builder/menuTemplates/new',
    menuTemplateEdit: '/marketplace-settings/app-builder/menuTemplates/edit',
    cancelReason: '/marketplace-settings/app-builder/cancelReason',
    cancelReasonNew: '/marketplace-settings/app-builder/cancelReason/new',
    cancelReasonEdit: '/marketplace-settings/app-builder/cancelReason/edit',
    pages: '/marketplace-settings/app-builder/pages',
    pagesNew: '/marketplace-settings/app-builder/pages/new',
    pagesEdit: '/marketplace-settings/app-builder/pages/edit',
    //app builder end
    deepLink: '/marketplace-settings/deepLink'
};
export const RECONCILIATIONS_MODEL = {
    settings: '/reconciliations/settings',
    settingsTemplateNew: '/reconciliations/settings/template/new',
    settingsTemplateEdit: '/reconciliations/settings/template/edit',
    periods: '/reconciliations/periods',
    periodsView: '/reconciliations/periods/view',
    periodsEdit: '/reconciliations/periods/edit'
};

export const ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL = {
    orderingBusinessParentMenu: '/marketplace-settings/app-builder/orderingmenu/parentMenu',
    orderingBusinessParentMenuEdit: '/settings/app-builder/orderingmenu/parentMenu/edit',
    orderingMenu: '/marketplace-settings/app-builder/orderingmenu',
    orderingMenuEdit: '/marketplace-settings/app-builder/orderingmenu/edit',
    orderingMenuBusinesInfo: '/marketplace-settings/app-builder/orderingmenu/businesInfo',
    orderingMenuSchedule: '/marketplace-settings/app-builder/orderingmenu/schedule',
    orderingMenuDeliveryZones: '/marketplace-settings/app-builder/orderingmenu/deliveryZones',
    orderingMenuDeliveryZonesEdit: '/marketplace-settings/app-builder/orderingmenu/deliveryZones/edit',
    orderingMenuDeliveryZonesNew: '/marketplace-settings/app-builder/orderingmenu/deliveryZones/new',
    orderingMenuDiscounts: '/marketplace-settings/app-builder/orderingmenu/discounts',
    orderingMenuDiscountsNew: '/marketplace-settings/app-builder/orderingmenu/discounts/new',
    orderingMenuDiscountsEdit: '/marketplace-settings/app-builder/orderingmenu/discounts/edit',
    orderingBusinessMenu: '/marketplace-settings/app-builder/orderingmenu/menu',
    orderingBusinessMenuEdit: '/marketplace-settings/app-builder/orderingmenu/menu/edit',
    orderingBusinessMenuNew: '/marketplace-settings/app-builder/orderingmenu/menu/new',
    orderingBusinessMenuExtras: '/marketplace-settings/app-builder/orderingmenu/menuExtras',
    orderingBusinessMenuExtrasNew: '/marketplace-settings/app-builder/orderingmenu/menuExtras/new',
    orderingBusinessMenuExtrasEdit: '/marketplace-settings/app-builder/orderingmenu/menuExtras/edit',
    orderingBusinessMenuExtrasOption: '/marketplace-settings/app-builder/orderingmenu/menuExtras/option',
    orderingBusinessMenuExtrasOptionEdit: '/marketplace-settings/app-builder/orderingmenu/menuExtras/option/edit',
    orderingBusinessMenuExtrasOptionNew: '/marketplace-settings/app-builder/orderingmenu/menuExtras/option/new',
    orderingBusinessMenuExtrasSuboption: '/marketplace-settings/app-builder/orderingmenu/menuExtras/suboption',
    orderingBusinessMenuExtrasSubsuboptionEdit: '/marketplace-settings/app-builder/orderingmenu/menuExtras/suboption/edit',
    orderingBusinessMenuExtrasSubsuboptionNew: '/marketplace-settings/app-builder/orderingmenu/menuExtras/suboption/new',
    orderingBusinessMenuCategoryProducts: '/marketplace-settings/app-builder/orderingmenu/categoryProducts',
    orderingBusinessMenuCategoryProductsNew: '/marketplace-settings/app-builder/orderingmenu/categoryProducts/new',
    orderingBusinessMenuCategoryProductsEdit: '/marketplace-settings/app-builder/orderingmenu/categoryProducts/edit',
    orderingBusinessMenuCategoryProductIngredient: '/marketplace-settings/app-builder/orderingmenu/categoryProductIngredient',
    orderingBusinessMenuCategoryProductIngredientNew:
        '/marketplace-settings/app-builder/orderingmenu/categoryProductIngredient/new',
    orderingBusinessMenuCategoryProductIngredientEdit:
        '/marketplace-settings/app-builder/orderingmenu/categoryProductIngredient/edit',
    orderingBusinessMenuCategoryExtras: '/marketplace-settings/app-builder/orderingmenu/categoryExtras',
    orderingBusinessMenuCategoryExtrasNew: '/marketplace-settings/app-builder/orderingmenu/categoryExtras/new',
    orderingBusinessMenuCategoryExtrasEdit: '/marketplace-settings/app-builder/orderingmenu/categoryExtras/edit',
    orderingBusinessMenuCategoryExtrasOption: '/marketplace-settings/app-builder/orderingmenu/categoryExtras/option',
    orderingBusinessMenuCategoryExtrasOptionEdit: '/marketplace-settings/app-builder/orderingmenu/categoryExtras/option/edit',
    orderingBusinessMenuCategoryExtrasOptionNew: '/marketplace-settings/app-builder/orderingmenu/categoryExtras/option/new',
    orderingBusinessMenuCategoryExtrasSuboption: '/marketplace-settings/app-builder/orderingmenu/categoryExtras/suboption',
    orderingBusinessMenuCategoryExtrasSuboptionEdit: '/marketplace-settings/app-builder/orderingmenu/categoryExtras/suboption/edit',
    orderingBusinessMenuCategoryExtrasSuboptionNew: '/marketplace-settings/app-builder/orderingmenu/categoryExtras/suboption/new',
    orderingMenuCategories: '/marketplace-settings/app-builder/orderingmenu/orderingMenuCategories',
    orderingMenuTranslations: '/marketplace-settings/app-builder/orderingmenu/orderingMenuTranslations',
    orderingMenuCategoriesNew: '/marketplace-settings/app-builder/orderingmenu/orderingMenuCategories/new',
    reviewProductExtra: '/marketplace-settings/app-builder/orderingmenu/reviewExtra',
    overrideExtra: '/marketplace-settings/app-builder/orderingmenu/overrideExtra',
    overrideOption: '/marketplace-settings/app-builder/orderingmenu/overrideOption',
    overrideSuboption: '/marketplace-settings/app-builder/orderingmenu/overrideSuboption',
    gallery: '/marketplace-settings/app-builder/orderingmenu/gallery',
    galleryEdit: '/marketplace-settings/app-builder/orderingmenu/gallery/edit',
    galleryNew: '/marketplace-settings/app-builder/orderingmenu/gallery/new',
    referral: '/settings/referral',
    driverTip: '/settings/driverTip',
    listPromotion: '/settings/listPromotion',
    listPromotionNew: '/settings/listPromotion/new',
    listPromotionEdit: '/settings/listPromotion/edit',
    serviceFee: '/settings/serviceFee',
    deliveryZones: '/settings/deliveryZones',
    deliveryZonesEdit: '/settings/deliveryZones/edit',
    deliveryZonesNew: '/settings/deliveryZones/new',
    pages: '/settings/pages',
    pagesNew: '/settings/pages/new',
    pagesEdit: '/settings/pages/edit',
    menuTemplate: '/settings/menuTemplate',
    menuTemplateNew: '/settings/menuTemplate/new',
    menuTemplateEdit: '/settings/menuTemplate/edit',
    provider: '/settings/provider',
    providerEdit: '/settings/provider/edit',
    providerNew: '/settings/provider/new',
};

export const ORDERING_MENU_TYPE_SECTION_MODEL = {
    parentMenuProductOverride: '/settings/orderingmenu/parentMenu/productOverride',
    parentMenuSuboptionsOverride: '/settings/orderingmenu/parentMenu/suboptionOverride',
    parentMenuCategoryOverride: '/settings/orderingmenu/parentMenu/categoryOverride',
    parentMenuOptionsOverride: '/settings/orderingmenu/parentMenu/optionOverride',
    parentMenuExtraOverride: '/settings/orderingmenu/parentMenu/extraOverride',
    orderingBusinessParentMenu: '/settings/orderingmenu/parentMenu',
    orderingBusinessParentMenuEdit: '/settings/orderingmenu/parentMenu/edit',
    orderingMenu: '/settings/orderingmenu',
    orderingMenuEdit: '/settings/orderingmenu/edit',
    orderingMenuBusinesInfo: '/settings/orderingmenu/businesInfo',
    orderingMenuSchedule: '/settings/orderingmenu/schedule',
    orderingMenuDeliveryZones: '/settings/orderingmenu/deliveryZones',
    orderingMenuDeliveryZonesEdit: '/settings/orderingmenu/deliveryZones/edit',
    orderingMenuDeliveryZonesNew: '/settings/orderingmenu/deliveryZones/new',
    orderingMenuDiscounts: '/settings/orderingmenu/discounts',
    orderingMenuDiscountsNew: '/settings/orderingmenu/discounts/new',
    orderingMenuDiscountsEdit: '/settings/orderingmenu/discounts/edit',
    orderingBusinessMenu: '/settings/orderingmenu/menu',
    orderingBusinessMenuEdit: '/settings/orderingmenu/menu/edit',
    orderingBusinessMenuNew: '/settings/orderingmenu/menu/new',
    orderingBusinessMenuExtras: '/settings/orderingmenu/menuExtras',
    orderingBusinessMenuExtrasNew: '/settings/orderingmenu/menuExtras/new',
    orderingBusinessMenuExtrasEdit: '/settings/orderingmenu/menuExtras/edit',
    orderingBusinessMenuExtrasOption: '/settings/orderingmenu/menuExtras/option',
    orderingBusinessMenuExtrasOptionEdit: '/settings/orderingmenu/menuExtras/option/edit',
    orderingBusinessMenuExtrasOptionNew: '/settings/orderingmenu/menuExtras/option/new',
    orderingBusinessMenuExtrasSuboption: '/settings/orderingmenu/menuExtras/suboption',
    orderingBusinessMenuExtrasSubsuboptionEdit: '/settings/orderingmenu/menuExtras/suboption/edit',
    orderingBusinessMenuExtrasSubsuboptionNew: '/settings/orderingmenu/menuExtras/suboption/new',
    orderingBusinessMenuCategoryProducts: '/settings/orderingmenu/categoryProducts',
    orderingBusinessMenuCategoryProductsNew: '/settings/orderingmenu/categoryProducts/new',
    orderingBusinessMenuCategoryProductsEdit: '/settings/orderingmenu/categoryProducts/edit',
    orderingBusinessMenuCategoryProductsDeepLinkEdit: '/settings/orderingmenu/categoryProducts/deepLink',
    orderingBusinessMenuCategoryProductIngredient: '/settings/orderingmenu/categoryProductIngredient',
    orderingBusinessMenuCategoryProductIngredientNew: '/settings/orderingmenu/categoryProductIngredient/new',
    orderingBusinessMenuCategoryProductIngredientEdit: '/settings/orderingmenu/categoryProductIngredient/edit',
    orderingBusinessMenuCategoryExtras: '/settings/orderingmenu/categoryExtras',
    orderingBusinessMenuCategoryExtrasNew: '/settings/orderingmenu/categoryExtras/new',
    orderingBusinessMenuCategoryExtrasEdit: '/settings/orderingmenu/categoryExtras/edit',
    orderingBusinessMenuCategoryExtrasOption: '/settings/orderingmenu/categoryExtras/option',
    orderingBusinessMenuCategoryExtrasOptionEdit: '/settings/orderingmenu/categoryExtras/option/edit',
    orderingBusinessMenuCategoryExtrasOptionNew: '/settings/orderingmenu/categoryExtras/option/new',
    orderingBusinessMenuCategoryExtrasSuboption: '/settings/orderingmenu/categoryExtras/suboption',
    orderingBusinessMenuCategoryExtrasSuboptionEdit: '/settings/orderingmenu/categoryExtras/suboption/edit',
    orderingBusinessMenuCategoryExtrasSuboptionNew: '/settings/orderingmenu/categoryExtras/suboption/new',
    orderingMenuCategories: '/settings/orderingmenu/orderingMenuCategories',
    orderingMenuLink: '/settings/orderingmenu/link',
    reviewProductExtra: '/settings/orderingmenu/reviewExtra',
    overrideExtra: '/settings/orderingmenu/overrideExtra',
    overrideOption: '/settings/orderingmenu/overrideOption',
    overrideSuboption: '/settings/orderingmenu/overrideSuboption',
    gallery: '/settings/orderingmenu/gallery',
    galleryEdit: '/settings/orderingmenu/gallery/edit',
    galleryNew: '/settings/orderingmenu/gallery/new',
    translations: '/settings/orderingmenu/translations',
};

export const BILLING_PATHS = {
    billing: '/billing',
    payment: '/billing/payment',
    address: '/billing/address',
    plan: '/billing/plan',
    usages: '/billing/usages',
};

export const CHART_MODEL = {
    controlCenters: 'controlCenters',
    controlCentersTable: 'controlCentersTable',
    depotTableData: 'depotTableData',
    inProcessOrders: 'inProcessOrders',
    ordersAverage: 'ordersAverage',
    ordersAveragePerHours: 'ordersAveragePerHours',
    reportDeliveryTimeItems: 'reportDeliveryTimeItems',
    reportAverageDeliveryTimeItems: 'reportAverageDeliveryTimeItems',
    reportAverageDeliveryTime: 'reportAverageDeliveryTime',
    reportControlCenterAverageDeliveryTime: 'reportControlCenterAverageDeliveryTime',
    reportDayAverageDeliveryTime: 'reportDayAverageDeliveryTime',
    controlCentersAverageDeliveryTime: 'controlCentersAverageDeliveryTime',
    sales: 'sales',
    deepLinkStats: 'deepLinkStats',
};

export const TYPE_CHART = {
    comboChart: 'ComboChart',
    pieChart: 'PieChart',
    columnChart: 'ColumnChart',
    table: 'Table',
    gauge: 'Gauge',
    barChartStacked: 'BarChartStacked',
    areaChart: 'AreaChart',
    line: 'Line',
};

export const POPUP_ITEM_TYPE = {
    managerItem: 'managerItem',
    courierShiftItem: 'courierShiftItem',
    shiftsFilterItem: 'shiftsFilterItem',
    dateRange: 'dateRange'
}

export const MODEL_CONTROL_TYPE = {
    default: 'default',
    add: 'add',
    back: 'back',
    iconControl: 'iconControl',
    comboUrl: 'comboUrl',
    sortMode: 'sortMode',
    filter: 'filter',
    importFile: 'importFile',
    actionButton: 'actionButton',
    listItems: 'listItems',
    dropdown: 'dropdown'
};

export const TYPE_ACTION = {
    view: 'view',
    new: 'new',
    edit: 'edit',
};

export const TYPE_EDIT_ACTION = {
    add: 'add',
    edit: 'edit',
    delete: 'delete',
    reorder: 'reorder',
};

export const USER_FILTER_ROLES = {
    supervisor: 'Supervisor',
    deliveryManager: 'DeliveryManager',
    depot: 'Depot',
    courier: 'Courier',
    supportOperator: 'SupportOperator',
    controlCenterManager: 'ControlCenterManager',
}

export const USER_ROLES = {
    all: '*',
    supervisor: 'supervisor',
    deliveryManager: 'deliveryManager',
    depot: 'depot',
    courier: 'courier',
    supportOperator: 'supportOperator',
    controlCenterManager: 'controlCenterManager',
};

export const ROLES_MANAGER_TYPE = {
    menu: 'menu',
    [CONTROL_TYPE.linkAction]: CONTROL_TYPE.linkAction,
    [CONTROL_TYPE.linkIconComponent]: CONTROL_TYPE.linkIconComponent,
    sectionItemEdit: 'sectionItemEdit',
    readonlyPage: 'readonlyPage',
};

export const TYPE_STORAGE = {
    local: 'local',
    session: 'session',
    db: 'db',
};

export const FIND_ADDRESS_TYPE_ACTION = {
    find: 'find',
    findByAddress: 'findByAddress',
    history: 'history',
    restore: 'restore',
    search: 'search',
};

export const TYPE_STATUS = {
    textActive: 'textActive',
    textDisabled: 'textDisabled',
};

export const TYPE_MENU = {
    header: 'header',
    settings: 'settings',
    shifts: 'shifts',
    marketplaceSettings: 'marketplaceSettings',
    depotSettings: 'depotSettings',
    menuTemplate: 'menuTemplate',
    loyaltyMenu: 'loyaltyMenu',
    deliveryMenu: 'deliveryMenu',
    appBuilderMenu: 'appBuilderMenu',
    orderingMenu: 'orderingMenu',
    orderingMenuMenuTemplate: 'orderingMenuMenuTemplate',
    messageSettings: 'messageSettings',
    reconciliations: 'reconciliations',
};

export const FORMAT_RELATIVE_TIME_ONLY_TYPE = {
    d: 'd',
    h: 'h',
    m: 'm',
};

export const TITLE_RELATIVE_TIME = type => {
    switch (type) {
        case 'd':
            return i18n.t('covertTime.TIME_DAY');
        case 'h':
            return i18n.t('covertTime.TIME_HOUR');
        case 'm':
            return i18n.t('covertTime.TIME_MIN');
        default:
            return '';
    }
};

export const PUSHER_EVENTS = {
    OrderStateUpdate: 'Order State Update Event',
    LocationUpdate: 'Location Update Event',
    NewOrder: 'New Order Event',
    UpdateOrder: 'Update Order Event',
    NewTakeaway: 'New Takeaway Event',
    UpdateTakeaway: 'Update Takeaway Event',
    CycleStateUpdate: 'Cycle State Update Event',
    CycleDispatched: 'Cycle Dispatched Event',
    AssociateUpdate: 'Associate Update Event',
    GroupUpdate: 'Group Update Event',
    CycleReorder: 'Cycle Reorder Event',
    CycleRouteUpdate: 'Cycle Route Update Event',
    AssistanceUpdate: 'Assistance Update Event',
    UserUpdated: 'User Updated Event',
    UserDeleted: 'User Deleted Event',
    CourierShiftCreated: 'Courier Shift Created Event',
    ManualAssociateUpdate: 'Manual Associate Update Event',
    GroupReorder: 'Group Reorder Event',
    ResaurantStateUpdate: 'Restaurant State Update Event',
    ConnectionStateChanged: 'Connection State Changed Event',
    NotificationAdded: 'Notification Added Event',
    notificationServiceRestarted: 'Notification Service Restart',
    NotificationDeleted: 'Notification Deleted Event',
    GroupLockUpdate: 'Group Lock Update Event',
    CourierLockUpdate: 'Courier Lock Update Event',
    ManualUnassociateUpdate: 'Manual Unassociate Update Event',
    SetAway: 'Set Away Event',
    SoundNotificationMessage: 'Sound Notification Message Event',
    ManualUnassociateUpdateList: 'Manual Unassociate Update List Event',
};

export const START_MODULE = {
    main: 'main',
    restaurateur: 'restaurateur',
};

export const COUNTRY_CURRENCY_CODES_VALUE = {
    IL: '₪',
    ME: '€',
    RS: '€',
    RU: 'руб',
    UA: 'грн',
    US: '$',
    PL: 'zł',
};

export const TYPE_PATTERN = {
    email: 'email',
    userName: 'userName',
    userNameWithSpaces: 'userNameWithSpaces',
    userPassword: 'userPassword',
    onlyNumbers: 'onlyNumbers',
    slug: 'slug',
};

export const DIALOG_TYPE_CONFIRM = {
    accept: 'accept',
    acknowledge: 'acknowledge',
    reject: 'reject',
    delay: 'delay',
    ready: 'ready',
    delivery: 'delivery',
    delivered: 'delivered',
    kitchen: 'kitchen',
    cooking: 'cooking',
};

export const CREDIT_ACCEPT = {
    off: 0,
    infoOnly: 1,
    selling: 2,
};

export const INTEGRATION_TYPE = {
    none: 0,
    box: 1,
    email: 2,
    presto: 3,
    beeComm: 4,
    telegram: 5,
    paidIt: 6,
    netlunch: 7,
    sms: 8,
    clickeat: 9,
    wix: 10,
    ordering: 11,
    foodNinjas: 12,
    bamps: 13,
    nikosha: 14,
    caspit: 15,
    aviv: 16,
    uber: 17,
    mcDonalds: 18,
    wooCommerce: 19,
    tabit: 20,
    livraro: 21,
    shopify: 22,
    beeCommCloud: 23,
    deliverect: 24,
    gloriaFood: 25,
    kitchenHub: 26,
    valueCard: 27,
    squareUp: 28,
    noga: 29,
    mishloha: 31,
    halalDeliveryUk: 32,
    wolt: 33,
    wixMarketPlace: 34,
    forgastro: 40,
    merkus: 42,
    portel: 43,
    restimo: 44,
    cibeez: 47,
    appyRo: 48,
    kiosk: 50,
    internalOutsourcing: 100,
};

export const HALF_OPTION = {
    0: i18n.t('pending-orders.PENDING_ORDER_HALF_OPTION_ZERRO_LABEL'),
    1: i18n.t('pending-orders.PENDING_ORDER_HALF_OPTION_FIRST_LABEL'),
    2: i18n.t('pending-orders.PENDING_ORDER_HALF_OPTION_SECOND_LABEL'),
};

export const DELIVERY_ZONE_TYPE = {
    circle: 1,
    polygon: 2,
    reference: 3,
    deliveryZoneReference: 4,
};

export function getDeliveryZoneTypes(withDeliveryZoneReference = false) {
    const types = [
        { key: DELIVERY_ZONE_TYPE.circle, value: i18n.t('settings.orderingmenu.DELIVERY_ZONE_CIRCLE_TYPE_LABEL') },
        { key: DELIVERY_ZONE_TYPE.polygon, value: i18n.t('settings.orderingmenu.DELIVERY_ZONE_POLYGON_TYPE_LABEL') },
        {
            key: DELIVERY_ZONE_TYPE.reference,
            value: i18n.t('settings.orderingmenu.DELIVERY_ZONE_REFERENCE_TYPE_LABEL'),
        },
    ];

    if (withDeliveryZoneReference) {
        types.push({
            key: DELIVERY_ZONE_TYPE.deliveryZoneReference,
            value: i18n.t('settings.orderingmenu.DELIVERY_ZONE_GLOBAL_REFERENCE_TYPE_LABEL'),
        });
    }

    return types;
}

export const WEEK_DAYS = {
    0: i18n.t('basic.days.SUNDAY'),
    1: i18n.t('basic.days.MONDAY'),
    2: i18n.t('basic.days.TUESDAY'),
    3: i18n.t('basic.days.WEDNESDAY'),
    4: i18n.t('basic.days.THURSDAY'),
    5: i18n.t('basic.days.FRIDAY'),
    6: i18n.t('basic.days.SATURDAY'),
};

export function getWeekDays() {
    return [
        { key: 0, value: i18n.t('basic.days.SUNDAY') },
        { key: 1, value: i18n.t('basic.days.MONDAY') },
        { key: 2, value: i18n.t('basic.days.TUESDAY') },
        { key: 3, value: i18n.t('basic.days.WEDNESDAY') },
        { key: 4, value: i18n.t('basic.days.THURSDAY') },
        { key: 5, value: i18n.t('basic.days.FRIDAY') },
        { key: 6, value: i18n.t('basic.days.SATURDAY') },
    ];
}

export const RATE_TYPE = {
    percent: 1,
    price: 2,
};

export const DISCOUNT_TYPE = {
    promotion: 1,
    coupon: 2,
};

export const DISCOUNT_ORDER_TYPE = {
    any: 0,
    delivery: 1,
    takeAway: 2,
};

export const SPICY_TYPE = {
    no: 0,
    spicy: 1,
    extraSpicy: 2,
};

export function rateTypes() {
    return [
        { key: RATE_TYPE.percent, value: i18n.t('settings.orderingmenu.MENU_RATE_TYPE_PERCENTAGE') },
        { key: RATE_TYPE.price, value: i18n.t('settings.orderingmenu.MENU_RATE_TYPE_PRICE') },
    ];
}

export function discountTypes() {
    return [
        { key: DISCOUNT_TYPE.promotion, value: i18n.t('settings.orderingmenu.MENU_DISCOUNT_TYPE_PROMOTION') },
        { key: DISCOUNT_TYPE.coupon, value: i18n.t('settings.orderingmenu.MENU_DISCOUNT_TYPE_COUPON') },
    ];
}

export function discountOrderTypes() {
    return [
        { key: DISCOUNT_ORDER_TYPE.any, value: i18n.t('settings.orderingmenu.MENU_DISCOUNT_ORDER_TYPE_ANY') },
        { key: DISCOUNT_ORDER_TYPE.delivery, value: i18n.t('settings.orderingmenu.MENU_DISCOUNT_ORDER_TYPE_DELIVERY') },
        { key: DISCOUNT_ORDER_TYPE.takeAway, value: i18n.t('settings.orderingmenu.MENU_DISCOUNT_ORDER_TYPE_TAKEAWAY') },
    ];
}

export function spicyTypes() {
    return [
        { key: SPICY_TYPE.no, value: i18n.t('settings.orderingmenu.MENU_BUSINESS_PRODUCT_NO_SPICY') },
        { key: SPICY_TYPE.spicy, value: i18n.t('settings.orderingmenu.MENU_BUSINESS_PRODUCT_SPICY') },
        { key: SPICY_TYPE.extraSpicy, value: i18n.t('settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_SPICY') },
    ];
}


export const CROP_MODE = {
    fill: 0,
    pad: 1,
    fit: 2,
    mfit: 3,
    scale: 4,
    limit: 5,
    lpad: 6,
    mpad: 7,
    crop: 8,
    fill_pad: 9,
    thumb: 10,
};

export const GRAVITY_MODE = {
    center: 0,
    north_east: 1,
    north: 2,
    north_west: 3,
    west: 4,
    south_west: 5,
    south: 6,
    south_east: 7,
    east: 8,
    liquid: 9,
    auto: 10,
};

export function getCropModes() {
    return Object.keys(CROP_MODE).map(key => ({
        key: CROP_MODE[key],
        value: key,
    }));
}

export function getGravityModes() {
    return Object.keys(GRAVITY_MODE).map(key => ({
        key: GRAVITY_MODE[key],
        value: key,
    }));
}

export const QUALITY_MODE = {
    default: 0,
    best: 1,
    good: 2,
    eco: 3,
    low: 4,
    custom: 5,
};

export function getQualityModes() {
    return Object.keys(QUALITY_MODE).map(key => ({
        key: QUALITY_MODE[key],
        value: key,
    }));
}

export const RESHIPMENT_TYPE = {
    marketplaceResponsibility: 0,
    restaurantResponsibility: 1,
};

export function getReshipmentTypes(depot) {
    const reshipments = [{
        key: RESHIPMENT_TYPE.marketplaceResponsibility,
        value: i18n.t('admin-orders.RESHIPMENT_TYPE_MARKETPLACE_RESPONSIBILITY'),
    }];
    if(depot) {
        return [{
            key: RESHIPMENT_TYPE.restaurantResponsibility,
            value: i18n.t('admin-orders.RESHIPMENT_TYPE_RESTAURANT_RESPONSIBILITY'),
        }];
    }

    return reshipments.concat({
        key: RESHIPMENT_TYPE.restaurantResponsibility,
        value: i18n.t('admin-orders.RESHIPMENT_TYPE_RESTAURANT_RESPONSIBILITY'),
    });
}

export const RESHIPMENT_TYPE_VALUE = {
    0: 'admin-orders.RESHIPMENT_TYPE_MARKETPLACE_RESPONSIBILITY',
    1: 'admin-orders.RESHIPMENT_TYPE_RESTAURANT_RESPONSIBILITY',
};

export const REFOUND_CHANNEL_TYPE = {
    creditCard: 0,
    loyalty: 1,
};

export function getRefoundChannelType() {
    return [
        {
            key: REFOUND_CHANNEL_TYPE.creditCard,
            value: i18n.t('admin-orders.RESTAURANT_TYPE_REFOUND_CHANNEL_CREDIT_CARD'),
        },
        { key: REFOUND_CHANNEL_TYPE.loyalty, value: i18n.t('admin-orders.RESTAURANT_TYPE_REFOUND_CHANNEL_LOYALTY') },
    ];
}

export function getRefoundBonusChannelType() {
    return [
        { key: REFOUND_CHANNEL_TYPE.loyalty, value: i18n.t('admin-orders.RESTAURANT_TYPE_REFOUND_CHANNEL_LOYALTY') },
    ];
}

export const PAY_METHOD = {
    creditCard: 1,
    cash: 2,
    bonus: 3,
    discount: 4,
    // doesn't exist in global api, only for dev
    voucher: 100, // instead of bonus
    terminal: 101, //
};

export const getPaymethodNames = {
    [PAY_METHOD.creditCard]: i18n.t('edit-customer-order.CREDIT_CARD'),
    [PAY_METHOD.cash]: i18n.t('edit-customer-order.CASH'),
    [PAY_METHOD.bonus]: i18n.t('edit-customer-order.BONUS'),
    [PAY_METHOD.discount]: i18n.t('edit-customer-order.DISCOUNT'),
    [PAY_METHOD.terminal]: i18n.t('edit-customer-order.EXTERNAL_PROVIDER'),
}

export const ORDER_INTEGRATION_TYPE_FILTER = {
    application: 0,
    external: 1,
    allOrders: 2,
    allMarketplaceOrders: 3,
};

export function getOrderIntegrationTypeFilterItems() {
    return [
        {
            key: ORDER_INTEGRATION_TYPE_FILTER.application,
            value: i18n.t('dashboard.orders.ORDER_INTEGRATION_TYPE_FILTER_APP_LABEL'),
        },
        {
            key: ORDER_INTEGRATION_TYPE_FILTER.external,
            value: i18n.t('dashboard.orders.ORDER_INTEGRATION_TYPE_FILTER_EXTERNAL_LABEL'),
        },
        {
            key: ORDER_INTEGRATION_TYPE_FILTER.allOrders,
            value: i18n.t('dashboard.orders.ORDER_INTEGRATION_TYPE_FILTER_ALL_ORDERS_LABEL'),
        },{
            key: ORDER_INTEGRATION_TYPE_FILTER.allMarketplaceOrders,
            value: i18n.t('dashboard.orders.ORDER_INTEGRATION_TYPE_FILTER_APP_FROM_ALL_SOURCES_LABEL'),
        },
    ];
}

export const ADMIN_ORDER_FILTER_TYPE = {
    search: 'search',
    business: 'business',
    dateStart: 'dateStart',
    dateEnd: 'dateEnd',
    status: 'status',
    deliveryStrategy: 'deliveryStrategy',
    territory: 'territory',
};

export const LAYOUT_TYPE = {
    adminOrderInfo: 'adminOrderInfo',
};

export const PENDING_ORDERS_FILTER = {
    byDate: 1,
    byId: 2,
};

export const PRICE_CALCULATION_MODE = {
    fixedRegionBased: 0,
    distanceBased: 2,
    mixed: 4,
};

export function getPendingOrdersFilter() {
    return [
        { key: PENDING_ORDERS_FILTER.byDate, value: i18n.t('pending-orders.FILTER_TYPE_BYDATE') },
        { key: PENDING_ORDERS_FILTER.byId, value: i18n.t('pending-orders.FILTER_TYPE_BYID') },
    ];
}

export const PERIOD_TYPE = {
  today: "today",
  yesterday: "yesterday",
  week: "week",
  month: "month",
  prevMonth: "prevMonth",
  year: "year",
  selectDate: "selectDate"
}

export const getPeriodTypeTranslate = type => {
  switch (type) {
    case PERIOD_TYPE.today:
      return i18n.t("datetimepicker.TODAY");
    case PERIOD_TYPE.yesterday:
      return i18n.t("datetimepicker.YESTERDAY");
    case PERIOD_TYPE.week:
      return i18n.t("datetimepicker.WEEK");
    case PERIOD_TYPE.month:
      return i18n.t("datetimepicker.MONTH");
    case PERIOD_TYPE.prevMonth:
      return i18n.t("datetimepicker.PREVIOUS_MONTH");
    case PERIOD_TYPE.year:
      return i18n.t("datetimepicker.YEAR");
    case PERIOD_TYPE.selectDate:
      return i18n.t("datetimepicker.SELECT_DATE");
    default:
      return "";
  }
}

export function getBasePeriods(isShowSelect = true) {
  const items = [
    { key: PERIOD_TYPE.today, value: getPeriodTypeTranslate(PERIOD_TYPE.today) },
    { key: PERIOD_TYPE.yesterday, value: getPeriodTypeTranslate(PERIOD_TYPE.yesterday) },
    { key: PERIOD_TYPE.week, value: getPeriodTypeTranslate(PERIOD_TYPE.week) },
    { key: PERIOD_TYPE.month, value: getPeriodTypeTranslate(PERIOD_TYPE.month) },
    { key: PERIOD_TYPE.prevMonth, value: getPeriodTypeTranslate(PERIOD_TYPE.prevMonth) },
    { key: PERIOD_TYPE.year, value: getPeriodTypeTranslate(PERIOD_TYPE.year) },
  ];

  return isShowSelect
    ? items.concat({ key: PERIOD_TYPE.selectDate, value: getPeriodTypeTranslate(PERIOD_TYPE.selectDate) })
    : items;
}

export function getPendingOrdersHistoryPeriods() {
  const excludedItems = [PERIOD_TYPE.year];
  return getBasePeriods()
    .filter(period=> !excludedItems.includes(period.key));
}

export function getPriceCalculationModeItems() {
    return [
        {
            key: PRICE_CALCULATION_MODE.fixedRegionBased,
            value: i18n.t('enums.priceCalculationModeItems.FIXED_REGION_BASED'),
        },
        { key: PRICE_CALCULATION_MODE.distanceBased, value: i18n.t('enums.priceCalculationModeItems.DISTANCE_BASED') },
    ];
}

export function getMerchantDeliveryCostCalculationModeItems() {
    return [
        {
            key: PRICE_CALCULATION_MODE.fixedRegionBased,
            value: i18n.t('enums.priceCalculationModeItems.FIXED_REGION_BASED'),
        },
        { key: PRICE_CALCULATION_MODE.distanceBased, value: i18n.t('enums.priceCalculationModeItems.DISTANCE_BASED') },
        { key: PRICE_CALCULATION_MODE.mixed, value: i18n.t('enums.priceCalculationModeItems.MIXED_MODE') },
    ];
}

export function newOrderNotificationMode() {
    return [
        {
            key: 0,
            value: i18n.t('settings.settings.numericalSettings.NEW_ORDER_NOTIFICATION_MODE_RADIUS'),
        },
        {
            key: 1,
            value: i18n.t('settings.settings.numericalSettings.NEW_ORDER_NOTIFICATION_MODE_FAIRNESS'),
        },
        {
            key: 2,
            value: i18n.t('settings.settings.numericalSettings.NEW_ORDER_NOTIFICATION_MODE_DISTANCE'),
        },
    ];
}

export function salaryCalculationMode() {
    return [
        {
            key: 0,
            value: i18n.t('settings.users.SALARY_HOURLY_PAYMENT'),
        },
        {
            key: 1,
            value: i18n.t('settings.users.SALARY_PER_ORDER_PAYMENT'),
        },
    ];
}

export const POSITIONING_TYPES = {
    row: 0,
    banner: 1,
    table: 2,
}

export const getPositioningTypes = () => ([
    { key: POSITIONING_TYPES.row, value: i18n.t('settings.marketplaceMessage.ROW') },
    { key: POSITIONING_TYPES.banner, value: i18n.t('settings.marketplaceMessage.BANNER')  },
    { key: POSITIONING_TYPES.table, value: i18n.t('settings.marketplaceMessage.TABLE') },
]);

export const FORM_FACTOR = {
    f5x3: 0,
    f5x4: 1,
    f1x1: 3,
}

export const getFormatTypes = () => ([
    { key: FORM_FACTOR.f5x3, value: i18n.t('settings.marketplaceMessage.5X3') },
    { key: FORM_FACTOR.f5x4, value: i18n.t('settings.marketplaceMessage.5X4') },
    { key: FORM_FACTOR.f1x1, value: i18n.t('settings.marketplaceMessage.1X1') },
]);

export const MARKETPLACE_SEARCH_TYPES = {
    none: 0,
    new: 1,
    offers: 2,
    favorites: 3,
    history: 4,
    mostPopular: 5,
    fastestDelivery: 6,
    distance: 7,
    deliveryPrice: 8,
    freeDelivery: 9,
    instagram: 99,
    categories: 100,
    messages: 101,
    voucher: 102,
};

export function getMarketplaceSearchTypesFilter() {
    return [
        { key: MARKETPLACE_SEARCH_TYPES.none, value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_NONE') },
        { key: MARKETPLACE_SEARCH_TYPES.offers, value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_OFFERS') },
        { key: MARKETPLACE_SEARCH_TYPES.favorites, value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_FAVORITES') },
        { key: MARKETPLACE_SEARCH_TYPES.history, value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_HISTORY') },
        {
            key: MARKETPLACE_SEARCH_TYPES.mostPopular,
            value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_MOST_POPULAR'),
        },
        {
            key: MARKETPLACE_SEARCH_TYPES.fastestDelivery,
            value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_FASTEST_DELIVERY'),
        },
        { key: MARKETPLACE_SEARCH_TYPES.distance, value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_DISTANCE') },
        {
            key: MARKETPLACE_SEARCH_TYPES.deliveryPrice,
            value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_DELIVERY_PRICE'),
        },
        {
            key: MARKETPLACE_SEARCH_TYPES.freeDelivery,
            value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_FREE_DELIVERY'),
        },
        {
            key: MARKETPLACE_SEARCH_TYPES.categories,
            value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_CATEGORIES'),
        },
        {
            key: MARKETPLACE_SEARCH_TYPES.messages,
            value: i18n.t('settings.marketplaceMessage.MESSAGES'),
        },
        {
            key: MARKETPLACE_SEARCH_TYPES.voucher,
            value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_FREE_VOUCHER'),
        },
    ]
}

export function getMarketplaceSearchTypes() {
    return [
        { key: MARKETPLACE_SEARCH_TYPES.none, value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_NONE') },
        { key: MARKETPLACE_SEARCH_TYPES.new, value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_NEW') },
        { key: MARKETPLACE_SEARCH_TYPES.offers, value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_OFFERS') },
        { key: MARKETPLACE_SEARCH_TYPES.favorites, value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_FAVORITES') },
        { key: MARKETPLACE_SEARCH_TYPES.history, value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_HISTORY') },
        {
            key: MARKETPLACE_SEARCH_TYPES.categories,
            value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_CATEGORIES'),
        },
        {
            key: MARKETPLACE_SEARCH_TYPES.mostPopular,
            value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_MOST_POPULAR'),
        },
        {
            key: MARKETPLACE_SEARCH_TYPES.fastestDelivery,
            value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_FASTEST_DELIVERY'),
        },
        { key: MARKETPLACE_SEARCH_TYPES.distance, value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_DISTANCE') },
        {
            key: MARKETPLACE_SEARCH_TYPES.deliveryPrice,
            value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_DELIVERY_PRICE'),
        },
        {
            key: MARKETPLACE_SEARCH_TYPES.freeDelivery,
            value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_FREE_DELIVERY'),
        },
        {
            key: MARKETPLACE_SEARCH_TYPES.messages,
            value: i18n.t('settings.marketplaceMessage.MESSAGES'),
        },
        {
            key: MARKETPLACE_SEARCH_TYPES.voucher,
            value: i18n.t('settings.marketplaceMessage.SEARCH_TYPE_FREE_VOUCHER'),
        },
    ];
}

export const MARKETPLACE_MESSAGE_TYPE = {
    generalWarning: 0,
    banner: 1,
    article: 2,
    bussinesPromotion: 3,
    listPromotion: 4,
    referral: 5,
    customList: 8,
    serviceShutdown: 255,
};

export function getMarketplaceMessageTypes() {
    return [
        {
            key: MARKETPLACE_MESSAGE_TYPE.generalWarning,
            value: i18n.t('settings.marketplaceMessage.MESSAGE_TYPE_GENERAL_WARNING'),
        },
        { key: MARKETPLACE_MESSAGE_TYPE.banner, value: i18n.t('settings.marketplaceMessage.MESSAGE_TYPE_BANNER') },
        { key: MARKETPLACE_MESSAGE_TYPE.article, value: i18n.t('settings.marketplaceMessage.MESSAGE_TYPE_ARTICLE') },
        {
            key: MARKETPLACE_MESSAGE_TYPE.bussinesPromotion,
            value: i18n.t('settings.marketplaceMessage.MESSAGE_TYPE_BUSINESS_PROMOTION'),
        },
        { key: MARKETPLACE_MESSAGE_TYPE.listPromotion, value: i18n.t("settings.marketplaceMessage.MESSAGE_TYPE_LIST_PROMOTION") },
        {
            key: MARKETPLACE_MESSAGE_TYPE.referral,
            value: i18n.t('settings.marketplaceMessage.MESSAGE_TYPE_LIST_REFERRAL'),
        },
        {
            key: MARKETPLACE_MESSAGE_TYPE.customList,
            value: i18n.t('settings.marketplaceMessage.MESSAGE_TYPE_LIST_CUSTOM'),
        },
        {
            key: MARKETPLACE_MESSAGE_TYPE.serviceShutdown,
            value: i18n.t('settings.marketplaceMessage.MESSAGE_TYPE_SERVICE_SHUTDOWN'),
        },
    ];
}

export const MARKETPLACE_LANGUAGE = {
    en: 'en',
    he: 'he',
    ru: 'ru',
    ar: 'ar',
    es: 'es',
    ro: 'ro',
    de: 'de',
    el: 'el',
    pl: 'pl',
};

export function getMarketplaceLanguages() {
    return [
        { key: '', value: '' },
        { key: MARKETPLACE_LANGUAGE.en, value: i18n.t('settings.marketplaceMessage.EN') },
        { key: MARKETPLACE_LANGUAGE.he, value: i18n.t('settings.marketplaceMessage.HE') },
        { key: MARKETPLACE_LANGUAGE.ru, value: i18n.t('settings.marketplaceMessage.RU') },
        { key: MARKETPLACE_LANGUAGE.ar, value: i18n.t('settings.marketplaceMessage.AR') },
        { key: MARKETPLACE_LANGUAGE.es, value: i18n.t('settings.marketplaceMessage.ES') },
        { key: MARKETPLACE_LANGUAGE.ro, value: i18n.t('settings.marketplaceMessage.RO') },
        { key: MARKETPLACE_LANGUAGE.de, value: i18n.t('settings.marketplaceMessage.DE') },
        { key: MARKETPLACE_LANGUAGE.el, value: i18n.t('settings.marketplaceMessage.EL') },
        { key: MARKETPLACE_LANGUAGE.pl, value: i18n.t('settings.marketplaceMessage.PL') },
    ];
}

export const TYPE_SWITCH_CONTROL_COLOR = {
    blue: 'blue',
    blueSubmit: 'blueSubmit',
    red: 'red',
    crown: 'crown',
    extraSize: 'extraSize',
};

export const getCurrencyIcons = currency => {
    const icons = {
        RUB: '₽',
        USD: '$',
        EUR: '€',
        GBP: '£',
        UAH: '₴',
        AED: 'د.',
        IL: '₪',
        ILS: '₪',
        JPY: '¥',
        KZT: '₸',
        PLN: "zł"
    };

    if (currency) {
        return icons[currency.toUpperCase()] ? icons[currency.toUpperCase()] : currency.toUpperCase();
    } else {
        return '';
    }
};

export function getUserError(error) {
    switch (error) {
        case 'Invalid phone number':
            return i18n.t('settings.users.PHONE_NUMBER_ERROR');
        default:
            return error || i18n.t('settings.FORM_SEND_ERROR_MSG');
    }
}

export const STREET_CONTROL_TYPE_BUTTON = {
    notes: 'notes',
    search: 'search',
};

export const TYPE_INFO_MESSAGE = {
    emptyControlcenter: i18n.t('settings.users.PHONE_NUMBER_ERROR'),
};

export const PUSHER_CONNECTION_STATE = {
    connected: 'connected',
    connecting: 'connecting',
};

export function getDepotIntegrationTypes() {
    return [
        { key: INTEGRATION_TYPE.none, value: i18n.t('enums.integrationTypes.NONE') },
        { key: INTEGRATION_TYPE.box, value: i18n.t('enums.integrationTypes.BOX') },
        { key: INTEGRATION_TYPE.email, value: i18n.t('enums.integrationTypes.EMAIL') },
        { key: INTEGRATION_TYPE.presto, value: i18n.t('enums.integrationTypes.PRESTO') },
        { key: INTEGRATION_TYPE.beeComm, value: i18n.t('enums.integrationTypes.BEE_COMM') },
        { key: INTEGRATION_TYPE.telegram, value: i18n.t('enums.integrationTypes.TELEGRAM') },
        { key: INTEGRATION_TYPE.paidIt, value: i18n.t('enums.integrationTypes.PAIDIT') },
        { key: INTEGRATION_TYPE.netlunch, value: i18n.t('enums.integrationTypes.NET_LUNCH') },
        { key: INTEGRATION_TYPE.sms, value: i18n.t('enums.integrationTypes.SMS') },
        { key: INTEGRATION_TYPE.clickeat, value: i18n.t('enums.integrationTypes.CLICK_EAT') },
        { key: INTEGRATION_TYPE.wix, value: i18n.t('enums.integrationTypes.WIX') },
        { key: INTEGRATION_TYPE.ordering, value: i18n.t('enums.integrationTypes.ORDERING') },
        { key: INTEGRATION_TYPE.foodNinjas, value: i18n.t('enums.integrationTypes.FOOD_NINJAS') },
        { key: INTEGRATION_TYPE.bamps, value: i18n.t('enums.integrationTypes.BAMPS') },
        { key: INTEGRATION_TYPE.nikosha, value: i18n.t('enums.integrationTypes.NIKOSHA') },
        { key: INTEGRATION_TYPE.caspit, value: i18n.t('enums.integrationTypes.CASPIT') },
        { key: INTEGRATION_TYPE.aviv, value: i18n.t('enums.integrationTypes.AVIV') },
        { key: INTEGRATION_TYPE.uber, value: i18n.t('enums.integrationTypes.UBER') },
        { key: INTEGRATION_TYPE.mcDonalds, value: i18n.t('enums.integrationTypes.MCDONALDS') },
        { key: INTEGRATION_TYPE.wooCommerce, value: i18n.t('enums.integrationTypes.WOO_COMMERCE') },
        { key: INTEGRATION_TYPE.tabit, value: i18n.t('enums.integrationTypes.TABIT') },
        { key: INTEGRATION_TYPE.livraro, value: i18n.t('enums.integrationTypes.LIVRARO') },
        { key: INTEGRATION_TYPE.shopify, value: i18n.t('enums.integrationTypes.SHOPIFY') },
        { key: INTEGRATION_TYPE.beeCommCloud, value: i18n.t('enums.integrationTypes.BEECOMM_CLOUD') },
        { key: INTEGRATION_TYPE.deliverect, value: i18n.t('enums.integrationTypes.DELIVERECT') },
        { key: INTEGRATION_TYPE.gloriaFood, value: i18n.t('enums.integrationTypes.GLORIA_FOOD') },
        { key: INTEGRATION_TYPE.kitchenHub, value: i18n.t('enums.integrationTypes.KITCHEN_HUB') },
        { key: INTEGRATION_TYPE.valueCard, value: i18n.t('enums.integrationTypes.VALUE_CARD') },
        { key: INTEGRATION_TYPE.squareUp, value: i18n.t('enums.integrationTypes.SQUAREUP') },
        { key: INTEGRATION_TYPE.noga, value: i18n.t('enums.integrationTypes.NOGA') },
        { key: INTEGRATION_TYPE.mishloha, value: i18n.t('enums.integrationTypes.MISHLOHA') },
        { key: INTEGRATION_TYPE.halalDeliveryUk, value: i18n.t('enums.integrationTypes.HALAL_DELIVERY') },
        { key: INTEGRATION_TYPE.wixMarketPlace, value: i18n.t('enums.integrationTypes.WIX_MARKET_PLACE') },
        { key: INTEGRATION_TYPE.forgastro, value: i18n.t('enums.integrationTypes.FORGASTRO') },
        { key: INTEGRATION_TYPE.merkus, value: i18n.t('enums.integrationTypes.MERKUS') },
        { key: INTEGRATION_TYPE.portel, value: i18n.t('enums.integrationTypes.PORTEL') },
        { key: INTEGRATION_TYPE.restimo, value: i18n.t('enums.integrationTypes.RESTIMO') },
        { key: INTEGRATION_TYPE.cibeez, value: i18n.t('enums.integrationTypes.CIBEEZ') },
        { key: INTEGRATION_TYPE.internalOutsourcing, value: i18n.t('enums.integrationTypes.INTERNAL_OUTSOURCING') },
        { key: INTEGRATION_TYPE.appyRo, value: i18n.t('enums.integrationTypes.APPYRO') },
        { key: INTEGRATION_TYPE.wolt, value: i18n.t('enums.integrationTypes.WOLT') },
    ];
}

export function getDepotIntegrationAddTypes() {
    return [
        { key: INTEGRATION_TYPE.squareUp, value: i18n.t('enums.integrationTypes.SQUAREUP') },
        { key: INTEGRATION_TYPE.presto, value: i18n.t('enums.integrationTypes.PRESTO') },
        { key: INTEGRATION_TYPE.mcDonalds, value: i18n.t('enums.integrationTypes.MCDONALDS') },
        { key: INTEGRATION_TYPE.gloriaFood, value: i18n.t('enums.integrationTypes.GLORIA_FOOD') },
        { key: INTEGRATION_TYPE.foodNinjas, value: i18n.t('enums.integrationTypes.FOOD_NINJAS') },
        { key: INTEGRATION_TYPE.mishloha, value: i18n.t('enums.integrationTypes.MISHLOHA') },
        { key: INTEGRATION_TYPE.halalDeliveryUk, value: i18n.t('enums.integrationTypes.HALAL_DELIVERY') },
        { key: INTEGRATION_TYPE.kitchenHub, value: i18n.t('enums.integrationTypes.KITCHEN_HUB') },
        { key: INTEGRATION_TYPE.beeComm, value: i18n.t('enums.integrationTypes.BEE_COMM') },
        { key: INTEGRATION_TYPE.beeCommCloud, value: i18n.t('enums.integrationTypes.BEECOMM_CLOUD') },
        { key: INTEGRATION_TYPE.aviv, value: i18n.t('enums.integrationTypes.AVIV') },
        { key: INTEGRATION_TYPE.netlunch, value: i18n.t('enums.integrationTypes.NET_LUNCH') },
        { key: INTEGRATION_TYPE.wixMarketPlace, value: i18n.t('enums.integrationTypes.WIX_MARKET_PLACE') },
        { key: INTEGRATION_TYPE.forgastro, value: i18n.t('enums.integrationTypes.FORGASTRO') },
        { key: INTEGRATION_TYPE.merkus, value: i18n.t('enums.integrationTypes.MERKUS') },
        { key: INTEGRATION_TYPE.portel, value: i18n.t('enums.integrationTypes.PORTEL') },
        { key: INTEGRATION_TYPE.restimo, value: i18n.t('enums.integrationTypes.RESTIMO') },
        { key: INTEGRATION_TYPE.cibeez, value: i18n.t('enums.integrationTypes.CIBEEZ') },
        { key: INTEGRATION_TYPE.tabit, value: i18n.t('enums.integrationTypes.TABIT') },
        { key: INTEGRATION_TYPE.email, value: i18n.t('enums.integrationTypes.EMAIL') },
        { key: INTEGRATION_TYPE.appyRo, value: i18n.t('enums.integrationTypes.APPYRO') },
    ];
}

export const EXTERNAL_COURIER_PROVIDER = {
    delivApp: 1,
    yango: 2,
    wolt: 3,
    doorDash: 4,
    haat: 6,
    external: 10,
    gett: 7,
};

export const EXTERNAL_CYCLE_STATE = {
    started: 0,
    estimationFailed: 1,
    estimated: 2,
    approved: 3,
    driverAssigned: 4,
    driverNotFound: 5,
    deliveryStart: 6,
    closed: 7,
    canceled: 8,
};

export function getExternalCourierProviderNameByProvider(provider, isReturnArray = false) {
    const items = [
        { key: EXTERNAL_COURIER_PROVIDER.delivApp, value: i18n.t('externalCycles.EXTERNAL_COURIER_PROVIDER_DELIVAPP') },
        { key: EXTERNAL_COURIER_PROVIDER.yango, value: i18n.t('externalCycles.EXTERNAL_COURIER_PROVIDER_YANGO') },
        { key: EXTERNAL_COURIER_PROVIDER.wolt, value: i18n.t('externalCycles.EXTERNAL_COURIER_PROVIDER_WOLT') },
        {
            key: EXTERNAL_COURIER_PROVIDER.doorDash,
            value: i18n.t('externalCycles.EXTERNAL_COURIER_PROVIDER_DOOR_DASH'),
        },
        {
            key: EXTERNAL_COURIER_PROVIDER.gett,
            value: i18n.t('externalCycles.EXTERNAL_COURIER_PROVIDER_GETT'),
        },
        { key: EXTERNAL_COURIER_PROVIDER.external, value: i18n.t('externalCycles.EXTERNAL_COURIER_PROVIDER_EXTERNAL') },
    ];

    if (isReturnArray) return items;

    const item = items.find(f => f.key === provider);
    return (item && item['value']) || '';
}

export function getExternalCourierProviderName(provider) {
    if (provider.providerName || provider.name) {
        return provider.providerName || provider.name;
    }

    return getExternalCourierProviderNameByProvider(provider.provider);
}

export function getExternalCourierState(state) {
    switch (state) {
        case EXTERNAL_CYCLE_STATE.started:
            return i18n.t('externalCycles.EXTERNAL_CYCLE_STATE_STARTED');
        case EXTERNAL_CYCLE_STATE.estimationFailed:
            return i18n.t('externalCycles.EXTERNAL_CYCLE_STATE_ESTIMATION_FAILED');
        case EXTERNAL_CYCLE_STATE.estimated:
            return i18n.t('externalCycles.EXTERNAL_CYCLE_STATE_ESTIMATED');
        case EXTERNAL_CYCLE_STATE.approved:
            return i18n.t('externalCycles.EXTERNAL_CYCLE_STATE_APPROVED');
        case EXTERNAL_CYCLE_STATE.driverAssigned:
            return i18n.t('externalCycles.EXTERNAL_CYCLE_STATE_DRIVER_ASSIGNED');
        case EXTERNAL_CYCLE_STATE.driverNotFound:
            return i18n.t('externalCycles.EXTERNAL_CYCLE_STATE_DRIVER_NOT_FOUND');
        case EXTERNAL_CYCLE_STATE.deliveryStart:
            return i18n.t('externalCycles.EXTERNAL_CYCLE_STATE_DELIVERY_START');
        case EXTERNAL_CYCLE_STATE.closed:
            return i18n.t('externalCycles.EXTERNAL_CYCLE_STATE_CLOSED');
        case EXTERNAL_CYCLE_STATE.canceled:
            return i18n.t('externalCycles.EXTERNAL_CYCLE_STATE_CANCELED');
        default:
            return '';
    }
}

export const NEW_BUSINESS_MENU_TYPE = {
    empty: 0,
    template: 1,
    business: 2,
};

export const getNewBusinessMenuTypes = () => [
    { key: NEW_BUSINESS_MENU_TYPE.empty, value: i18n.t('settings.createMenu.CREATE_MENU') },
    { key: NEW_BUSINESS_MENU_TYPE.template, value: i18n.t('settings.createMenu.CREATE_FROM_TEMPLATE') },
    { key: NEW_BUSINESS_MENU_TYPE.business, value: i18n.t('settings.createMenu.CREATE_FROM_ANOTHER_BUSINESS') },
];

export const EXTRA_TYPES = {
    extra: 'extra',
    option: 'option',
    suboption: 'suboption',
};

export const PARENT_MENU_UNIT_TYPES = {
    category: 'category',
    product: 'product',
    extra: 'extra',
    option: 'option',
    suboption: 'suboption',
}

export const INFO_ITEM_TYPE = {
    phone: 'phone',
    address: 'address',
    notes: 'notes',
    serviceNotes: 'serviceNotes',
};

export const SUBSCRIPTION_STATE = {
    undefined: 0,
    active: 1,
    canceled: 2,
    expired: 3,
    failed: 4,
    future: 5,
    paused: 6,
};

export const INTERVAL_UNIT = {
    undefined: 0,
    days: 1,
    months: 2,
};

export const CARD_TYPE = {
    undefined: 0,
    americanExpress: 1,
    dankort: 2,
    dinersClub: 3,
    discover: 4,
    forbrugsforeningen: 5,
    jcb: 6,
    laser: 7,
    maestro: 8,
    mastercard: 9,
    testCard: 10,
    unionPay: 11,
    unknown: 12,
    visa: 13,
    tarjetaNaranja: 14,
};

export const PAYMENT_PROVIDER = {
    PayMe: 0,
    Stripe: 1,
    Tranzila: 2,
};

export const getPaymentProviders = provider => {
    const items = [
        { key: PAYMENT_PROVIDER.Stripe, value: i18n.t('payment-provider.STRIPE') },
        { key: PAYMENT_PROVIDER.PayMe, value: i18n.t('payment-provider.PAYME') },
    ];

    if (provider || provider === 0) {
        return (items.find(p => p.key === provider) || {}).value || '';
    }

    return items;
};

export const SOURCE_TYPE = {
    logistic: -1,
    app: 0,
    admin: 1,
    mishloha: 2,
    tenBis: 3,
    wolt: 4,
    cibus: 5,
    delivapp: 6,
};

export const getOrderSourceTypes = () => {
  const types = orderSourceType();
  return [
    { key: SOURCE_TYPE.app, value: types[SOURCE_TYPE.app] },
    { key: SOURCE_TYPE.admin, value: types[SOURCE_TYPE.admin] },
    { key: SOURCE_TYPE.mishloha, value: types[SOURCE_TYPE.mishloha] },
    { key: SOURCE_TYPE.tenBis, value: types[SOURCE_TYPE.tenBis] },
    { key: SOURCE_TYPE.wolt, value: types[SOURCE_TYPE.wolt] },
    { key: SOURCE_TYPE.cibus, value: types[SOURCE_TYPE.cibus] },
  ];
}

export const isMarketplaceOrder = sourceType => [SOURCE_TYPE.app, SOURCE_TYPE.admin].includes(sourceType);
export const isLogisticOrder = sourceType => sourceType === SOURCE_TYPE.logistic;
export const isValidForRepeat = (order) => (
    isMarketplaceOrder(order?.order_source) && [ACCOUNT_ORDER_STATUS.finished, ACCOUNT_ORDER_STATUS.canceled].includes(order?.status)
);

export const orderSourceType = () => ({
  [SOURCE_TYPE.logistic]: i18n.t("enums.orderSourceTypes.LOGISTIC"),
  [SOURCE_TYPE.app]: i18n.t("enums.orderSourceTypes.APP"),
  [SOURCE_TYPE.admin]: i18n.t("enums.orderSourceTypes.ADMIN"),
  [SOURCE_TYPE.mishloha]: i18n.t("enums.orderSourceTypes.MISHLOHA"),
  [SOURCE_TYPE.tenBis]: i18n.t("enums.orderSourceTypes.TEN_BIS"),
  [SOURCE_TYPE.wolt]: i18n.t("enums.orderSourceTypes.WOLT"),
  [SOURCE_TYPE.cibus]: i18n.t("enums.orderSourceTypes.CIBUS"),
  [SOURCE_TYPE.delivapp]: i18n.t("enums.orderSourceTypes.DELIVAPP"),
});

export const chatSource = {
    support: 0,
    depot: 1,
    courier: 2,
    consumer: 3,
};

export const chatSourceTypes = {
    [chatSource.support]: i18n.t('support-chat.chatSource.SUPPORT'),
    [chatSource.depot]: i18n.t('support-chat.chatSource.DEPOT'),
    [chatSource.courier]: i18n.t('support-chat.chatSource.COURIER'),
    [chatSource.consumer]: i18n.t('support-chat.chatSource.CONSUMER'),
};

export const filterStateChat = {
    pendingOperator: 0,
    active: 10,
    closed: 20,
};

export const getFilterStateChat = () => [
    { key: filterStateChat.active, value: i18n.t('support-chat.filterChat.ACTIVE') },
    { key: filterStateChat.closed, value: i18n.t('support-chat.filterChat.CLOSED') },
];

export const OFFER_DISCOUNT_TARGET = {
    products: 0,
    delivery: 1,
    product: 2,
};

export const ORDER_DISCOUNT_TYPE = {
  bonus: 1,
  common: 0,
}

export const getOfferDiscountTarget = () => [
    { key: OFFER_DISCOUNT_TARGET.products, value: i18n.t('enums.offerDiscountTarget.PRODUCTS') },
    { key: OFFER_DISCOUNT_TARGET.delivery, value: i18n.t('enums.offerDiscountTarget.DELIVERY') },
    { key: OFFER_DISCOUNT_TARGET.product, value: i18n.t('enums.offerDiscountTarget.PRODUCT') },
];

export const getOfferCalculationBase = () => [
    { key: OFFER_DISCOUNT_TARGET.products, value: i18n.t('enums.offerCalculationBase.PRODUCTS') },
    { key: OFFER_DISCOUNT_TARGET.delivery, value: i18n.t('enums.offerCalculationBase.DELIVERY') },
];

export const BASIC_PATH = {
    adminOrders: '/adminorders',
    editCustomerOrder: '/edit-customer-order'
};

export const GLOBAL_FILTER_TYPE = {
    reviews: 'reviews',
    customers: 'customers',
    sales: 'sales',
};

export const APPLICATION_TYPE = {
    courier: 0,
    business: 1,
};

export const SALES_TYPES = {
    quantity: 'quantity',
    income: 'income',
    items: 'items',
    source: 'source',
}

export const DEEP_LINK_EVENT_TYPE = {
    click: 1,
    redirect: 2,
    appInstall: 3,
    appFirstOpen: 4,
    appReOpen: 5,
};

export const DEEP_LINK_EVENT = {
    [DEEP_LINK_EVENT_TYPE.click]: i18n.t("deepLinkStats.CLICK"),
    [DEEP_LINK_EVENT_TYPE.redirect]: i18n.t("deepLinkStats.REDIRECT"),
    [DEEP_LINK_EVENT_TYPE.appInstall]: i18n.t("deepLinkStats.APP_INSTALL"),
    [DEEP_LINK_EVENT_TYPE.appFirstOpen]: i18n.t("deepLinkStats.APP_FIRST_OPEN"),
    [DEEP_LINK_EVENT_TYPE.appReOpen]: i18n.t("deepLinkStats.APP_RE_OPEN"),
}

export const EVENT_OBJECT_TYPE = {
    marketplaceMessage: 0,
    marketplacePage: 1,
    offer: 2,
    deliveryZone: 3,
    category: 4,
    product: 5,
    extra: 6,
    option: 7,
    suboption: 8,
    business: 9,
}

export const EVENT_OBJECT = {
    [EVENT_OBJECT_TYPE.marketplaceMessage]: i18n.t("eventObject.MARKETPLACE_MESSAGE"),
    [EVENT_OBJECT_TYPE.marketplacePage]: i18n.t("eventObject.MARKETPLACE_PAGE"),
    [EVENT_OBJECT_TYPE.offer]: i18n.t("eventObject.OFFER"),
    [EVENT_OBJECT_TYPE.deliveryZone]: i18n.t("eventObject.DELIVERY_ZONE"),
    [EVENT_OBJECT_TYPE.category]: i18n.t("eventObject.CATEGORY"),
    [EVENT_OBJECT_TYPE.product]: i18n.t("eventObject.PRODUCT"),
    [EVENT_OBJECT_TYPE.extra]: i18n.t("eventObject.EXTRA"),
    [EVENT_OBJECT_TYPE.option]: i18n.t("eventObject.OPTION"),
    [EVENT_OBJECT_TYPE.suboption]: i18n.t("eventObject.SUBOPTION"),
    [EVENT_OBJECT_TYPE.business]: i18n.t("eventObject.BUSINESS"),
}

export const imageFormats = [".apng", ".avif", ".gif", ".jpg", ".jpeg", ".jfif", ".pjpeg", ".pjp", ".png", ".svg", ".webp"];
export const videoFormats = [".mp4"];

export const GENDER = {
    male: 0,
    female: 1,
};

export const getGenders = () => ([
    {key: GENDER.male, value: i18n.t('gender.MALE')},
    {key: GENDER.female, value: i18n.t('gender.FEMALE')},
]);

export const getGender = (gender) => {
    return (getGenders().find(g => g.key === gender) || {}).value || '-';
}

export const PROVIDERS_TYPE_MODEL = {
    Yango: "Yango",
    Wolt: "Wolt",
    DoorDash: "DoorDash",
    Sendiman: "Sendiman",
    Haat: "Haat",
    Gett: "Gett",
}

export const BATCH_STATUS = {
    draft: 0,
    pay: 1,
    inProcess: 2,
    finished: 3
};

export const getBatchStatus = (batchStatus) => {
    switch (batchStatus) {
        case BATCH_STATUS.draft:
            return i18n.t('enums.batchOrderStatus.DRAFT');
        case BATCH_STATUS.pay:
            return i18n.t('enums.batchOrderStatus.PAY');
        case BATCH_STATUS.inProcess:
            return i18n.t('enums.batchOrderStatus.IN_PROCESS');
        case BATCH_STATUS.finished:
            return i18n.t('enums.batchOrderStatus.FINISHED');
        default:
            return '';
    }
}

export const LOYALTY_LEVEL = {
    0: i18n.t('enums.loyaltyLevel.0'),
    1: i18n.t('enums.loyaltyLevel.1'),
    2: i18n.t('enums.loyaltyLevel.2'),
}

export const getLoyaltyLevel = (loyalty) => {
    if(!loyalty) {
        return null;
    }
    return LOYALTY_LEVEL[loyalty] || null;
}

// order_channel
export const ORDER_CHANNEL = {
    unknown: 0,
    ios: 1,
    android: 2,
    web: 3
}

export const orderChannelNames = {
    [ORDER_CHANNEL.unknown]: i18n.t('enums.orderChannel.UNKNOWN'),
    [ORDER_CHANNEL.web]: i18n.t('enums.orderChannel.WEB'),
    [ORDER_CHANNEL.android]: i18n.t('enums.orderChannel.ANDROID'),
    [ORDER_CHANNEL.ios]: i18n.t('enums.orderChannel.IOS'),
}

export const getOrderChannelNames = [
    { key: ORDER_CHANNEL.unknown, value: orderChannelNames[ORDER_CHANNEL.unknown] },
    { key: ORDER_CHANNEL.android, value: orderChannelNames[ORDER_CHANNEL.android] },
    { key: ORDER_CHANNEL.web, value: orderChannelNames[ORDER_CHANNEL.web] },
    { key: ORDER_CHANNEL.ios, value: orderChannelNames[ORDER_CHANNEL.ios] }
]

export const getDepotPaymentValue = () => ([
    { key: true, value: i18n.t("settings.paymeWallet.ENABLED") },
    { key: false, value: i18n.t("settings.paymeWallet.DISABLED") },
    { key: null, value: i18n.t("settings.paymeWallet.DEFAULT") },
])