import React from 'react';

import MenuModel from './menuModel';

import {BASIC_PATH, ORDERING_MENU_TYPE_SECTION_MODEL, SHIFTS_MODEL, TYPE_SECTION_MODEL} from '../utils/enums';
import {
    currentUserIsControlCenterManagerRole,
    currentUserIsDeliveryManager,
    currentUserIsDepotRole,
    currentUserIsOnlySupportOperatorRole, currentUserIsSupervisorRole,
    currentUserIsSupportOperatorRole,
} from '../services/userModel';
import {
    countPendingOrder,
    getConsumerRestaurantId,
    getRestaurantPaymentEnabled,
    menuItemIconOrdersCount
} from '../services/restaurant';

import {CountUnreadMessages} from '../app/components/CountUnreadMessages/CountUnreadMessages';
import MenuItemIconDepots from '../app/components/MenuItemIconDepots';

export function createHeaderMenu() {
    return currentUserIsDepotRole()
        ? [
              new MenuModel({
                  pathname: '/',
                  title: 'header.menu.DASHBOARD_LABEL',
                  active: location => location.pathname === '/',
                  badgeCount: () => menuItemIconOrdersCount() || null,
                  priority: 0,
              }),
              new MenuModel({
                  pathname: '/orders',
                  title: 'header.menu.ORDERS_LABEL',
                  active: location => location.pathname === '/orders',
                  priority: 1,
              }),
              new MenuModel({
                  pathname: ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu + '?id=' + getConsumerRestaurantId(),
                  title: 'header.menu.RESTAURATEUR_MENU_LABEL',
                  active: location => location.pathname.split('/')[1] === 'settings' && location.pathname.split('/')[2] === 'orderingmenu',
                  isShow: ({ consumerOrderMenu }) => !!consumerOrderMenu,
                  priority: 2,
              }),
              new MenuModel({
                  pathname: currentUserIsDeliveryManager() ? TYPE_SECTION_MODEL.users : TYPE_SECTION_MODEL.personalSettings,
                  title: 'header.menu.SETTINGS_LABEL',
                  active: location => location.pathname.split('/')[1] === 'settings' && location.pathname.split('/')[2] !== 'orderingmenu',
                  priority: 3,
              }),
                new MenuModel({
                    pathname: BASIC_PATH.adminOrders,
                    title: 'header.menu.ADMIN_ORDERS_LABEL',
                    active: location => location.pathname === BASIC_PATH.adminOrders,
                    isShow: () => currentUserIsSupportOperatorRole(),
                    priority: 4,
                    isIconRelative: true,
                    badgeCount: () => countPendingOrder() || null,
                }),
          ]
        : [
              new MenuModel({
                  pathname: '/',
                  title: 'header.menu.DASHBOARD_LABEL',
                  active: location => location.pathname === '/',
                  priority: 4,
              }),
              new MenuModel({
                  pathname: '/orders',
                  title: 'header.menu.ORDERS_LABEL',
                  active: location => location.pathname === '/orders',
                  priority: 0,
              }),
              new MenuModel({
                  pathname: '/marketplace-settings',
                  title: 'header.menu.MARKETPLACE_SETTINGS',
                  active: location => location.pathname.includes('/marketplace-settings'),
                  isShow: () => getRestaurantPaymentEnabled() && window.innerWidth >= 700 && (currentUserIsSupervisorRole() || currentUserIsControlCenterManagerRole()),
              }),
              new MenuModel({
                  pathname: '/marketplace-settings/list',
                  title: 'header.menu.MARKETPLACE_SETTINGS',
                  active: location => location.pathname.includes('/marketplace-settings/list'),
                  priority: 2,
                  isShow: () => getRestaurantPaymentEnabled() && window.innerWidth < 700 && (currentUserIsSupervisorRole() || currentUserIsControlCenterManagerRole()),
              }),
              new MenuModel({
                  pathname: '/settings',
                  title: 'header.menu.SETTINGS_LABEL',
                  active: location =>
                      location.pathname.split('/')[1] === 'settings',
                  priority: 2,
                  isWithIcon: () => <MenuItemIconDepots check={['squareUpAuthorizationRequired']} />,
              }),
              new MenuModel({
                  pathname: SHIFTS_MODEL.currentShiftUsers,
                  title: 'settings.SHIFTS_TITLE',
                  active: location =>
                      location.pathname.split('/')[1] === 'shifts',
                  priority: 3,
                  isShow: () => !currentUserIsOnlySupportOperatorRole(),
              }),
              new MenuModel({
                  pathname: '/reports',
                  title: 'header.menu.REPORTS_LABEL',
                  active: location => location.pathname === '/reports',
                  priority: 1,
              }),
              new MenuModel({
                  pathname: BASIC_PATH.adminOrders,
                  title: 'header.menu.ADMIN_ORDERS_LABEL',
                  active: location => location.pathname === BASIC_PATH.adminOrders,
                  isShow: () => currentUserIsSupportOperatorRole(),
                  priority: 5,
                  isIconRelative: true,
                  badgeCount: () => countPendingOrder() || null,
                  isWithIcon: () => <>
                      <CountUnreadMessages />
                  </>,
              }),
          ];
}
