import environment from "environment";
import { callServer } from "../utils/request-util";

export function getCustomers(data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/consumers",
            method: "POST",
            data
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function createCustomer(data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/consumers",
            method: "PUT",
            data
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function customerWalletOperation(consumerId, data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/bonuses/" + consumerId,
            method: "POST",
            data
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function customerAddBonusCard(consumerId, data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/bonuseCard/" + consumerId,
            method: "POST",
            data: {
                bonus: data.bonus,
                name: data.name,
                checkout_limit_percent: data.checkoutLimitPercent,
                expiration: data.expiration,
                multi_use: data.multiUse,
            }
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function updateLoyaltyLevel(consumerId, loyaltyLeve) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/consumer/loyaltyLevel/",
            method: "PUT",
            data: {
                UserId: consumerId,
                LoyaltyLevel: loyaltyLeve
            }
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function blockConsumerUser(consumerId, notes) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/blockConsumer/",
            method: "POST",
            data: {
                UserId: consumerId,
                Notes: notes
            }
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function unblockConsumerUser(consumerId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/blockConsumer/" + consumerId,
            method: "DELETE",
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getblockConsumerUserStatus(consumerId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/blockConsumer/" + consumerId,
            method: "GET",
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function customerWalletInfo(consumerId) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "callCenter/consumerWallet/" + consumerId,
            method: "GET"
        }).then(response => resolve(response), error => reject(error.response));
    });
}

export function getCustomersReport(data) {
    return new Promise((resolve, reject) => {
        callServer({
            url: environment.backendAPI + "report/consumers",
            method: "POST",
            responseType: "blob",
            data,
            roles: [environment.USER_ROLES.all]
        }).then(response=> resolve(response), error => reject(error.response));
    });
}