import React from 'react';
import i18n from '../../i18n';

import FilterOptionsModel from '../../models/filterOptionsModel';
import SectionModel from '../../models/sectionModel';
import { createControlModel } from '../controlModelFactory';
import { getBusinesDiscountFilter } from '../filter';
import { getInitModel } from '../initModels';
import {
    getDiscountCalculationBaseType,
    getDiscountOrderTypeLabel,
    getDiscountTargetType,
    getDiscountTypeLabel,
    isMobileWidth,
} from '../restaurant';

import { SETTINGS_OFFERS, SETTINGS_OFFERS_GET } from '../../store/reducers/restaurant/restaurant-actions';

import {
    CONTROL_TYPE,
    DATECONTROL_TYPE,
    DISCOUNT_TYPE,
    discountOrderTypes,
    discountTypes, FILTER_TYPE, getCampaignRecurringTypes,
    getOfferCalculationBase, getOfferDiscountTarget, LOYALTY_LEVEL,
    MARKETPLACE_SETTINGS_MODEL,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE, OFFER_DISCOUNT_TARGET,
    RATE_TYPE,
    rateTypes,
    TYPE_ACTION,
} from '../../utils/enums';
import { defaultPromiseResolve } from '../../utils/objects-util';

import IconButton from '../../app/components/IconButton';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import MarketplaceDiscountProductsItem from "../../app/components/MarketplaceDiscountProductsItem";

export function createSettingsOffersSection({ ...args }) {
    return new SectionModel({
        filterOptions: createBSettingsOffersSectionFilter(),
        columns: [
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_NAME_LABEL',
                accessor: 'name',
                width: '10%',
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_TARGET_LABEL',
                accessor: 'discount_target',
                component: item => getDiscountTargetType(item.discount_target) || null,
                // width: '180px',
                centered: true,
            },

            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_ORDER_TYPE_LABEL',
                accessor: 'order_type',
                component: item => getDiscountOrderTypeLabel(item.order_type),
                width: 'auto',
                centered: true,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_TYPE_LABEL',
                accessor: 'type',
                component: item => getDiscountTypeLabel(item.type) || '-',
                width: 'auto',
                centered: true,
            },
            {
                title: 'settings.orderingmenu.MENU_RATE_TYPE_LABEL',
                accessor: 'rate_type',
                component: item => {

                    if(!item.rate_type){
                        return '-'
                    }

                    return(
                      <IconButton
                        isIcon={true}
                        iconName={item.rate_type === RATE_TYPE.percent ? 'percent' : 'cash'}
                        tooltipText={
                            item.rate_type === RATE_TYPE.percent
                              ? i18n.t('settings.orderingmenu.MENU_RATE_TYPE_PERCENTAGE')
                              : i18n.t('settings.orderingmenu.MENU_RATE_TYPE_PRICE')
                        }
                        onClick={defaultPromiseResolve}
                      />
                    )
                },
                width: 'auto',
                centered: true,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_RATE_LABEL',
                accessor: 'rate',
                width: 'auto',
                centered: true,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNTS_CALCULATION_BASED_ON',
                accessor: 'calculation_base',
                component: item => getDiscountCalculationBaseType(item.calculation_base) || null,
                // width: '180px',
                centered: true,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_MINIMUM_LABEL',
                accessor: 'minimum',
                width: 'auto',
                centered: true,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_LIMIT_LABEL',
                accessor: 'limit',
                width: '160px',
                centered: true,
            },

            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                centered: true,
                wrapOnMobileWidth: '600',
                type: CONTROL_TYPE.link,
                link: MARKETPLACE_SETTINGS_MODEL.offersEdit,
                iconName: 'editItem',
                tooltipText: i18n.t('settings.orderingmenu.MENU_DISCOUNTS_EDIT_TOOLTIP_LABEL'),
            },
        ],
        actionLoad: SETTINGS_OFFERS_GET,
        actionName: SETTINGS_OFFERS,
        tableMinWidth: 1200,
        typeModel: MARKETPLACE_SETTINGS_MODEL.offers,
        typeAction: args.typeAction,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: MARKETPLACE_SETTINGS_MODEL.offersNew,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
        ],
        label: 'settings.orderingmenu.MENU_DISCOUNTS',
        mappingLambda: state => state.restaurant.data.offers || [],
    });
}

export function createSettingsOffersEditSection({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_TARGET_LABEL',
                accessor: 'discount_target',
                connect: () => ({
                    values: getOfferDiscountTarget(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_ORDER_TYPE_LABEL',
                accessor: 'order_type',
                connect: () => ({
                    values: discountOrderTypes(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_TYPE_LABEL',
                accessor: 'type',
                connect: () => ({
                    values: discountTypes(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_RATE_TYPE_LABEL',
                accessor: 'rate_type',
                connect: () => ({
                    values: rateTypes(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_RATE_LABEL',
                accessor: 'rate',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNTS_CALCULATION_BASED_ON',
                accessor: 'calculation_base',
                connect: () => ({
                    values: getOfferCalculationBase(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_MINIMUM_LABEL',
                accessor: 'minimum',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_LIMIT_LABEL',
                accessor: 'limit',
                isEditable: true,
                inlineType: 'inlineOdd',
                tooltipText: i18n.t('settings.orderingmenu.MENU_DISCOUNT_LIMIT_TOOLTIP'),
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_START_LABEL',
                accessor: 'start',
                isEditable: true,
                timeType: DATECONTROL_TYPE.datetime,
                type: CONTROL_TYPE.date,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_END_LABEL',
                accessor: 'end',
                min: item => item.start,
                isEditable: true,
                inlineType: 'inlineOdd',
                timeType: DATECONTROL_TYPE.datetime,
                type: CONTROL_TYPE.date,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_COUPON_LABEL',
                accessor: 'coupon',
                isEditable: true,
                isShow: item => item && item.type === DISCOUNT_TYPE.coupon,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_DISCOUNT_SHOW_IN_MARKETPLACE_APP',
                accessor: 'show_in_marketplace',
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true,
            },

            {
                title: 'settings.orderingmenu.MENU_BUSINESS_SHEDULE_SINGLE_USER_LABEL',
                accessor: 'single_user',
                isEditable: true,
                // isShow: item => item && item.type === DISCOUNT_TYPE.coupon,
                type: CONTROL_TYPE.switch,
                isInline: true,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_SHEDULE_LABEL',
                accessor: 'schedule',
                isEditable: true,
                isFillContent: true,
                type: CONTROL_TYPE.schedule,
            },
            {
                accessor: 'business_id',
                title: 'settings.orderingmenu.MENU_BUSINESS_BUSINESS_ID_LABEL',
                isEditable: true,
                type: CONTROL_TYPE.select,
                isShow: item => item.discount_target === OFFER_DISCOUNT_TARGET.product,
                connect: (state) => {
                    const templates = state.menuTemplate.data.templates || [];
                    return {
                        values: templates.map(template => ({ key: template.business_id, value: template.name })),
                    }
                },
            },
            {
                accessor: 'product_id',
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_ID_LABEL',
                isEditable: true,
                isShow: item => item.discount_target === OFFER_DISCOUNT_TARGET.product,
                component: (item, onLayout, onPropChange, column) =>
                    <MarketplaceDiscountProductsItem
                        item={item}
                        onLayout={onLayout}
                        onPropChange={onPropChange}
                        column={column}
                    />
            },
            {
                title: 'settings.orderingmenu.RECURRING_TYPE',
                accessor: 'recurring_type',
                connect: () => ({
                    values: getCampaignRecurringTypes(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.USAGE_LIMIT',
                accessor: 'usage_limit',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.LOYALTY_LEVEL',
                accessor: 'loyalty_level',
                connect: () => {
                    const levels = [];
                    Object.entries(LOYALTY_LEVEL).forEach(([key, value]) => levels.push({key: parseInt(key), value}));
                    return {
                        values: [{key: null, value: i18n.t("enums.loyaltyLevel.ALL")}].concat(levels),
                    }
                },
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.DISCOUNT_BUSINESS_SHEDULE_ENABLED_LABEL',
                accessor: 'enable_schedule',
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true,
            },
        ],
        actionLoad: SETTINGS_OFFERS_GET,
        actionName: SETTINGS_OFFERS,
        initNewItemModel: item => getInitModel(MODEL_TYPE.offerModel, item),
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? MARKETPLACE_SETTINGS_MODEL.offersEdit
                : MARKETPLACE_SETTINGS_MODEL.offersNew,
        parentUrl: MARKETPLACE_SETTINGS_MODEL.offers,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: 'settings.orderingmenu.MENU_DISCOUNTS',
            }),
        ],
        mappingLambda: state => state.restaurant?.data?.offers || [],
    });
}

function createBSettingsOffersSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(businesDiscount) {
        return getBusinesDiscountFilter(this.selected, businesDiscount);
    });

    const selectOption = filterOptionsModel.addOptions(
        "discount_target_discount",
        CONTROL_TYPE.select,
        i18n.t('settings.orderingmenu.MENU_DISCOUNT_TARGET_LABEL'),
        function(item) {
            if(this.selected === null) {
                return true;
            }
            return item.discount_target === this.selected;
        }
    );
    selectOption.filterType = FILTER_TYPE.and;
    selectOption.addItem("-", null);
    getOfferDiscountTarget().forEach(item => selectOption.addItem(item.value, item.key));
    selectOption.selected = null;

    const selectOptionLoyaltyLevel = filterOptionsModel.addOptions(
        "loyalty_level_discount",
        CONTROL_TYPE.select,
        i18n.t('settings.orderingmenu.LOYALTY_LEVEL'),
        function(item) {
            if(this.selected === null) {
                return true;
            }
            if(this.selected === -1) {
                return item.loyalty_level === null;
            }
            return item.loyalty_level === this.selected;
        }
    );
    const loyaltyLevelOptions = [{key: null, value: '-'}, {key: -1, value: i18n.t("enums.loyaltyLevel.ALL")}];
    Object.entries(LOYALTY_LEVEL).forEach(([key, value]) => loyaltyLevelOptions.push({key: parseInt(key), value}));
    loyaltyLevelOptions.forEach((item) => selectOptionLoyaltyLevel.addItem(item.value, item.key));
    selectOptionLoyaltyLevel.selected = null;
    selectOptionLoyaltyLevel.filterType = FILTER_TYPE.and;

    const selectOptionDiscountType = filterOptionsModel.addOptions(
        "discount_type_discount",
        CONTROL_TYPE.select,
        i18n.t('settings.orderingmenu.MENU_DISCOUNT_TYPE_LABEL'),
        function(item) {
            if(this.selected === null) {
                return true;
            }
            return item.type === this.selected;
        }
    );
    selectOptionDiscountType.addItem('-', null);
    discountTypes().forEach((item) => selectOptionDiscountType.addItem(item.value, item.key));
    selectOptionDiscountType.selected = null;
    selectOptionDiscountType.filterType = FILTER_TYPE.and;

    return filterOptionsModel;
}
