import { CONTROL_TYPE, FILTER_TYPE } from '../utils/enums';

export const FILTER_OPTION_TYPE = {
  controlCenter: "controlCenter"
}

export default class FilterOptionsModel {
  constructor() {
    this.options = {};
  }

  addOptions(
    name,
    type,
    label,
    filterFunc,
    disabled,
    selected,
    isManualUpdate = false,
    editFunc,
    typeControlStyle,
    isCustomItems = false,
    options,
  ) {
    let option = {};

    switch (type) {
      case CONTROL_TYPE.checkbox:
        option = this.createFilterCheckOptionModel(
          label,
          filterFunc,
          disabled,
          selected
        );
        break;
      case CONTROL_TYPE.groupCheckbox:
        option = this.createFilterGroupCheckOptionModel(label, filterFunc, selected);
        break;
      case CONTROL_TYPE.select:
        option = this.createFilterSelectOptionModel(
          label,
          filterFunc,
          disabled,
          selected
        );
        break;
      case CONTROL_TYPE.multiselect:
        option = this.createFilterMultiSelectOptionModel(
          label,
          filterFunc,
          disabled,
          selected
        );
        break;
      case CONTROL_TYPE.usersPopup:
        option = this.createFilterPopupOptionModel(
          label,
          filterFunc,
          disabled,
          selected
        );
        break;
      case CONTROL_TYPE.slider:
        option = this.createFilterSlideOptionModel(
          label,
          filterFunc,
          disabled,
          selected
        );
        break;
      case CONTROL_TYPE.text:
        option = this.createFilterTextOptionModel(
          filterFunc,
          disabled,
          selected,
          label,
        );
        break;
      case CONTROL_TYPE.date:
        option = this.createFilterDateOptionModel(
          label,
          filterFunc,
          disabled,
          selected,
          options
        );
        break;
      case CONTROL_TYPE.link:
        option = this.createFilterLinkOptionModel(
          label,
          editFunc,
          typeControlStyle
        );
        break;
      case CONTROL_TYPE.label:
        option = this.createFilterLabelOptionModel(label);
        break;
      default:
        console.log("Unknown option type!");
        return;
    }

    option.isManualUpdate = isManualUpdate;
    option.isCustomItems = isCustomItems;
    this.options[name] = option;
    this.options[name].name = name;
    return option;
  }

  createFilterCheckOptionModel(label, filterFunc, disabled, selected) {
    let model = {
      filterType: FILTER_TYPE.or,
      label: label || "",
      type: CONTROL_TYPE.checkbox,
      disabled: disabled || false,
      selected: selected || false
    };

    if (filterFunc) model.filterFunction = filterFunc.bind(model);

    return model;
  }

  createFilterGroupCheckOptionModel(label, filterFunc, selected) {
    let model = {
      filterType: FILTER_TYPE.or,
      label: label || "",
      type: CONTROL_TYPE.groupCheckbox,
      selected: selected || false
    };

    if (filterFunc) model.filterFunction = filterFunc.bind(model);

    return model;
  }

  createFilterPopupOptionModel(label, filterFunc, disabled, selected) {
    return {
      label: label || "",
      filterType: FILTER_TYPE.or,
      disabled: disabled || false,
      type: CONTROL_TYPE.usersPopup,
      selected: selected,
      isClearUser: true
    };
  }

  createFilterSelectOptionModel(label, filterFunc, disabled, selected) {
    let model = {
      label: label || "",
      filterType: FILTER_TYPE.or,
      disabled: disabled || false,
      type: CONTROL_TYPE.select,
      selected: selected,
      items: [],
      mappingLambda: null,
      addItem: (key, value) => model.items.push({ key, value }),
      isShow: null,
    };

    if (filterFunc) model.filterFunction = filterFunc.bind(model);

    return model;
  }

  createFilterMultiSelectOptionModel(label, filterFunc, disabled, selected, selectedValues) {
    let model = {
      label: label || "",
      filterType: FILTER_TYPE.or,
      disabled: disabled || false,
      type: CONTROL_TYPE.multiselect,
      selected: selected,
      selectedValues: selectedValues,
      items: [],
      mappingLambda: null,
      addItem: (key, value) => model.items.push({ key, value }),
      isShow: null,
    };

    if (filterFunc) model.filterFunction = filterFunc.bind(model);

    return model;
  }

  createFilterSlideOptionModel(label, filterFunc, disabled, selected, min, max) {
    let model = {
      label,
      filterType: FILTER_TYPE.and,
      disabled: disabled || false,
      type: CONTROL_TYPE.slider,
      selected,
      sliderOptions: {
        disabled: disabled || false,
        floor: min || 0,
        ceil: max || 0,
        rightToLeft: false,
        showSelectionBar: true
      },
      setMinMax: (min, max) => {
        model.sliderOptions.floor = min;
        model.sliderOptions.ceil = max;
      }
    };

    if (filterFunc) model.filterFunction = filterFunc.bind(model);

    return model;
  }

  createFilterTextOptionModel(filterFunc, disabled, selected) {
    let model = {
      filterType: FILTER_TYPE.and,
      disabled: disabled || false,
      type: CONTROL_TYPE.text,
      selected: selected
    };

    if (filterFunc) model.filterFunction = filterFunc.bind(model);

    return model;
  }

  createFilterDateOptionModel(label, filterFunc, disabled, selected, options) {
    let model = {
      label: label || "",
      filterType: FILTER_TYPE.and,
      disabled: disabled || false,
      type: CONTROL_TYPE.date,
      selected: selected,
      format: options?.format,
    };

    if (filterFunc) model.filterFunction = filterFunc.bind(model);

    return model;
  }

  createFilterLinkOptionModel(label, editFunc, typeControlStyle) {
    let model = {
      label: label || "",
      type: CONTROL_TYPE.link,
      typeControlStyle: typeControlStyle
    };

    if (editFunc) model.editFunc = editFunc.bind(model);

    return model;
  }

  createFilterLabelOptionModel(label) {
    let model = {
      label: label || "",
      type: CONTROL_TYPE.label
    };

    return model;
  }

  isSelectedFilters() {
    for (const key in this.options) {
      if (!this.options.hasOwnProperty(key)) continue;
      if (this.options[key].selected) return true;
    }
    return false;
  }

  getSelectedFilters(filterType) {
    let result = [];
    for (var key in this.options) {
      if (!this.options.hasOwnProperty(key)) continue;
      const option = this.options[key];
      if(option.isCustomItems) {
        option.selected.forEach(optionSelected => {
          if(optionSelected.isCheckAllGroup) {
            if (this.getSelectFilter(optionSelected, filterType, true)) {
              result.push(optionSelected);
            }
          } else {
            optionSelected.items.forEach(element => {
              if (this.getSelectFilter(option, filterType)) {
                result.push(element);
              }
            });
          }
        });
      } else {
        if (this.getSelectFilter(option, filterType)) {
          result.push(option);
        }
      }
    }

    return result;
  }

  getSelectFilter(option, filterType, isOnlyType) {
    return (isOnlyType || option.selected || option.selected === 0) &&
      (!filterType || filterType === option.filterType) &&
      (isOnlyType || typeof option.filterFunction === "function")
  }
}
