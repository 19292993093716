import {ADD, DELETE, EDIT, GET} from "../common-actions";
import {MESSAGE_GALLERY} from "../restaurant/restaurant-actions";

export const ORDERS_LOAD = "ORDERS_LOAD";
export const ORDERS_LOAD_SUCCESS = ORDERS_LOAD + "_SUCCESS";
export const ORDERS_LOAD_FAIL = ORDERS_LOAD + "_FAIL";
export const ORDERS_MODEL_UPDATE = "ORDERS_MODEL_UPDATE";
export const ADMIN_ORDER_SELECTED_ORDER_ID = "ADMIN_ORDER_SELECTED_ORDER_ID";
export const PENDING_ORDER_SAVE = "PENDING_ORDER_SAVE";
export const GET_MERGED_BUSINESS_MENU = "GET_MERGED_BUSINESS_MENU";
export const GET_MERGED_BUSINESS_MENU_BY_ID = "GET_MERGED_BUSINESS_MENU_BY_ID";
export const PENDING_ORDER_GET = "PENDING_ORDER_GET";
export const PENDING_ORDER_DATA_GET = "PENDING_ORDER_DATA_GET";
export const PENDING_ORDER_CANCEL_CYCLE = "PENDING_ORDER_CANCEL_CYCLE";
export const PENDING_ORDER_UN_ASSOCIATE_ORDER = "PENDING_ORDER_UN_ASSOCIATE_ORDER";
export const CONSUMER_PRE_ORDERS_GET = "CONSUMER_PRE_ORDERS_GET";
export const PENDING_ORDERS_UPDATE = "PENDING_ORDERS_UPDATE";
export const PENDING_ORDER_UPDATE_COURIER = "PENDING_ORDER_UPDATE_COURIER";
export const PENDING_ORDER_UPDATE = "PENDING_ORDER_UPDATE";
export const PENDING_PREORDERS_UPDATE = "PENDING_PREORDERS_UPDATE";
export const PENDING_PREORDER_DELETE = "PENDING_PREORDER_DELETE"
export const ADMIN_ORDER_GET = "ADMIN_ORDER_GET";
export const ADMIN_ORDER_GET_BY_ID = "ADMIN_ORDER_GET_BY_ID";
export const ADMIN_ORDER_GET_ORDER_INFO = "ADMIN_ORDER_GET_ORDER_INFO";
export const ADMIN_ORDER_SET_FILERS = "ADMIN_ORDER_SET_FILERS";
export const ORDER_ADMIN_INFO_GET = "ORDER_ADMIN_INFO_GET";
export const ADMIN_ORDERS_UPDATE = "ADMIN_ORDERS_UPDATE";
export const ADMIN_ORDERS_LOADING = "ADMIN_ORDERS_LOADING";
export const ADMIN_ORDER_UPDATE = "ADMIN_ORDER_UPDATE";
export const ADMIN_ORDER_PENDING_COUNT_UPDATE = "ADMIN_ORDER_PENDING_COUNT_UPDATE";
export const ADMIN_ORDER_RESHIPMENT = "ADMIN_ORDER_RESHIPMENT";
export const ADMIN_ORDER_DELIVERED = "ADMIN_ORDER_DELIVERED";
export const ADMIN_ORDER_REFUND = "ADMIN_ORDER_REFUND";
export const ADMIN_ORDER_CAPTURE = "ADMIN_ORDER_CAPTURE";
export const PUSH_MESSAGE = "PUSH_MESSAGE";
export const ADMIN_ORDER_CANCEL = "ADMIN_ORDER_CANCEL";
export const PUSHER_CYCLE_REORDER = "PUSHER_CYCLE_REORDER";
export const PUSHER_NEW_ORDER = "PUSHER_NEW_ORDER";
export const ORDER_NEW = "ORDER_NEW";
export const ADD_NEW_ORDERS = "ADD_NEW_ORDERS";
export const ORDER_UPDATE = "ORDER_UPDATE";
export const ORDER_NOTE_EDIT = "ORDER_NOTE_EDIT";
export const PUSHER_UPDATE_ORDER = "PUSHER_UPDATE_ORDER";
export const ORDER_UPDATE_STATE = ORDER_UPDATE + "_STATE";
export const ORDER_UPDATE_SERVICE_NOTES = ORDER_UPDATE + "_SERVICE_NOTES";
export const PUSHER_ORDER_STATE_UPDATE = "PUSHER_ORDER_STATE_UPDATE";
export const PUSHER_GROUP_UPDATE = "PUSHER_GROUP_UPDATE";
export const PUSHER_GROUP_REORDER = "PUSHER_GROUP_REORDER";
export const PUSHER_GROUP_LOCK_UPDATE = "PUSHER_GROUP_LOCK_UPDATE";
export const NEW_GROUP_REORDER = "NEW_GROUP_REORDER";
export const GROUP_ORDER_UPDATE = "GROUP_ORDER_UPDATE";
export const REMOVE_GROUP_ORDERS = "REMOVE_GROUP_ORDERS";
export const CYCLE_REORDER = "CYCLE_REORDER";
export const PERFORM_ORDER_ASSOCIATING = "PERFORM_ORDER_ASSOCIATING";
export const GROUP_UPDATE_STATE = "GROUP_UPDATE_STATE";
export const GROUP_UPDATE = "GROUP_UPDATE";
export const CYCLE_DISPATCHE = "CYCLE_DISPATCHE";
export const UPDATE_ORDER_DATA = "UPDATE_ORDER_DATA";
export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const ORDER_REMOVE = "ORDER_REMOVE";
export const ORDER_DELETE = "ORDER_DELETE";
export const ORDER_ASSISTANCE_DELETE = "ORDER_ASSISTANCE_DELETE";
export const CHANGE_ORDER_STATE = "CHANGE_ORDER_STATE";
export const CHANGE_ORDER_INDEX = "CHANGE_ORDER_INDEX";
export const CHANGE_ITEMS_GROUP = "CHANGE_ITEMS_GROUP";
export const PUSHER_ASSISTANCE_UPDATE = "PUSHER_ASSISTANCE_UPDATE";
export const ASSOCIATE_ORDERS = "ASSOCIATE_ORDERS";
export const UPDATE_GROUP_ORDERS_MARKERS = "UPDATE_GROUP_ORDERS_MARKERS";
export const SAVE_ORDER_TO_SERVER = "SAVE_ORDER_TO_SERVER";
export const APPROVE_ASSISTANCE_ORDER = "APPROVE_ASSISTANCE_ORDER";
export const GET_ORDER_RECEIPTS = "GET_ORDER_RECEIPTS";
export const GET_ADVANCED_INFO = "GET_ADVANCED_INFO";
export const LOAD_HISTORY_ORDERS = "LOAD_HISTORY_ORDERS";
export const GET_ORDERS_PROVIDER = "GET_ORDERS_PROVIDER";
export const SAVE_HISTORY_ORDERS = "SAVE_HISTORY_ORDERS";
export const RELOAD_ORDER = "RELOAD_ORDER";
export const SET_ORDER_DELIVERED_BY = "SET_ORDER_DELIVERED_BY";
export const PUSHER_CONSUMER_ORDER_ARRIVED = "PUSHER_CONSUMER_ORDER_ARRIVED";
export const ORDER_MENU_GET = "ORDER_MENU_GET";
export const ORDER_MENU_UPDATE = "ORDER_MENU_UPDATE";
export const ORDER_INDEX_UPDATE = "ORDER_INDEX_UPDATE";
export const CONSUMER_ORDER = "CONSUMER_ORDER";
export const CONSUMER_ORDER_SEARCH = CONSUMER_ORDER + "_SEARCH";
export const CONSUMER_ORDER_SEARCH_SAGA = CONSUMER_ORDER + "_SEARCH_SAGA";
export const CONSUMER_ORDER_ADD = CONSUMER_ORDER + ADD;
export const CONSUMER_ORDER_CANCEL = CONSUMER_ORDER + "_CANCEL";
export const GET_PRODUCTS_BY_ID_BUSINESS = "GET_PRODUCTS_BY_ID_BUSINESS";
export const MARKETPLACE_MESSAGE_GET = "MARKETPLACE_MESSAGE_GET";
export const MARKETPLACE_MESSAGE_GALLERY = "MARKETPLACE_MESSAGE_GALLERY";
export const MARKETPLACE_MESSAGE_GALLERY_GET = MARKETPLACE_MESSAGE_GALLERY + GET;
export const MARKETPLACE_MESSAGE_GALLERY_EDIT = MARKETPLACE_MESSAGE_GALLERY + EDIT;
export const MARKETPLACE_MESSAGE_GALLERY_ADD = MARKETPLACE_MESSAGE_GALLERY + ADD;
export const MARKETPLACE_MESSAGE_GALLERY_REORDER = MARKETPLACE_MESSAGE_GALLERY + "_REORDER";
export const MESSAGE_GALLERY_DELETE = MESSAGE_GALLERY + DELETE;
export const MARKETPLACE_MESSAGE_DATA_GET = "MARKETPLACE_MESSAGE_DATA_GET";
export const MARKETPLACE_PAGES_GET = "MARKETPLACE_PAGES_GET";
export const GET_ORDER_PAYMENT_INTENT = "GET_ORDER_PAYMENT_INTENT";
export const GET_ORDER_SETUP_INTENT = "GET_ORDER_SETUP_INTENT";
export const STRIPE_VERIFY_CARD_ORDER = "STRIPE_VERIFY_CARD_ORDER";
export const SET_CREATED_ORDER = "SET_CREATED_ORDER";
export const GET_ADMIN_ORDER_PENDING_COUNT = "GET_ADMIN_ORDER_PENDING_COUNT";

export function actionLoadOrders(payload) {
  return {
    type: ORDERS_LOAD,
    payload: {
      ...payload
    }
  };
}

export function actionLoadOrdersSuccess(data) {
  return { type: ORDERS_LOAD_SUCCESS, data, additionalParams: { batched: true } };
}

export function actionLoadOrdersFail(error) {
  return {
    type: ORDERS_LOAD_FAIL,
    error: error
  };
}

export function actionSaveHistoryOrders(data) {
  return {
    type: SAVE_HISTORY_ORDERS,
    data: data
  };
}

export function actionUpdateOrder(data) {
  return {
    type: ORDER_UPDATE,
    data: data
  };
}

export function actionUpdateOrderServiceNotes(data) {
  return { type: ORDER_UPDATE_SERVICE_NOTES, data };
}

export function actionNewOrder(data) {
  return { type: ORDER_NEW, data, additionalParams: { batched: true } };
}

export function actionAddNewOrders(data) {
  return {
    type: ADD_NEW_ORDERS,
    data: data
  };
}

export function actionUpdateOrderState(data) {
  return { type: ORDER_UPDATE_STATE, data, additionalParams: { batched: true } };
}

export function actionCycleReorder(data) {
  return { type: CYCLE_REORDER, data, additionalParams: { batched: true } };
}

export function actionUpdateOrderGroup(data) {
  return { type: GROUP_ORDER_UPDATE, data, additionalParams: { batched: true } };
}

export function actionRemoveGroupOrders(data) {
  return {
    type: REMOVE_GROUP_ORDERS,
    data: data
  };
}

export function actionGroupUpdateState(data) {
  return { type: GROUP_UPDATE_STATE, data, additionalParams: { batched: true } };
}

export function actionCycleDispatched(data) {
  return {
    type: CYCLE_DISPATCHE,
    data: data
  };
}

export function actionUpdateOrderData(data) {
  return {
    type: UPDATE_ORDER_DATA,
    data: data
  };
}

export function actionUpdateOrders(data) {
  return { type: UPDATE_ORDERS, data, additionalParams: { batched: true } };
}

export function actionRemoveOrder(data) {
  return {
    type: ORDER_REMOVE,
    data: data
  };
}

export function actionChangeOrderIndex(data) {
  return {
    type: CHANGE_ORDER_INDEX,
    data: data
  };
}

export function actionChangeItemsGroup(data) {
  return {
    type: CHANGE_ITEMS_GROUP,
    data: data
  };
}

export function actionUpdateGroupOrdersMarkers(data) {
  return { type: UPDATE_GROUP_ORDERS_MARKERS, data, additionalParams: { batched: true } };
}

export function actionUpdateGroup(data) {
  return { type: GROUP_UPDATE, data};
}

export function actionPendingOrderSave(data) {
  return {
    type: PENDING_ORDER_SAVE,
    data: data
  };
}

export function actionPendingOrdersUpdate(data) {
  return { type: PENDING_ORDERS_UPDATE, data };
}

export function actionPendingOrderCourierUpdate(data) {
  return { type: PENDING_ORDER_UPDATE_COURIER, data };
}

export function actionPendingOrderUpdate(data) {
  return { type: PENDING_ORDER_UPDATE, data };
}

export function actionPendingPeorderDelete(data) {
  return { type: PENDING_PREORDER_DELETE, data };
}

export function actionAdminOrdersUpdate(data) {
  return {
    type: ADMIN_ORDERS_UPDATE,
    data: data
  };
}

export function actionAdminOrdersLoading(data) {
  return {
    type: ADMIN_ORDERS_LOADING,
    data: data
  };
}

export function actionAdminOrderUpdate(data) {
  return {
    type: ADMIN_ORDER_UPDATE,
    data: data
  };
}

export function actionAdminOrderPendingCountUpdate(data) {
  return {
    type: ADMIN_ORDER_PENDING_COUNT_UPDATE,
    data: data
  };
}

export function actionAdminOrderGetOrderInfo(data) {
  return {
    type: ADMIN_ORDER_GET_ORDER_INFO,
    data: data
  };
}

export function setAdminOrderSelectedOrderId(data) {
  return {
    type: ADMIN_ORDER_SELECTED_ORDER_ID,
    data: data
  }
}

export function setFilterAdminOrders(data) {
  return {
    type: ADMIN_ORDER_SET_FILERS,
    data: data
  };
}

export function actionOrderAdminInfoGet(data) {
  return { type: ORDER_ADMIN_INFO_GET, data };
}

export function actionOrderMenuUpdate(data) {
  return {
    type: ORDER_MENU_UPDATE,
    data: data
  };
}

export function actionOrderIndexUpdate(data) {
  return { type: ORDER_INDEX_UPDATE, data }
}

export const actionConsumerOrdersSearch = data => ({ type: CONSUMER_ORDER_SEARCH, data });

export function actionOrdersModelUpdate(data) {
  return { type: ORDERS_MODEL_UPDATE, data };
}
