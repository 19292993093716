import { GET, UPDATE } from "../common-actions";

export const CUSTOMER = "CUSTOMER";
export const CUSTOMER_GET = CUSTOMER + GET;
export const CUSTOMER_UPDATE = CUSTOMER + UPDATE;
export const CUSTOMER_WALLET_OPERATION = CUSTOMER + "_WALLET_OPERATION";
export const CUSTOMER_ADD_BONUS_CARD = CUSTOMER + "_ADD_BONUS_CARD";
export const CUSTOMER_UPDATE_LOYALTY_LEVEL = CUSTOMER + "_UPDATE_LOYALTY_LEVEL";
export const CUSTOMER_BLOCK_USER = CUSTOMER + "_BLOCK_USER";
export const CUSTOMER_WALLET_INFO_GET = CUSTOMER + "_WALLET_INFO" + GET;
export const CUSTOMER_BLOCK_USER_STATUS = CUSTOMER + "_BLOCK_STATUS" + GET;
export const CUSTOMER_ORDERS_GET = CUSTOMER + "_ORDERS" + GET;
export const CUSTOMERS = "CUSTOMERS";
export const CUSTOMERS_GET = CUSTOMERS + GET;
export const CUSTOMERS_UPDATE = CUSTOMERS + UPDATE;
export const CUSTOMER_REVIEWS = "CUSTOMER_REVIEWS";
export const CUSTOMER_REVIEWS_GET = CUSTOMER_REVIEWS + GET;
export const CUSTOMER_REVIEWS_UPDATE = CUSTOMER_REVIEWS + UPDATE;

export const actionCustomerReviewUpdate = (data) => ({ type: CUSTOMER_REVIEWS_UPDATE, data });

export const actionCustomersUpdate = (data) => ({ type: CUSTOMERS_UPDATE, data });

export const actionCustomerUpdate = (data) => ({ type: CUSTOMER_UPDATE, data });