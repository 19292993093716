import {callServer} from '../utils/request-util';
import environment from 'environment';
import {currentUserIsDepotRole, isShowControlCenterSearch} from '../services/userModel';
import Pusher from '../services/pusher';

export function fetchRestaurant() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant",
      method: "GET",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot,
        environment.USER_ROLES.supervisor,
        environment.USER_ROLES.supportOperator
      ]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getDeliveryActiveState() {
  return new Promise((resolve, reject) => {
    const controlCenterMulti = Pusher.instance.getControlCenterMultiChannel();
    const isPostRequest = isShowControlCenterSearch();

    callServer({
      url: environment.backendAPI + "delivery/activeState",
      method:  isPostRequest ? "POST" : "GET",
      data: isPostRequest ? {
        ControlCenterIds: controlCenterMulti
      } : null,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot, environment.USER_ROLES.controlCenterManager],
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchDepotAvatarBase64(depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/depot/avatar/" + depotId,
      method: "GET",
      responseType: "blob"
    }).then(
      response => {
        resolve(response);
      },
      error => {
        resolve(null);
      }
    );
  });
}

export function getControlCenter() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "controlCenter",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getControlCenterActive(orderIds) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "controlCenter/available",
      method: "POST",
      data: (orderIds && { OrderIds: orderIds })
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getControlCenterStatus() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "controlCenter/status/",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function setMode(mode) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/state",
      method: "POST",
      data: {
        State: mode
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function checkDepotMenu(depotId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `restaurant/depot/${depotId}/menu`,
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function depotMenuCopy(fromDepotId, toDepotId){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `restaurant/depot/${fromDepotId}/copyMenu/${toDepotId}`,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function updateDepotMenu(depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `restaurant/depot/${depotId}/updateMenu`,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function updateDepotCategoryStates(businessId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessMenu/${businessId}/categories`,
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function fetchCancelReason() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceOrderRejects`,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot],
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchAddCancelReason(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceOrderRejects`,
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot],
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchEditCancelReason(id, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceOrderRejects/${id}`,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot],
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchDeleteCancelReason(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceOrderRejects/${id}`,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot],
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchBusinessCategory() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessCategory`,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot],
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}
export function reverseGeoLocation(lat, lng) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "geolocation/reverse",
      method: "POST",
      data: {
        Latitude: lat,
        Longitude: lng
      },
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function addDepot(depot) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/depot",
      method: "POST",
      data: depot,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getDepotOperationMode(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "operation/" + id,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function updateDepotOperationMode(id, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "operation/" + id,
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function editDepot(depot) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/depot/" + depot.Id,
      method: "PUT",
      data: depot,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function removeDepot(depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/depot/" + depotId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function addDepotUser(depotId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/" + depotId + "/user",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function removeDepotUser(depotId, userId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/" + depotId + "/user/" + userId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function setDepotAvatarBase64(depotId, avatar) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/depot/avatar/" + depotId,
      method: "POST",
      data: avatar,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getDepotUsers({ depotId, num, count, query }) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/" + depotId + "/user/page",
      method: "POST",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ],
      data: {
        Count: count,
        Page: num,
        Query: query,
      },
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function editDepotUser(depotId, userId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/" + depotId + "/user/" + userId,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function changeDepotPassword(depotId, userId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url:
        environment.authAPI +
        "profile/depot/" +
        depotId +
        "/resetPassword/" +
        userId,
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getDepotDeliveryPrice(depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/deliveryprice/" + depotId,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function setDepotDeliveryPrice(depotId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/deliveryprice/" + depotId,
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setNotificationSettings(
  lostAlertTime,
  orderIsLateAlertTime,
  orderIsLateWarningTime,
  stopAlertTime,
  courierReadyTimeDelay,
  orderIsUnassignedTime
) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/notificationsettings",
      method: "POST",
      data: {
        LostAlertTime: lostAlertTime,
        OrderIsLateAlertTime: orderIsLateAlertTime,
        OrderIsLateWarningTime: orderIsLateWarningTime,
        StopAlertTime: stopAlertTime,
        CourierReadyTimeDelay: courierReadyTimeDelay,
        OrderIsUnassignedTime: orderIsUnassignedTime
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getCourierInterfaceSettings() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/courierInterfaceSettings",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateCourierInterfaceSettings(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/courierInterfaceSettings",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getCourierDisplaySettings() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/courierDisplaySettings",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateCourierDisplaySettings(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/courierDisplaySettings",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getCourierControlSettings() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/courierControlSettings",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateCourierControlSettings(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/courierControlSettings",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getBusinessAppSettings() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/businessAppSettings",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateBusinessAppSettings(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/businessAppSettings",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getShipmentPriceSettings() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/courierShipmentPriceSettings",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setShipmentPriceSettings(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/courierShipmentPriceSettings",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getAppShipmentPriceSettings() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/appShipmentPriceSettings",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setAppShipmentPriceSettings(
  basisPrice,
  basisDistanceInMeters,
  pricePerKm,
  maxDeliveryPrice,
  mode
) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/appShipmentPriceSettings",
      method: "POST",
      data: {
        BasisPrice: basisPrice,
        BasisDistanceInMeters: basisDistanceInMeters,
        PricePerKm: pricePerKm,
        MaxDeliveryPrice: maxDeliveryPrice,
        Mode: mode
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function setNumericalSettings(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/numericalsettings",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setRestaurantInfo(location, regions, phoneNumber) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/info",
      method: "POST",
      data: {
        Location: location,
        Regions: regions,
        PhoneNumber: phoneNumber
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function updateRegionInfo(region) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/region",
      method: "PUT",
      data: region,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function setNewRegionInfo(region) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/region",
      method: "POST",
      data: region,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function removeRegion(regionId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/region/" + regionId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}
export function fetchSettingProviders() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/providers",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}
export function fetchSettingExternalProvidersById(providerEntityId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/externalProvider/" + providerEntityId,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function fetchSettingProviderDetails(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/" + id,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function addSettingProviders(id, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/" + id,
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function addSettingExternalProviders(id, data) {

  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/externalProvider",
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}



export function deleteSettingProviders(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/" + id,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function deleteSettingExternalProviders(providerEntityId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/externalProvider/" + providerEntityId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function fetchSettingCloudPrinters() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/printer",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function fetchSettingCloudPrintersDetails(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/printer/" + id,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function addSettingCloudPrinters(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/printer",
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function editSettingCloudPrinters(data, id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/printer/" + id,
      method: "PUT",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function deleteSettingCloudPrinters(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/printer/" + id,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function telegramSettings(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/telegramSettings",
      method: "POST",
      data: {
        RequireWaitingTime: data.requireWaitingTime,
        ShowMap: data.showMap,
        NotifyDeliveredOrder: data.notifyDeliveredOrder,
        NotifyCourierDispatched: data.notifyCourierDispatched,
        AllowOrderDeletion: data.allowOrderDeletion,
	    	EnablePhoneQuery: data.enablePhoneQuery,
        WaitingTimes: data.waitingTimes,
        AllowOrderSetVIP: data.allowOrderSetVIP,
        AllowOrderDelay: data.allowOrderDelay,
        RequirePhoneNumberOnOrderCreation: data.requirePhoneNumberOnOrderCreation,
        HideReadyButton: data.hideReadyButton
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function fetchMarketplaceMessage() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplaceMessage",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.message));
  });
}

export function fetchPages() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplacePage",
      method: "GET",
    }).then(response => resolve(response), error => reject(error.message));
  });
}

export function pushMessage(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceMessage/${id}/send`,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.message));
  });
}

export function createMarketplaceMessage(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplaceMessage",
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager],
      data
    }).then(response => resolve(response), error => reject(error.message));
  })
}

export function createMarketplacePage(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplacePage",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.message));
  })
}

export function updateMarketplaceMessage(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplaceMessage/" + data.id,
      method: "PUT",
      data
    }).then(response => resolve(response), error => reject(error.message));
  })
}

export function updateMarketplacePage(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplacePage/" + data.id,
      method: "PUT",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.supportOperator],
      data
    }).then(response => resolve(response), error => reject(error.message));
  })
}


export function deleteMarketplaceMessage(marketplaceId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplaceMessage/" + marketplaceId,
      method: "DELETE",
    }).then(response => resolve(response), error => reject(error.message));
  })
}

export function deleteMarketplacePage(marketplacePageId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplacePage/" + marketplacePageId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager],
    }).then(response => resolve(response), error => reject(error.message));
  })
}

export function setMarketplaceBannerBase64(id, banner, isVideo) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplaceMessage/" + id + "/banner",
      method: "POST",
      headers: {
        "Content-Type": "image/png"
      },
      isVideoType: isVideo,
      data: banner,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function setMarketplacePageBannerBase64(id, banner, isVideo) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplacePage/" + id + "/banner",
      method: "POST",
      headers: {
        "Content-Type": "image/png"
      },
      isVideoType: isVideo,
      data: banner,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function marketplaceMessageReorder(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplaceMessage/reorder",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.message));
  });
}

export function marketplacePagesReorder(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplacePage/reorder",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.message));
  });
}

export function depotWallet(depotId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/depot/" + depotId + "/wallet",
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function restaurantWallet(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/wallet",
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function restaurantPaymentState(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/paymentState",
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function depotPaymentState(depotId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/depot/" + depotId + "/paymentState",
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function businessCategoryAdd(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessCategory",
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function businessCategoryEdit(data, id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessCategory/" + id,
      method: "PUT",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function businessCategoryRemove(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessCategory/" + id,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function setBusinessCategoryAvatarBase64(id, avatar) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessCategory/logo/" + id,
      method: "POST",
      headers: {
        "Content-Type": "image/png"
      },
      data: avatar,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function businessCategoryReorder(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessCategory/reorder",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function addServiceCity(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "serviceCity",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function editServiceCity(id, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "serviceCity/" + id,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function getServiceCity() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "serviceCity",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function getFleet() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "vehicle",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}
export function getFleetById(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `vehicle/${id}`,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}
export function getFleetTelemetryBlink(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `vehicle/${id}/sendCommand`,
      method: "POST",
      data: {
        Command: 0,
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}
export function getFleetTelemetry(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `vehicle/telemetry/${id}`,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function deleteFleet(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `vehicle/${id}`,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function addFleet(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "vehicle",
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function editFleet(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `vehicle/${data.id}`,
      method: "PUT",
      data: data.data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}



export function toggleServiceCity(city, body) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "serviceCity/" + city,
      method: "PUT",
      data: body,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function deleteServiceCity(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "serviceCity/" + id,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getNamedPlaces() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "namedPlaces",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function addNamedPlace(namedPlace) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "namedPlaces",
      method: "POST",
      data: namedPlace,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function editNamedPlace(namedPlace, id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "namedPlaces/" + id,
      method: "PUT",
      data: namedPlace,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function deleteNamedPlace(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "namedPlaces/" + id,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function startControlCenterShift(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "controlCenter/startShift",
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function endControlCenterShift(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "controlCenter/endShift",
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function controlCenterEdit(id, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `controlCenter/${id}`,
      method: "PUT",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  })
}

export function changeControlCenterShift(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "controlCenter/changeShift",
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getReportOrderStatistics(skipHours) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "report/orderstat/" + skipHours,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getReportOrderStatisticsByDate(startDate, endDate, controlCenterIds, userId, territoryId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "report/orderstat",
      method: "POST",
      data: {
        StartDate: startDate,
        EndDate: endDate,
        ControlCenterIds: controlCenterIds,
        UserId: userId,
        TerritoryId: territoryId
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function getNotification() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "notification",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function fetchOperations() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "operation",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function updateOperations(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "operation",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function getBusinessMenu(businessId, isById) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + ((!isById && currentUserIsDepotRole()) ? "businessMenu" : "businessMenu/" + businessId),
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function createBusinessDeepLink(businessId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/link",
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function createBusinessProductDeepLink(businessId, categoryId, productId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `/businessMenu/${businessId}/category/${categoryId}/product/${productId}/link`,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function createMarketplaceDeepLink() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplace/link",
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getDepotBusinessTranslation({ businessId, language }) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessMenu/${businessId}/overrides/${language}/translate`,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setDepotBusinessTranslation({ data, businessId, language }) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessMenu/${businessId}/overrides/${language}/translate`,
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function exportDepotBusinessTranslation({ businessId, language }) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessMenu/${businessId}/translate/export`,
      method: "POST",
      responseType: "blob",
      data: {
        languages: [language]
      },
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function importDepotBusinessTranslation({ businessId, file }) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessMenu/${businessId}/translate/import`,
      method: "POST",
      data: file,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}


export function getLinkStats(link, period) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplace/linkStats",
      method: "POST",
      data: {
        DeepLink: link,
        DaysDuration: period,
      },
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function overrideParentBusinessMenu(businessId, objectId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessMenu/${businessId}/overrides/${objectId}`,
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deleteOverrideParentBusinessMenu(businessId, objectId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessMenu/${businessId}/override/${objectId}`,
      method: "DELETE",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getBusinessOffer() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessOffer",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateBusinessOffer(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessOffer",
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function addBusinessOffer(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessOffer",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deleteBusinessOffer(offerId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessOffer/" + offerId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateDeliveryZone(businessId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/deliveryZone",
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateEnableDeliveryZone(businessId, zoneId, enabled) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/deliveryZone/" + zoneId + "/activate",
      method: "PUT",
      data: {
        Enabled: enabled,
      },
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setDeliveryZone(businessId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/deliveryZone",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deleteDeliveryZone(businessId, zoneId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/deliveryZone/" + zoneId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateOrderingMenuDeliveryZone(businessId, globalDeliveryZoneId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessMenu/${businessId}/deliveryZone/attach/${globalDeliveryZoneId}`,
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateSchedule(businessId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/schedule",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateBusinessMenuDiscount(businessId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/offer",
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function addBusinessMenuDiscount(businessId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/offer",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deleteBusinessMenuDiscount(businessId, offerId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/offer/" + offerId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateBusinessMenuInfo(businessId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/settings",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateBusinessMenuHeaderAvatar(businessId, data, isVideo) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/header",
      method: "POST",
      data,
      isVideoType: isVideo,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateBusinessMenuHeaderMini(businessId, data, isVideo) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/headerMini",
      method: "POST",
      data,
      isVideoType: isVideo,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}


export function businessMenuCategoryDelete(businessId, categoryId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryAdd(businessId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryEdit(businessId, categoryId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryActivate(businessId, categoryId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/activate",
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function marketplaceMenuClearOverride(businessId, objectId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessMenu/${businessId}/childOverride/${objectId}`,
      method: "DELETE",
      data: {name: "enabled"},
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryReorder(businessId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/categoryReorder",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryProductAdd(businessId, categoryId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/product",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryProductEdit(businessId, categoryId, productId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/product/" + productId,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryProductActivate(businessId, categoryId, productId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/product/" + productId + "/activate",
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryProductDelete(businessId, categoryId, productId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/product/" + productId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryProductsReorder(businessId, categoryId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/productReorder",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateBusinessMenuCategoryProductAvatar(businessId, categoryId, productId, data, isVideo) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/product/" + productId + "/header",
      method: "POST",
      isVideType: isVideo,
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryProductIngredientAdd(businessId, categoryId, productId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/product/" + productId + "/ingredient",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryProductIngredientUpdate(businessId, categoryId, productId, ingredientId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/product/" + productId + "/ingredient/" + ingredientId,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryProductIngredientDelete(businessId, categoryId, productId, ingredientId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/product/" + productId + "/ingredient/" + ingredientId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryProductIngredientReorder(businessId, categoryId, productId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/product/" + productId + "/ingredientReorder",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtrasAdd(businessId, categoryId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtrasUpdate(businessId, categoryId, extraId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra/" + extraId,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtrasActive(businessId, categoryId, extraId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra/" + extraId + "/activate",
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtraDelete(businessId, categoryId, extraId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra/" + extraId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtrasOptionUpdate(businessId, categoryId, extraId, optionId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra/" + extraId + "/option/" + optionId,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtrasOptionActivate(businessId, categoryId, extraId, optionId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra/" + extraId + "/option/" + optionId + "/activate",
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtrasOptionDelete(businessId, categoryId, extraId, optionId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra/" + extraId + "/option/" + optionId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtrasOptionAdd(businessId, categoryId, extraId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra/" + extraId + "/option",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtrasOptionReorder(businessId, categoryId, extraId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra/" + extraId + "/optionReorder",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtrasSuboptionAdd(businessId, categoryId, extraId, optionId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra/" + extraId + "/option/" + optionId + "/suboption",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtrasSuboptionUpdate(businessId, categoryId, extraId, optionId, suboptionId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra/" + extraId + "/option/" + optionId + "/suboption/" + suboptionId,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtrasSuboptionActivate(businessId, categoryId, extraId, optionId, suboptionId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra/" + extraId + "/option/" + optionId + "/suboption/" + suboptionId + "/activate",
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtrasSuboptionReorder(businessId, categoryId, extraId, optionId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra/" + extraId + "/option/" + optionId + "/suboptionReorder",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessMenuCategoryExtrasSuboptionDelete(businessId, categoryId, extraId, optionId, suboptionId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/category/" + categoryId + "/extra/" + extraId + "/option/" + optionId + "/suboption/" + suboptionId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setActivateModel(businessId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenu/" + businessId + "/activate",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function depotDefaultRegionControlCenterGet(depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/" + depotId + "/controlCenter",
      method: "GET",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.supervisor,
        environment.USER_ROLES.supportOperator
      ]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function depotDefaultRegionControlCenterUpdate(depotId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/" + depotId + "/controlCenter",
      method: "PUT",
      data,
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.supervisor,
        environment.USER_ROLES.supportOperator
      ]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function depotDefaultRegionControlCenterDelete(depotId, id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/" + depotId + "/controlCenter/" + id,
      method: "DELETE",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.supervisor,
        environment.USER_ROLES.supportOperator
      ]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function depotDefaultRegionControlCenterAdd(depotId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "depot/" + depotId + "/controlCenter",
      method: "POST",
      data,
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.supervisor,
        environment.USER_ROLES.supportOperator
      ]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function addSearchShortcut(shortcut) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "searchShortcut/" + shortcut,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deleteSearchShortcut(shortcut) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "searchShortcut/" + shortcut,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function giftCardGet() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "giftCard",
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.supervisor, environment.USER_ROLES.controlCenterManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function giftCardEnable(value) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "giftCard/" + (value ? "enable" : "disable"),
      method: "POST"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getReferralSettings(){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "referral",
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getLoyaltySettings(){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/loyaltySettings",
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function editLoyaltySettingsRegisterVoucher(data){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/loyaltySettings/voucher",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}


export function editLoyaltySettingsCashback(data){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/loyaltySettings/cashBack",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getDriverTip(){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "driverTip",
      method: "GET",
      skipRetry: true,
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateReferralSettings(data){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "referral",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateDriverTip(data){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "driverTip",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getListPromotion(){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessList",
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getRestaurantDeliveryZones() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplaceDeliveryZone",
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}


export function listPromotionAdd(data){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessList",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function addRestaurantDeliveryZones(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplaceDeliveryZone",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function listPromotionUpdate(id, data){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessList/" + id,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function listPromotionReorder(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessList/reorder",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateRestaurantDeliveryZones(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplaceDeliveryZone",
      method: "PUT",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function listPromotionDelete(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessList/" + id,
      method: "DELETE"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deleteRestaurantDeliveryZone(zoneId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "marketplaceDeliveryZone/" + zoneId,
      method: "DELETE"
    }).then(response => resolve(response), error => reject(error.response));
  });
}


export function getServiceFee(){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "serviceFee",
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}
export function getMarketplaceCampaign(){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceCampaign`,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getMarketplaceCampaignAudienceFilterCount(data){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceCampaign/count`,
      method: "POST",
      data: data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function addMarketplaceCampaign(newCampaign){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceCampaign`,
      method: "POST",
      data: {
        ...newCampaign,
        ...newCampaign.bonusVoucher
      }
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function editMarketplaceCampaign({ campaignId, campaign }){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceCampaign/${campaignId}`,
      method: "PUT",
      data: {
        ...campaign,
        ...campaign.bonusVoucher
      }
    }).then(response => resolve(response), error => reject(error.response));
  });
}
export function toggleActivateMarketplaceCampaign({ campaignId, data }){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceCampaign/${campaignId}/activate`,
      method: "PUT",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function executeCompaings(campaignId){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceCampaign/${campaignId}/execute`,
      method: "POST",
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getMarketplaceCampaignExecutions(campaignId){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceCampaign/${campaignId}/executions`,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function createMarketplaceCampaignExecutions({ campaignId, data }){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceCampaign/${campaignId}/test`,
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}


export function deleteMarketplaceCampaign(campaignId){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceCampaign/${campaignId}/`,
      method: "DELETE",
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateMarketplaceCampaignExecutionById({ campaignId, executionId }){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceCampaign/${campaignId}/execution/${executionId}/update`,
      method: "POST"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function restaurantDeliveryZoneActivate(id, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `marketplaceDeliveryZone/${id}/activate`,
      method: "PUT",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setServiceFee(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "serviceFee",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getDepotIntegrations(depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `restaurant/depot/${depotId}/integrations`,
      method: "GET",
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateDepotIntegration(depotId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `restaurant/depot/${depotId}/integrations`,
      method: "PUT",
      data,
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function addDepotIntegration(depotId, body) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `restaurant/depot/${depotId}/integrations`,
      method: "POST",
      data: body
    }).then(response => resolve(response), error => reject(error.response));
  })
}

export function getDepotSquareLocations(depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + (currentUserIsDepotRole() ? "squareUp/locations" : `squareUp/locations/${depotId}`),
      method: "GET",
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getMenuTemplate() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessTemplate`,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getGlobalMenuTemplate() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessTemplate/global`,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getDepotSquareAssociatedLocation(depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + (currentUserIsDepotRole() ? "squareUp/associatedLocation" : `squareUp/associatedLocation/${depotId}`),
      method: "GET",
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setDepotSquareAssociatedLocation(depotId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "squareUp/associatedLocation/" + depotId,
      method: "POST",
      data,
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setDepotSquareAssociatedRevoke(depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + (currentUserIsDepotRole() ? "squareUp/revoke" : `squareUp/revoke/${depotId}`),
      method: "POST",
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function addMenuTemplate(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessTemplate`,
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function removeDepotSquareLocation(depotId, locationId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "squareUp/associatedLocation/" + depotId,
      method: "DELETE",
      data: { LocationId: locationId }
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function generateDepotAuthorizationUrl(depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + (currentUserIsDepotRole()
        ? "squareUp/generateAuthorizationUrl"
        : ("squareUp/generateAuthorizationUrl/" + depotId)),
      method: "GET",
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setSquareUpAuthorized(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "squareUp/authorized",
      method: "POST",
      data,
      skipRetry: true
    }, true).then(response => resolve(response), error => reject(error.response));
  });
}

export function checkSquareUpAuthorizedRequired(depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `squareUp/authorizationRequired${depotId ? `/${depotId}` : ""}`,
      method: "GET",
      roles: [environment.USER_ROLES.all]
    }, true).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessCategoryExtraAdd(data,id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${id}/extra`,
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function businessCategoryExtraEdit(data, businessId, id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/extra/${id}`,
      method: "PUT",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function businessCategoryExtraRemove( id, businessId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/extra/${id}`,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function businessCategoryExtraUpdate(businessId, id, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/extra/${id}/activate`,
      method: "PUT",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function businessCategoryExtraOptionAdd(businessId, id, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/extra/${id}/option`,
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deleteMenuTemplate(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessTemplate/${id}`,
      method: "DELETE",
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateMenuTemplate(id, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessTemplate/${id}`,
      method: "PUT",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessCategoryExtraOptionUpdate(businessId, id, optionId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/extra/${id}/option/${optionId}`,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessCategoryExtraOptionActivate(businessId, id, optionId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/extra/${id}/option/${optionId}/activate`,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function createOverrideMenu(id, objectId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessMenu/${id}/override/${objectId}`,
      method: "POST",
      data,
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessCategoryExtraOptionDelete(businessId, id, optionId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/extra/${id}/option/${optionId}`,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessCategoryExtraOptionReorder(businessId, id, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/extra/${id}/optionReorder`,
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateOverrideMenu(id, objectId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessMenu/${id}/override/${objectId}`,
      method: "PUT",
      data,
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessCategoryExtraSubOptionAdd(businessId, id, optionId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/extra/${id}/option/${optionId}/suboption`,
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessCategoryExtraSubOptionEdit(businessId, id, optionId, subId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/extra/${id}/option/${optionId}/suboption/${subId}`,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deleteOverrideMenu(id, objectId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `businessMenu/${id}/override/${objectId}`,
      method: "DELETE",
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessCategoryExtraSubOptionDelete(businessId, id, optionId,subId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/extra/${id}/option/${optionId}/suboption/${subId}`,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessCategoryExtraSubOptionActivate(businessId, id, optionId,subId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/extra/${id}/option/${optionId}/suboption/${subId}/activate`,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessCategoryExtraSubOptionReorder(businessId, id, optionId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/extra/${id}/option/${optionId}/suboptionReorder`,
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessGalleryEdit(businessId, galleryId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/gallery/${galleryId}`,
      method: "PUT",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessGalleryAdd(businessId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/gallery`,
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessGalleryDelete(businessId, galleryId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/gallery/${galleryId}`,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function businessGalleryReorder(businessId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/galleryReorder`,
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setBusinessGalleryImageBase64(businessId, galleryId, image, isVideo) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}businessMenu/${businessId}/gallery/${galleryId}/image`,
      method: "POST",
      headers: {
        "Content-Type": "image/png"
      },
      isVideoType: isVideo,
      data: image,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function updateMarketplaceSettings(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}restaurant/marketplace`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "image/png"
      },
      roles: [environment.USER_ROLES.all]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  })
}

export function setMarketplaceSettingsHeader(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}restaurant/marketplace/header`,
      method: "POST",
      data,
      roles: [environment.USER_ROLES.all]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  })
}

export function getBusinessWithQuery(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}callCenter/business`,
      method: "POST",
      data,
      roles: [environment.USER_ROLES.all]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  })
}

export function messageGalleryAdd(id, body) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}marketplaceMessage/${id}/gallery`,
      method: "POST",
      data: body,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function messageGalleryEdit(id, galleryId, body) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}marketplaceMessage/${id}/gallery/${galleryId}`,
      method: "PUT",
      data: body,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function messageGalleryImageBase64(id, galleryId, file, isVideo) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}marketplaceMessage/${id}/gallery/${galleryId}/image`,
      method: "POST",
      data: file,
      isVideoType: isVideo,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function messageGalleryReorder(id, body) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}marketplaceMessage/${id}/galleryReorder`,
      method: "POST",
      data: body,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function messageGalleryDelete(messageId, galleryId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}marketplaceMessage/${messageId}/gallery/${galleryId}`,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}
